import { Action, Reducer } from 'redux';
import authService from '../components/api-authorization/AuthorizeService';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { AppThunkAction } from './';
import { store } from './configureStore'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import queryString from "query-string";
import { navigateToUrl } from '../components/Navigation'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ApiInterfaceState {
    isErrorOpen: boolean;
    isInfoOpen: boolean;
    error?: string;
    info?: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetErrorAction {
    type: 'SET_ERROR';
    error: string;
}

interface HideErrorAction {
    type: 'HIDE_ERROR';
}

export interface SetInfoAction {
    type: 'SET_INFO';
    info: string;
}

interface HideInfoAction {
    type: 'HIDE_INFO';
}

export interface HideAllAction {
    type: 'HIDE_ALL';
}

interface LocationChangedAction {
    type: '@@router/LOCATION_CHANGE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = SetErrorAction |
    HideErrorAction |
    SetInfoAction |
    HideInfoAction |
    HideAllAction |
    LocationChangedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setError: (error: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_ERROR', error: error });        
    },
    hideError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HIDE_ERROR' });
    },
    setInfo: (info: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_INFO', info: info });
    },
    hideInfo: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HIDE_INFO' });
    },
    hideAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HIDE_ALL' });
    }

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ApiInterfaceState = { isErrorOpen: false, isInfoOpen: false };

export const reducer: Reducer<ApiInterfaceState> = (state: ApiInterfaceState | undefined, incomingAction: Action): ApiInterfaceState => {
    if (state === undefined) {
        return unloadedState;
    }
    
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_ERROR':
            return {
                isErrorOpen: true,
                error: action.error,
                isInfoOpen: state.isInfoOpen,
                info: state.info
            };
        case 'HIDE_ERROR':
            return {
                isErrorOpen: false,
                isInfoOpen: state.isInfoOpen,
                info: state.info
            };
        case 'SET_INFO':
            return {
                isInfoOpen: true,
                info: action.info,
                isErrorOpen: state.isErrorOpen,
                error: state.error
            };
        case 'HIDE_INFO':
            return {
                isInfoOpen: false,
                isErrorOpen: state.isErrorOpen,
                error: state.error
            };
        case 'HIDE_ALL':
            return {
                error: '',
                isErrorOpen: false,
                isInfoOpen: false,
                info: ''

            };
        /*
        case '@@router/LOCATION_CHANGE':
            return {
                error: '',
                isErrorOpen: false,
                isInfoOpen: false,
                info: ''

            };
        */
        default:
            return state;
    }
};

export async function checkTokenValidTryRefreshIfNot() : Promise<boolean>{
    var token_valid: boolean = false;

    if (authService.userManager) {
        const user = await authService.userManager.getUser();

        if (user != null) {
            if (!user.expired) {
                token_valid = true;
            }
            else {
                let retryStep = 0;
                while (retryStep < 5) {
                    retryStep += 1;
                    try {

                        await authService.ensureUserManagerInitialized();
                        const user = await authService.userManager.signinSilent();
                        authService.updateState(user);                        
                        token_valid = true;
                        break;
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }
    }


    // nu mai facem redirect la logout in checkTokenValidTryRefreshIfNot
    // pt ca se poate intampla ca aplicatia sa fie offline si sa nu poata obtine token
    // lasam redirect la logout doar in functia parseAxiosError - daca returneaza 401
    // pt asta nu mai validam daca raspunsul checkTokenValidTryRefreshIfNot este true / false - lasam sa apeleze API-ul chiar daca nu are token valid si daca returneaza 401 API-ul atunci facem redirect la logout
    /*
    if (!token_valid){
        navigateToUrl(ApplicationPaths.LogOut);
    }
    */


    return token_valid;

}

async function parseError(err : any): Promise<string> {
    var errMsg = ''
    if (err.response && err.response.status == 401) {
        errMsg = 'You are not authorized';
        store.dispatch({ type: 'SET_ERROR', error: errMsg });
        navigateToUrl(ApplicationPaths.LogOut);
        return errMsg;
    }
    else if (err.response && err.response.status == 403) {
        errMsg = 'Access forbidden';
        store.dispatch({ type: 'SET_ERROR', error: errMsg });
        return errMsg;
    }
    else {

        const apiResponse = await err.response.data;
        
        if (apiResponse.error != undefined) //cazul in care eroarea este returnata prin ApiResponseUtil.BadRequestResponse
        {
            store.dispatch({ type: 'SET_ERROR', error: apiResponse.error });
            return apiResponse.error;
        }
        else if (apiResponse instanceof Blob && apiResponse.type == 'text/plain') //cazul apelurilor cu responseType: 'blob' si in care eroarea este retunata prin BadRequest(<error>); sunt apelurile in care se downlodeaza binar fisiere pdf sau xlsx
        {
            const errMsg = await apiResponse.text();            
            store.dispatch({ type: 'SET_ERROR', error: errMsg });
            return errMsg;
        }
        else if (apiResponse != null && typeof apiResponse == 'string') //cazul in care eroarea este retunata prin BadRequest(<error>)
        {
            store.dispatch({ type: 'SET_ERROR', error: apiResponse });
            return apiResponse;
        }
        else {
            errMsg = 'Error occured processing API request';
            store.dispatch({ type: 'SET_ERROR', error: errMsg });
            return 'Error occured processing API request';
        }
    }
}

async function parseResponse(response: AxiosResponse): Promise<any> {
    if (response.status == 200 /*OK*/ || response.status == 201 /*created*/) {
        const apiResponse = await response.data;

        if (apiResponse.error != undefined) {
            store.dispatch({ type: 'SET_ERROR', error: apiResponse.error });
            throw apiResponse.error;
        }
        
        if (apiResponse.info != undefined) {
            store.dispatch({ type: 'SET_INFO', info: apiResponse.info });
        }

        
        if (response.headers['location'] != null) {            
            navigateToUrl(response.headers['location']);
        }

        return JSON.parse(apiResponse.payload);
    }
    else {
        throw new Error("Unknow status code");
    }
}

export const http_axios_get = (request: RequestInfo, params?: any) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;
    var qs = '';

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }

    if (params != null) {
        qs = '?' + queryString.stringify(params);
    }

    try {
        response = await axios.get(request.toString() + qs,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});


export const http_axios_post = (request: RequestInfo, data?: any, content_type: string = 'application/json') => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }
    try {
        response = await axios.post(request.toString(), data,
            {
                headers: {
                    'Content-Type': content_type,
                    'Authorization': `Bearer ${token}`
                }

            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});

export const http_axios_put = (request: RequestInfo, data?: any, content_type: string = 'application/json') => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }
    try {

        response = await axios.put(request.toString(), data,
            {
                headers: {
                    'Content-Type': content_type,
                    'Authorization': `Bearer ${token}`
                }

            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});


//export async function http_axios_delete(request: RequestInfo): Promise<any> {
export const http_axios_delete = (request: RequestInfo) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }
    try {
        response = await axios.delete(request.toString(),
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});



export const http_axios_download = (request: RequestInfo, type: string, file_name: string, params?: any) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;
    var qs = '';

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }

    if (params != null) {
        qs = '?' + queryString.stringify(params);
    }

    try {        
        response = await axios.get(request.toString() + qs,
        {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            responseType: 'blob', // Important
        });        

        var content_type = response.headers["content-type"];
        
        if (type == "xlsx") {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: content_type }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
        else if (type == "pdf") {

            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: content_type });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            //Open the URL on new Window
            const pdfWindow = window.open();
            
            if (pdfWindow != null) {
                pdfWindow.document.title = file_name;
                pdfWindow.location.href = fileURL;
            }
            
        }
    }
    catch (err: any) {
        reject(parseError(err));
    }
});

export const http_axios_get_as_object_url = (request: RequestInfo) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;
    var queryString = '';

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }

    try {        
        response = await axios.get(request.toString() + queryString,
        {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            responseType: 'blob', // Important
        });        

        const file = new Blob([response.data], { type: response.headers["content-type"] });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        resolve(fileURL);

    }
    catch (err: any) {
        reject(parseError(err));
    }
});




function areWeTestingWithJest() {
    return process.env.JEST_WORKER_ID !== undefined;
}

