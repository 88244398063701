import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as EditParametersStore from '../store/EditParameters';
import {
    FormControlLabel,
    Switch,
    MenuItem,
    Input,
    Select,
    Grid,
    TextField,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';

import {
    Info as InfoIcon,
} from '@mui/icons-material';

import * as utils from '../store/Utils';
import { Dialog, DialogTitle, DialogContent, DialogActions } from './StyledComponents'

// At runtime, Redux will merge together...

type EditParametersProps =
    EditParametersStore.EditParametersState // ... state we've requested from the Redux store
    & typeof EditParametersStore.actionCreators; // ... plus action creators we've requested
    


interface EditParametersState {
    parameters: EditParametersStore.ApplicationParametersExpanded;
    editVehicle: EditParametersStore.Vehicle;
    editDepot: EditParametersStore.Depot;
    showEditVehiclePopup: boolean;
    showEditDepotPopup: boolean;
}

export class EditParameters extends React.PureComponent<EditParametersProps, EditParametersState> {

    constructor(props: Readonly<EditParametersProps>) {
        super(props);
        this.state = {
            parameters: { ...this.props.parameters },
            editVehicle: {
                vehicle_id: 0,
                name: "",
                description: "",
                speed: 0,
                pickup_dropoff_capacity: 0,
            },
            editDepot: {
                depot_id: 0,
                name: "",
                latitude: 0,
                longitude: 0,
            },
            showEditVehiclePopup: false,
            showEditDepotPopup: false,
        };
        
    }

    handleShowPickupChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                ShowPickup: val
            }
        }));
    }

    handleAllocationMethodChange = (event: any) => {
        var val = event.target.value as string;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                AllocationMethod: val
            }
        }));
    }

    handleOptimizationTypeChange = (event: any) => {

        var val = event.target.value as string;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                OptimizationType: val
            }
        }));
    }

    handleCurrentRouteLockTypeChange = (event: any) => {

        var val = event.target.value as string;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                CurrentRouteLockType: val
            }
        }));
    }

    handleTimeWindowConstraintChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                TimeWindowConstraint: val
            }
        }));
    }

    handleAllowUserToChangeNavigationOrderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                AllowUserToChangeNavigationOrder: val
            }
        }));
    }

    handleVehicleWorkingTimeConstraintChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                VehicleWorkingTimeConstraint: val
            }
        }));
    }

    handleVehicleStopsContraintChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                VehicleStopsContraint: val
            }
        }));
    }

    handlePickupDropoffContraintChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                PickupDropoffContraint: val
            }
        }));
    }

    handleVehicleMinimizeStartTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                VehicleMinimizeStartTime: val
            }
        }));
    }

    handleVehicleMinimizeEndTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                VehicleMinimizeEndTime: val
            }
        }));
    }

    handlePrecedenceContraintChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                PrecedenceContraint: val
            }
        }));
    }


    handleAllowDropChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                AllowDrop: val,
                DropPenality: val ? prevState.parameters.DropPenality : 0,
            }
        }));
    }

    handleDropPenalityChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                DropPenality: value
            }
        }));
    }

    handleMinimizeEmptyRoutesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                MinimizeEmptyRoutes: val,
                EmptyRoutePenality: val ? prevState.parameters.EmptyRoutePenality : 0,
            }
        }));
    }

    handleEmptyRoutePenalityChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                EmptyRoutePenality: value
            }
        }));
    }


    handleDailyEndTimeChanged = (event: any) => {
        var value = event.target.value;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                DailyEndTime: value
            }
        }));
    }

    handleMaximumAllowWaitingTimeChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                MaximumAllowWaitingTime: value
            }
        }));
    }

    handleWaitingTimeAtLocationChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                WaitingTimeAtLocation: value
            }
        }));
    }
    handleGlobalCostCoefficientChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                GlobalCostCoefficient: value
            }
        }));
    }

    handleInitialLoadTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                InitialLoadTime: val
            }
        }));
    }

    handleInitialLoadTimeValueChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                InitialLoadTimeValue: value
            }
        }));
    }

    handleFinalUnloadTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        var val = event.target.checked as boolean;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                FinalUnloadTime: val
            }
        }));
    }

    handlFinalUnloadTimeValueChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                FinalUnloadTimeValue: value
            }
        }));
    }
    handlManattanDistanceFactorChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                ManattanDistanceFactor: value
            }
        }));
    }

    handleWorkingDayChanged = (event: any) => {
        var value = event.target.value;
        this.setState(prevState => ({
            parameters: {
                ...prevState.parameters,
                WorkingDay: value
            }
        }));
    }
    

    handleVehicleNameChanged = (event: any) => {
        var value = event.target.value;
        this.setState(prevState => ({
            editVehicle: {
                ...prevState.editVehicle,
                name: value
            }
        }));
    }

    handleVehicleDescriptionChanged = (event: any) => {
        var value = event.target.value;
        this.setState(prevState => ({
            editVehicle: {
                ...prevState.editVehicle,
                description: value
            }
        }));
    }

    handleVehicleSpeedChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            editVehicle: {
                ...prevState.editVehicle,
                speed: value
            }
        }));
    }

    handleVehiclePickupDropoffCapacityChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            editVehicle: {
                ...prevState.editVehicle,
                pickup_dropoff_capacity: value
            }
        }));
    }


    handleDepotNameChanged = (event: any) => {
        var value = event.target.value;
        this.setState(prevState => ({
            editDepot: {
                ...prevState.editDepot,
                name: value
            }
        }));
    }

    handleDepotLatitudeChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            editDepot: {
                ...prevState.editDepot,
                latitude: value
            }
        }));
    }

    handleDepotLongitudeChanged = (event: any) => {
        var value = Number(event.target.value);
        this.setState(prevState => ({
            editDepot: {
                ...prevState.editDepot,
                longitude: value
            }
        }));
    }

    handleValidation = () => {
        let result = true;
        //let err = '';

        /*
        var Destination = this.state.Destination;
        var StartDate = this.state.StartDate;
        var EndDate = this.state.EndDate;
        var Comment = this.state.Comment;

        if (!Validator.isAlphaNumericAndSpace(Destination)) {
            err += "Destination should be alpha numeric!\r\n";
            result = false;
        }

        if (Destination.length <= 0 || Destination.length > 50) {
            err += "Destination should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isText(Comment)) {
            err += "Illigal character in Comment field!\n";
            result = false;
        }
        if (Comment.length <= 0 || Comment.length > 200) {
            err += "Comment should be between 1 and 200 characters!\n";
            result = false;
        }

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
            console.log(err);
        }
        */
        return result;
    }

    //button actions

    //1. parameters
    handleSave = (event: any) => {
        event.preventDefault();
        if (this.handleValidation()) {
            var json = JSON.stringify(this.state.parameters);
            this.props.requestSaveParameters(json);
        }
    }    

    //2. vehicles
    handleCloseVehiclesModal = () => {
        this.setState({ showEditVehiclePopup: false });
    }

    handleSaveVehiclesModal = (event: any) => {
        event.preventDefault();
        if (this.handleValidation()) {
            //const data = new FormData(event.target);
            var json = JSON.stringify(this.state.editVehicle);
            this.props.requestSaveVehicle(json);
        }
    }

    handleDeleteVehicle = (vehicle_id: number, name: string) => {
        if (!window.confirm("Do you want to delete vehicle with name: " + name))
            return;
        else {
            this.props.requestDeleteVehicle(vehicle_id);
        }
    }

    handleShowVehiclesModal = (vehicle_id: number) => {
        if (vehicle_id === 0) {
            this.setState({
                showEditVehiclePopup: true,
                editVehicle: {
                    vehicle_id: 0,
                    name: "",
                    description: "",
                    speed: 0,
                    pickup_dropoff_capacity: 0,
                }
            });
        }
        else {
            var vehicle = this.props.vehicles.filter(v => {
                return v.vehicle_id === vehicle_id;
            });

            if (vehicle != null) {
                this.setState({
                    showEditVehiclePopup: true,
                    editVehicle: {
                        vehicle_id: vehicle[0].vehicle_id,
                        name: vehicle[0].name,
                        description: vehicle[0].description,
                        speed: vehicle[0].speed,
                        pickup_dropoff_capacity: vehicle[0].pickup_dropoff_capacity,
                    }
                });
            }
        }
    }


    //3. depots
    handleCloseDepotsModal = () => {
        this.setState({ showEditDepotPopup: false });
    }

    handleSaveDepotsModal = (event: any) => {
        event.preventDefault();
        if (this.handleValidation()) {
            //const data = new FormData(event.target);
            var json = JSON.stringify(this.state.editDepot);
            this.props.requestSaveDepot(json);
        }
    }

    handleDeleteDepot = (depot_id: number, name: string) => {
        if (!window.confirm("Do you want to delete depot with name: " + name))
            return;
        else {
            this.props.requestDeleteDepot(depot_id);
        }
    }

    handleShowDepotsModal = (depot_id: number) => {
        if (depot_id === 0) {
            this.setState({
                showEditDepotPopup: true,
                editDepot: {
                    depot_id: 0,
                    name: "",
                    latitude: 0,
                    longitude: 0,                    
                }
            });
        }
        else {
            var depot = this.props.depots.filter(v => {
                return v.depot_id === depot_id;
            });

            if (depot != null) {
                this.setState({
                    showEditDepotPopup: true,
                    editDepot: {
                        depot_id: depot[0].depot_id,
                        name: depot[0].name,
                        latitude: depot[0].latitude,
                        longitude: depot[0].longitude,
                    }
                });
            }
        }
    }

    handleTestToolsClearAllAllocations = () => {
        this.props.testToolsClearAllAllocations();
    }
    // This method is called when the component is first added to the document
    componentDidMount() {
        this.props.requestApplicationParameters();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        if (this.props.triggerLoad) {
            this.props.resetTriggerLoad();
            this.props.requestApplicationParameters();
        }
        if (this.props.triggerLoadState) {
            this.props.resetTriggerLoadState();
            this.setState({
                parameters: { ...this.props.parameters },
            });
        }
    }


    public render() {
        return (
            <React.Fragment>
                {this.renderCreateForm()}
            </React.Fragment>
        );
    }


    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <div>
                <Dialog fullWidth={true}
                    onClose={this.handleCloseVehiclesModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showEditVehiclePopup}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseVehiclesModal}>
                        Edit vehicle
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    Name:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="VehicleName"
                                        name="VehicleName"
                                        variant="standard"
                                        value={this.state.editVehicle.name}
                                        onChange={this.handleVehicleNameChanged}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Description:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="VehicleDescription"
                                        name="VehicleDescription"
                                        variant="standard"
                                        value={this.state.editVehicle.description}
                                        onChange={this.handleVehicleDescriptionChanged}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Speed (km/h):
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="VehicleSpeed"
                                        name="VehicleSpeed"
                                        type="number"
                                        variant="standard"
                                        value={this.state.editVehicle.speed}                                        
                                        onChange={this.handleVehicleSpeedChanged}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Pickup/dropoff capacity:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="VehicleSpeed"
                                        name="VehicleSpeed"
                                        type="number"
                                        variant="standard"
                                        value={this.state.editVehicle.pickup_dropoff_capacity}                                        
                                        onChange={this.handleVehiclePickupDropoffCapacityChanged}
                                    />
                                </Grid>
                            </Grid>

                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseVehiclesModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleSaveVehiclesModal}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth={true}
                    onClose={this.handleCloseDepotsModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showEditDepotPopup}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDepotsModal}>
                        Edit depot
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    Name:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="DepotName"
                                        name="DepotName"
                                        variant="standard"
                                        value={this.state.editDepot.name}
                                        onChange={this.handleDepotNameChanged}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Latitude:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="DepotLatitude"
                                        name="DepotLatitude"
                                        type="number"
                                        variant="standard"
                                        value={this.state.editDepot.latitude}                                        
                                        onChange={this.handleDepotLatitudeChanged}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Longitude:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="DepotLongitude"
                                        name="DepotLongitude"
                                        type="number"
                                        variant="standard"
                                        value={this.state.editDepot.longitude}                                        
                                        onChange={this.handleDepotLongitudeChanged}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDepotsModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleSaveDepotsModal}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={5} sx={{ mb: 5 }}>
                    <Grid item xs={12}>
                        <h1 id="tabelLabel">Mobile App Parameters</h1>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.parameters.ShowPickup}
                                        onChange={(e) => this.handleShowPickupChanged(e)}
                                        name="show-pickup-on-mobile"
                                        color="primary"
                                    />
                                }
                                labelPlacement="end"
                                label="Show pickup"
                            />
                    </Grid>
                    
                </Grid>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <h1 id="tabelLabel">Allocation Parameters</h1>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={5}>
                            <Grid item xs={8}>
                                <strong>Allocation method:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    id="allocation-method"
                                    value={this.state.parameters.AllocationMethod}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleAllocationMethodChange}
                                >
                                    <MenuItem key={""} value={""}>Select allocation method</MenuItem>
                                    <MenuItem key={"automatic"} value={"automatic"}>Automatic</MenuItem>
                                    <MenuItem key={"manual"} value={"manual"}>Manual</MenuItem>
                                    <MenuItem key={"self-managed"} value={"self-managed"}>Self Managed</MenuItem>
                                </Select>
                            </Grid>
                            
                            <Grid item xs={8}>
                                <strong>Optimization type:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    id="optimization-type"
                                    value={this.state.parameters.OptimizationType}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleOptimizationTypeChange}
                                >
                                    <MenuItem key={""} value={""}>Select optimization type</MenuItem>
                                    <MenuItem key={"time"} value={"time"}>Time</MenuItem>
                                    <MenuItem key={"distance"} value={"distance"}>Distance</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Current route lock type:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    id="optimization-type"
                                    value={this.state.parameters.CurrentRouteLockType}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleCurrentRouteLockTypeChange}
                                >
                                    <MenuItem key={""} value={""}>Select current route lock type</MenuItem>
                                    <MenuItem key={"vehicle"} value={"vehicle"}>Vehicle</MenuItem>
                                    <MenuItem key={"node"} value={"node"}>Node</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.AllowUserToChangeNavigationOrder}
                                            onChange={(e) => this.handleAllowUserToChangeNavigationOrderChanged(e)}
                                            name="allow-user-to-change-navigation-order"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Allow user to change navigation order"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.TimeWindowConstraint}
                                            onChange={(e) => this.handleTimeWindowConstraintChanged(e)}
                                            name="time-window-contraint"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Time window constraint"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.VehicleWorkingTimeConstraint}
                                            onChange={(e) => this.handleVehicleWorkingTimeConstraintChanged(e)}
                                            name="vehicle-working-time-contraint"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Vehicle working time constraint"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.VehicleStopsContraint}
                                            onChange={(e) => this.handleVehicleStopsContraintChanged(e)}
                                            name="vehicle-stops-contraint"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Vehicle stops constraint"
                                />
                            </Grid>

                            

                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.PickupDropoffContraint}
                                            onChange={(e) => this.handlePickupDropoffContraintChanged(e)}
                                            name="pickup-dropoff-contraint"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Pick-up drop-off constraint"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.VehicleMinimizeStartTime}
                                            onChange={(e) => this.handleVehicleMinimizeStartTimeChanged(e)}
                                            name="vehicle-minimize-start-time"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Minimize vehicle start time"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.VehicleMinimizeEndTime}
                                            onChange={(e) => this.handleVehicleMinimizeEndTimeChanged(e)}
                                            name="vehicle-minimize-end-time"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Minimize vehicle end time"
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.PrecedenceContraint}
                                            onChange={(e) => this.handlePrecedenceContraintChanged(e)}
                                            name="precedence-contraint"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Precedence contraint"
                                />
                                <Tooltip
                                    title={
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                            {`Similar to Pick-up drop-off constraint but the precedence is given by the column "preceding_node_id".
                                            Specifying the order offers greater versatility than 'Pick-up drop-off' constraint.

                                            Current implementation "preceding_node_id" of the delivery node is the pickup node of the request. So, it is similar to 'Pick-up drop-off' but can be extended in future.
                                            `}
                                        </div>
                                    }
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Grid>


                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.AllowDrop}
                                            onChange={(e) => this.handleAllowDropChanged(e)}
                                            name="allow-drop"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Allow drop"
                                />
                            </Grid>

                            {/*empty a.i. "Drop penality" sa fie mai la dreapta sa se vada ca depinde de Allow drop */}
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={6}>
                                <strong>Drop penality:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="DropPenality"
                                    name="DropPenality"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.DropPenality}
                                    onChange={this.handleDropPenalityChanged}
                                    disabled={!this.state.parameters.AllowDrop}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.MinimizeEmptyRoutes}
                                            onChange={(e) => this.handleMinimizeEmptyRoutesChanged(e)}
                                            name="minimize-empty-routes"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Minimize empty routes"
                                />
                            </Grid>

                            {/*empty a.i. "Empty route penality" sa fie mai la dreapta sa se vada ca depinde de Minimize empty routes */}
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={6}>
                                <strong>Empty route penality:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="EmptyRoutePenality"
                                    name="EmptyRoutePenality"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.EmptyRoutePenality}
                                    onChange={this.handleEmptyRoutePenalityChanged}
                                    disabled={!this.state.parameters.MinimizeEmptyRoutes}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={5}>
                            <Grid item xs={8}>
                                <strong>Daily End Time:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="DailyEndTime"
                                    name="DailyEndTime"
                                    type="time"
                                    variant="standard"
                                    value={this.state.parameters.DailyEndTime}
                                    onChange={this.handleDailyEndTimeChanged}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Maximum Allowed Waiting Time (seconds):</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="MaximumAllowWaitingTime"
                                    name="MaximumAllowWaitingTime"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.MaximumAllowWaitingTime}
                                    onChange={this.handleMaximumAllowWaitingTimeChanged}
                                />
                            </Grid>


                            <Grid item xs={8}>
                                <strong>Waiting Time at Location (seconds):</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="WaitingTimeAtLocation"
                                    name="WaitingTimeAtLocation"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.WaitingTimeAtLocation}
                                    onChange={this.handleWaitingTimeAtLocationChanged}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Global Cost Coefficient:</strong>
                                <Tooltip
                                    title={
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                            {`What it does:
                                            - It adds a term to the objective function of the routing problem.
                                            - This term is calculated as the product of the coefficient you set and the global span of the routes.
                                            - The global span is the difference between the maximum and minimum end times of all routes.

                                            Impact on the solution:
                                            - By increasing the coefficient, you prioritize minimizing the global span. This means the solver will try to create routes with similar lengths.
                                             Conversely, decreasing the coefficient gives less importance to the global span, allowing the solver to focus more on other objectives like minimizing total distance or time.

                                            Example:
                                            If you're solving a VRP and want to ensure that no vehicle is idle for too long while also minimizing total distance, you might increase the SetGlobalSpanCostCoefficient. This would encourage the solver to create routes with similar durations, reducing idle time.

                                            Additional considerations:
                                            Combining SetGlobalSpanCostCoefficient with other constraints or penalties can help achieve specific goals.
                                            Be aware that a very high coefficient might lead to suboptimal solutions if other objectives are more critical.
                                            `}
                                        </div>
                                    }
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="GlobalCostCoefficient"
                                    name="GlobalCostCoefficient"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.GlobalCostCoefficient}
                                    onChange={this.handleGlobalCostCoefficientChanged}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Manhattan distance factor:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="ManattanDistanceFactor"
                                    name="ManattanDistanceFactor"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.ManattanDistanceFactor}
                                    onChange={this.handlManattanDistanceFactorChanged}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={5}>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.InitialLoadTime}
                                            onChange={(e) => this.handleInitialLoadTimeChanged(e)}
                                            name="initial-load-time"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Initial Load Time"
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Initial Load Time Value (seconds):</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="InitialLoadTimeValue"
                                    name="InitialLoadTimeValue"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.InitialLoadTimeValue}
                                    onChange={this.handleInitialLoadTimeValueChanged}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.parameters.FinalUnloadTime}
                                            onChange={(e) => this.handleFinalUnloadTimeChanged(e)}
                                            name="final-unload-time"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Final Unload Time"
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Final Unload Time Value (seconds):</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="FinalUnloadTimeValue"
                                    name="FinalUnloadTimeValue"
                                    type="number"
                                    variant="standard"
                                    value={this.state.parameters.FinalUnloadTimeValue}
                                    onChange={this.handlFinalUnloadTimeValueChanged}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <strong>Working day:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="WorkingDay"
                                    name="WorkingDay"
                                    type="text"
                                    variant="standard"
                                    value={this.state.parameters.WorkingDay}
                                    onChange={this.handleWorkingDayChanged}
                                />
                            </Grid>
                        </Grid>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container                            
                            style={{ textAlign: "center" }}>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={this.handleSave}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h1 id="tabelLabel">Vehicles</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5} style={{ textAlign: "center" }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">Name</TableCell>
                                                <TableCell align="right">Description</TableCell>
                                                <TableCell align="right">Speed (km/h)</TableCell>
                                                <TableCell align="right">Pickup/dropoff capacity</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.vehicles.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell align="right">{row.name}</TableCell>
                                                    <TableCell align="right">{row.description}</TableCell>
                                                    <TableCell align="right">{row.speed}</TableCell>
                                                    <TableCell align="right">{row.pickup_dropoff_capacity}</TableCell>
                                                    <TableCell align="right">
                                                        <Button color="primary" onClick={(e) => this.handleShowVehiclesModal(row.vehicle_id)}>
                                                            Edit
                                                        </Button>
                                                        <Button color="secondary" onClick={(e) => this.handleDeleteVehicle(row.vehicle_id, row.name)}>
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container
                                    style={{ textAlign: "center" }}>
                                    <Grid item>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={(e) => this.handleShowVehiclesModal(0)}>
                                            Add new vehicle
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h1 id="tabelLabel">Depots</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5} style={{ textAlign: "center" }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">Name</TableCell>
                                                <TableCell align="right">Latitude</TableCell>
                                                <TableCell align="right">Longitude</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.depots.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell align="right">{row.name}</TableCell>
                                                    <TableCell align="right">{row.latitude}</TableCell>
                                                    <TableCell align="right">{row.longitude}</TableCell>
                                                    <TableCell align="right">
                                                        <Button color="primary" onClick={(e) => this.handleShowDepotsModal(row.depot_id)}>
                                                            Edit
                                                        </Button>
                                                        <Button color="secondary" onClick={(e) => this.handleDeleteDepot(row.depot_id, row.name)}>
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container
                                    style={{ textAlign: "center" }}>
                                    <Grid item>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={(e) => this.handleShowDepotsModal(0)}>
                                            Add new depot
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h1 id="tabelLabel">Test tools</h1>
                    </Grid>
                    <Grid container spacing={5} style={{ textAlign: "center" }}>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                color="primary"
                                onClick={(e) => this.handleTestToolsClearAllAllocations()}>
                                Clear all allocations
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.editParameters, // Selects which state properties are merged into the component's props
    EditParametersStore.actionCreators // Selects which action creators are merged into the component's props
)(EditParameters as any);