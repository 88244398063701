import * as React from 'react';
import { Box, Button, } from '@mui/material';
import * as FetchTripsStore from '../store/FetchTrips';
import * as utils from '../store/Utils';
import { withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...
type FetchTripsTableProps =
    utils.IClasses
    & FetchTripsStore.FetchTripsState // ... state we've requested from the Redux store
    & typeof FetchTripsStore.actionCreators; // ... plus action creators we've requested
    

const divStyle: React.CSSProperties = {
    overflow: 'auto'
};

export class FetchTripsTable extends React.PureComponent<FetchTripsTableProps> {

    
    constructor(props: Readonly<FetchTripsTableProps>) {
        super(props);

        // This binding is necessary to make "this" work in the callback  
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleNavigateMore = this.handleNavigateMore.bind(this);        
    }

    // Handle Delete request for an trip  
    private handleDelete(id: number, destination: string) {
        if (!window.confirm("Do you want to delete trip with destination: " + destination))
            return;
        else {
            this.props.requestDeleteTrip(id);
        }
    }
    private handleEdit(id: number) {
        this.props.navigate("/trip/edit/" + id);
    }



    // This method is called when the component is first added to the document
    public componentDidMount() {

    }

    public componentDidUpdate(prevProps: Readonly<FetchTripsTableProps>, prevState: any, snapshot: any) {

    }



    private handleNavigateMore(event: any) {
        this.props.navigateMore();
    }


    public render() {
        return (
            <React.Fragment>
                {this.renderTripsTable()}
            </React.Fragment>
        );
    }
    


    private renderTripsTable() {
        return (
            <div style={divStyle}>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Trip Id</th>
                            <th>Destination</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Comment</th>
                            <th>Create User</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.tripSearchResult.Data.map((trip: FetchTripsStore.TripData) =>
                            <tr key={trip.TripId}>
                                <td>{trip.TripId}</td>
                                <td>{trip.Destination}</td>
                                <td>{trip.StartDate}</td>
                                <td>{trip.EndDate}</td>
                                <td>{trip.Comment}</td>
                                <td>{trip.InsUser}</td>
                                <td>
                                    <Box sx={styled.buttonsBox}>
                                        <style>
                                            {`@media print {.buttons{display: none;}}`}
                                        </style>
                                        <Button variant="contained" color="primary" onClick={(id) => this.handleEdit(trip.TripId)}>
                                            Edit
                                        </Button>
                                        <Button variant="contained" color="secondary" onClick={(id) => this.handleDelete(trip.TripId, trip.Destination)}>
                                            Delete
                                        </Button>
                                    </Box>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="form-group text-center">                    
                    <Button variant="outlined" color="primary" disabled={this.props.tripSearchResult.AfterIdNext == -1} onMouseDown={this.handleNavigateMore}>
                        More
                    </Button>

                </div>
            </div>
        );
    }
}

export default withNavigation(FetchTripsTable);
