import { userConstants } from '../_constants';

export function registration(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
        case userConstants.UPDATE_REQUEST:
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return { isSaving: true };
        case userConstants.REGISTER_SUCCESS:            
        case userConstants.UPDATE_SUCCESS:
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {};
        case userConstants.REGISTER_FAILURE:
        case userConstants.UPDATE_FAILURE:
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {};
        default:
            return state
    }
}