import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as EditUserStore from '../store/EditUser';
import * as Validator from "../validator/Validator";
import { UserData } from '../store/FetchUsers';
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Switch, FormControlLabel, Checkbox } from '@mui/material';
import * as utils from '../store/Utils';
import { withParams, withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...

type EditUserProps =
    utils.IClasses
    & EditUserStore.EditUserState // ... state we've requested from the Redux store
    & typeof EditUserStore.actionCreators; // ... plus action creators we've requested


export class EditUser extends React.PureComponent<EditUserProps, UserData> {

    id = 0;

    constructor(props: Readonly<EditUserProps>) {
        super(props);

        this.state = {
            UserId: props.userData.UserId,
            UserName: props.userData.UserName,
            Type: props.userData.Type,
            Shop: props.userData.Shop,
            Company: props.userData.Company,
            Email: props.userData.Email,
            EmailConfirmed: props.userData.EmailConfirmed,
            FirstName: props.userData.FirstName,
            LastName: props.userData.LastName,
            PhoneNumber: props.userData.PhoneNumber,
            PhoneNumberConfirmed: props.userData.PhoneNumberConfirmed,
            UserEnabled: props.userData.UserEnabled,
            Roles: props.userData.Roles,
            Password1: props.userData.Password1,
            Password2: props.userData.Password2
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);

        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeEmailConfirmed = this.handleChangeEmailConfirmed.bind(this);
        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
        this.handleChangePhoneNumberConfirmed = this.handleChangePhoneNumberConfirmed.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);
        this.handleChangeShop = this.handleChangeShop.bind(this);
        this.handleCheckRole = this.handleCheckRole.bind(this);
        this.handleChangePassword1 = this.handleChangePassword1.bind(this);
        this.handleChangePassword2 = this.handleChangePassword2.bind(this);        
    }

    // This will handle the submit form event.  
    private async handleSave(event: any) {
        event.preventDefault();
        if (this.handleValidation()) {            
            this.props.requestSaveData(this.state, this.id > 0);
        }
    }
    // This will handle Cancel button click event.  
    private handleClose(e: any) {
        e.preventDefault();
        this.props.navigate("/fetchusers");
    }
    private async handleChangePassword(event: any) {
        event.preventDefault();
        var json = JSON.stringify(this.state);
        this.props.requestChangePassword(json);
        
    }

    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeUserName(event: any) {
        this.setState({ UserName: event.target.value });
    }
    handleChangeEmail(event: any) {
        this.setState({ Email: event.target.value });
    }

    handleChangeEmailConfirmed(event: any) {
        this.setState({ EmailConfirmed: event.target.checked });
    }
    handleChangeFirstName(event: any) {
        this.setState({ FirstName: event.target.value });
    }
    handleChangeLastName(event: any) {
        this.setState({ LastName: event.target.value });
    }
    handleChangePhoneNumber(event: any) {
        this.setState({ PhoneNumber: event.target.value });
    }
    handleChangePhoneNumberConfirmed(event: any) {
        this.setState({ PhoneNumberConfirmed: event.target.checked });
    }
    handleChangeEnabled(event: any) {
        this.setState({ UserEnabled: event.target.checked });
    }
    handleChangeType(event: any) {
        this.setState({ Type: event.target.value });
    }
    handleChangeCompany(event: any) {
        this.setState({ Company: Number(event.target.value) });
    }
    handleChangeShop(event: any) {
        this.setState({ Shop: Number(event.target.value) });
    }
    
    handleCheckRole(event: any) {
        var i;
        let roles = [...this.state.Roles];
        for (i = 0; i < roles.length; i++)
        {
            let role = { ...roles[i] };
            if (role.Value === event.target.value) {
                role.Selected = event.target.checked;
                roles[i] = role;
                break;
            }
        }
        this.setState({ Roles: roles });
    }    
    handleChangePassword1(event: any) {
        this.setState({ Password1: event.target.value });
    }
    handleChangePassword2(event: any) {
        this.setState({ Password2: event.target.value });
    }    

    // This method is called when the component is first added to the document
    componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate(prevProps: any, prevState: any) {

        //cand se schimba parametrul "id" din url trebui incarcate datele (este cazul cand se face redirect cu useNavigate)
        if (this.props.params != prevProps.params) {
            this.ensureDataFetched();
        }

        if (this.props.triggerLoad) {
            this.props.resetTriggerLoad();
            this.ensureDataFetched();
        }
        if (this.props.triggerLoadState) {

            this.props.resetTriggerLoadState();

            this.setState({
                UserId: this.props.userData.UserId,
                Type: this.props.userData.Type,
                Company: this.props.userData.Company,
                Shop: this.props.userData.Shop,
                UserName: this.props.userData.UserName,
                Email: this.props.userData.Email,
                EmailConfirmed: this.props.userData.EmailConfirmed,
                FirstName: this.props.userData.FirstName,
                LastName: this.props.userData.LastName,
                PhoneNumber: this.props.userData.PhoneNumber,
                PhoneNumberConfirmed: this.props.userData.PhoneNumberConfirmed,
                UserEnabled: this.props.userData.UserEnabled,
                Roles: this.props.userData.Roles
            });
        }
    }

    private ensureDataFetched() {                
        var idS = "0";
        if (this.props.params.id)
            idS = this.props.params.id;

        this.id = parseInt(idS) || 0;

        this.props.requestEditData(this.id);
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var Type = this.state.Type;
        var Company = this.state.Company;
        var Shop = this.state.Shop;

        var FirstName = this.state.FirstName;
        var LastName = this.state.LastName;
        var Email = this.state.Email;
        var PhoneNumber = this.state.PhoneNumber;

        if (Type == "") {
            err += "Select user type!\n";
            result = false;
        }
        if (Type == "COMPANY") {

            if (Company == 0) {
                err += "Select user company!\n";
                result = false;
            }

            if (Shop != 0) {
                err += "For company users you can not select shops!\n";
                result = false;
            }
        }

        if (Type == "SHOP") {

            if (Shop == 0) {
                err += "Select user shop!\n";
                result = false;
            }

            if (Company != 0) {
                err += "For shops users you can not select company!\n";
                result = false;
            }
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(FirstName)) {
            err += "First Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (FirstName.length <= 0 || FirstName.length > 50) {
            err += "First Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(LastName)) {
            err += "Last Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (LastName.length <= 0 || LastName.length > 50) {
            err += "Last Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isEmail(Email)) {
            err += "Email is not valid!\n";
            result = false;
        }
        if (Email.length <= 0 || Email.length > 100) {
            err += "Email should be between 1 and 100 characters!\n";
            result = false;
        }

        if (!Validator.isNumeric(PhoneNumber)) {
            err += "Phone Number is not valid!\n";
            result = false;
        }
        if (PhoneNumber.length <= 0 || PhoneNumber.length > 20) {
            err += "Phone Number should be between 1 and 20 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            this.props.setError(err)
        }

        return result;
    }

    public render() {
        let contents = this.props.isLoading
            ? <p><em>Loading...</em></p>
            : this.renderCreateForm();
        return <div>
            <h3>User management</h3>
            <hr />
            {contents}
        </div>;
    }
    

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <div>
                <fieldset>
                    <legend>Details</legend>
                    <form name="formEditUser" id="formEditUser" onSubmit={this.handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={5}>
                                <Grid container spacing={5}>
                                    {this.state.UserId == 0 ? null :                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                id="UserName"
                                                name="UserName"
                                                label="User Name"
                                                variant="standard"
                                                value={this.state.UserName}
                                                disabled
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.UserEnabled}
                                                        onChange={(e) => this.handleChangeEnabled(e)}
                                                        name="UserEnabled"
                                                        color="primary"
                                                    />
                                                }
                                                labelPlacement="end"
                                                label="Enabled"
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <TextField
                                            id="Email"
                                            name="Email"
                                            label="Email"
                                            variant="standard"
                                            value={this.state.Email}
                                            onChange={this.handleChangeEmail}
                                            required
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.EmailConfirmed}
                                                    onChange={(e) => this.handleChangeEmailConfirmed(e)}
                                                    name="EmailConfirmed"
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Email Confirmed"
                                        />
                                    </Grid>
                                    {this.state.UserId > 0 ? null :
                                        <>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="Password1"
                                                    name="Password1"
                                                    label="Password"
                                                    type="password"
                                                    variant="standard"
                                                    onChange={this.handleChangePassword1}
                                                    required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="Password2"
                                                    name="Password2"
                                                    label="Confirm Password"
                                                    type="password"
                                                    variant="standard"
                                                    onChange={this.handleChangePassword2}
                                                    required />
                                            </Grid>
                                        </>

                                    }
                                    <Grid item xs={4}>

                                        <InputLabel shrink id="labelType">
                                            Type
                                        </InputLabel>
                                        <Select
                                            id="Type"
                                            labelId="labelType"
                                            value={this.state.Type}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={this.handleChangeType}
                                        >
                                            <MenuItem value=""></MenuItem>
                                            <MenuItem value="COMPANY">Company</MenuItem>
                                            <MenuItem value="SHOP">Shop</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <InputLabel shrink id="labelCompany">
                                            Company
                                        </InputLabel>
                                        <Select
                                            id="Company"
                                            labelId="labelCompany"
                                            value={this.state.Company}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={this.handleChangeCompany}
                                        >
                                            {this.props.companies.map(o =>
                                                <MenuItem value={o.company_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <InputLabel shrink id="labelShop">
                                            Shop
                                        </InputLabel>
                                        <Select
                                            id="Company"
                                            labelId="labelShop"
                                            value={this.state.Shop}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={this.handleChangeShop}
                                        >
                                            {this.props.shops.map(o =>
                                                <MenuItem value={o.shop_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="FirstName"
                                            name="FirstName"
                                            label="First Name"
                                            variant="standard"
                                            value={this.state.FirstName}
                                            onChange={this.handleChangeFirstName}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="LastName"
                                            name="LastName"
                                            label="Last Name"
                                            variant="standard"
                                            value={this.state.LastName}
                                            onChange={this.handleChangeLastName}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="PhoneNumber"
                                            name="PhoneNumber"
                                            label="Phone Number"
                                            variant="standard"
                                            value={this.state.PhoneNumber}
                                            onChange={this.handleChangePhoneNumber}
                                            required
                                        />

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.PhoneNumberConfirmed}
                                                    onChange={(e) => this.handleChangePhoneNumberConfirmed(e)}
                                                    name="PhoneNumberConfirmed"
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Phone Number Confirmed"
                                        />
                                    </Grid>                                    
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <fieldset>
                                    <legend>User roles</legend>
                                    <Grid container spacing={1}>                                    
                                    {
                                        this.state.Roles.map(item => (
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                            checked={item.Selected}
                                                            value={item.Value}
                                                            key={item.Value}
                                                            onChange={this.handleCheckRole}
                                                            name="Roles"
                                                            color="primary"
                                                      />
                                                    }
                                                    label={item.Value}
                                                />
                                            </Grid>
                                        ))
                                        }
                                    </Grid>
                                </fieldset>
                            </Grid>                    
                            <Grid item xs={12}>
                                <Box sx={styled.buttonsBox}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Save
                                    </Button>                                
                                    <Button variant="contained" color="primary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
                {this.state.UserId == 0 ? null :
                    <fieldset>
                        <legend>Change password</legend>
                        <form name="formhandleChangePassword" onSubmit={this.handleChangePassword} >

                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <TextField
                                        id="Password1"
                                        name="Password1"
                                        label="Password"
                                        type="password"
                                        variant="standard"
                                        onChange={this.handleChangePassword1}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        id="Password2"
                                        name="Password2"
                                        label="Confirm Password"
                                        type="password"
                                        variant="standard"
                                        onChange={this.handleChangePassword2}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>

                                <Grid item xs={4}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Change password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </fieldset>
                }
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.editUser, // Selects which state properties are merged into the component's props
    EditUserStore.actionCreators // Selects which action creators are merged into the component's props
)(withParams(withNavigation(EditUser)));



