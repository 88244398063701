import * as React from 'react';

import * as AppStore from './store/App';
import { ApplicationState } from './store';
import { connect } from 'react-redux'

import { Route, Routes } from 'react-router-dom';

import { Layout } from './components/Layout';
import CookieConsent, { Cookies } from "react-cookie-consent";

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import authService from './components/api-authorization/AuthorizeService';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import Navigation from './components/Navigation';
import ApiInterface from './components/ApiInterface';

import { ThemeProvider, StyledEngineProvider, styled } from '@mui/material';
import theme from './theme';

import { ErrorBoundary } from './components/ErrorBoundary';
import { Login } from './components/api-authorization/Login'
import { Logout } from './components/api-authorization/Logout'

import { Home } from './components/Home';
import FetchUsers from './components/FetchUsers';
import EditUser from './components/EditUser';
import FetchTrips from './components/FetchTrips';
import EditTrip from './components/EditTrip';
import Map from './components/Map';
import MobileApp from './components/MobileApp';
import EditParameters from './components/EditParameters';
import FetchCompanies from './components/FetchCompanies';
import EditCompany from './components/EditCompany';
import FetchShops from './components/FetchShops';
import EditShop from './components/EditShop';


import TransportRequest from './components/TransportRequest';
import SearchTransportRequests from './components/SearchTransportRequests';
import Upload from './components/Upload';
import Settlements from './components/Settlements';
import Documents from './components/Documents';


import { PrivateRoute } from './_users/_components';
import { HomePage } from './_users/HomePage';
import { LoginPage } from './_users/LoginPage';
import { RegisterPage } from './_users/RegisterPage';


//import 'semantic-ui-css/semantic.min.css'
import './css/custom.css'

import * as utils from './store/Utils';
import { withLocation, withNavigation } from './hocs'

type AppProps =
    utils.IClasses
    & AppStore.AppState // ... state we've requested from the Redux store
    & typeof AppStore.actionCreators; // ... plus action creators we've requested    

export class App extends React.PureComponent<AppProps, AppStore.AppState> {
    static displayName = App.name;
    _subscription: number = 0;
    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => { this.populateState('populateState/subscribe'); });
        this.populateState('populateState/componentDidMount');
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }


    async populateState(event: string) {

        if (this.props.location.pathname != ApplicationPaths.LoginCallback) {

            let navigate = this.props.navigate;

            Promise.all([
                authService.isAuthenticated(),
                authService.getUser(),
            ])
                .then(([isAuthenticated, user]) => {

                    this.setState({
                        isAuthenticated: isAuthenticated,
                    });

                });

        }
    }
    componentDidUpdate(prevProps: any) {

        /*
        if (this.props.location.pathname !== prevProps.location.pathname) {
            console.log('location changed');
            console.log(this.props.location);
            console.log(prevProps.location);

            //console.log("ROUTE CHANGED");
            this.props.hideAll();
        }
        */
    }

    render() {
        return (
            <React.Fragment>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <ApiInterface />
                        <Navigation />
                        <ErrorBoundary>

                            <Routes>
                                <Route path='/' element={<Layout auth={this.state} />}>

                                    <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                                    <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                                    <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                                    <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                                    <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                                    <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                                    <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                                    <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />

                                    <Route path="/" element={
                                        <Home />
                                    } />

                                    <Route path="/fetchusers" element={
                                        <AuthorizeRoute component={<FetchUsers />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/user/edit/:id" element={
                                        <AuthorizeRoute component={<EditUser />}>
                                        </AuthorizeRoute>
                                    } />


                                    <Route path="/map" element={
                                        <AuthorizeRoute component={<Map />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/mobileapp" element={
                                        <AuthorizeRoute component={<MobileApp />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/parameters" element={
                                        <AuthorizeRoute component={<EditParameters />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/searchtransportrequests" element={
                                        <AuthorizeRoute component={<SearchTransportRequests />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/transportrequest/edit/:id" element={
                                        <AuthorizeRoute component={<TransportRequest />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/upload" element={
                                        <AuthorizeRoute component={<Upload />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/settlements" element={
                                        <AuthorizeRoute component={<Settlements />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/documents" element={
                                        <AuthorizeRoute component={<Documents />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/companies" element={
                                        <AuthorizeRoute component={<FetchCompanies />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/company/edit/:id" element={
                                        <AuthorizeRoute component={<EditCompany />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/shops" element={
                                        <AuthorizeRoute component={<FetchShops />}>
                                        </AuthorizeRoute>
                                    } />

                                    <Route path="/shop/edit/:id" element={
                                        <AuthorizeRoute component={<EditShop />}>
                                        </AuthorizeRoute>
                                    } />


                                    <Route path="/home" element={
                                        <PrivateRoute component={<HomePage />}>
                                        </PrivateRoute>
                                    } />

                                    <Route path="/fetchtrips" element={
                                        <PrivateRoute component={<FetchTrips />}>
                                        </PrivateRoute>
                                    } />

                                    <Route path="/trip/edit/:id" element={
                                        <PrivateRoute component={<EditTrip />}>
                                        </PrivateRoute>
                                    } />


                                    <Route path="/login" element={
                                        <LoginPage>
                                        </LoginPage>
                                    } />

                                    <Route path="/register" element={
                                        <RegisterPage profile={false}>
                                        </RegisterPage>
                                    } />

                                    <Route path="/profile" element={
                                        <RegisterPage profile={true}>
                                        </RegisterPage>
                                    } />

                                </Route>
                            </Routes>

                        </ErrorBoundary>
                        <CookieConsent
                            location="bottom"
                            buttonText="I understand"
                            cookieName="consent"
                            style={{ background: "#2B373B", zIndex: "2000" }}
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                            expires={150}
                        >
                            This website uses cookies to enhance the user experience.{" "}
                        </CookieConsent>
                    </ThemeProvider>
                </StyledEngineProvider>
            </React.Fragment>
        );
    }
}

function loginAction(name: string) {
    return (<Login action={name}></Login>);
}

function logoutAction(name: string) {
    return (<Logout action={name}></Logout>);
}


export default connect(
    (state: ApplicationState) => state.App, // Selects which state properties are merged into the component's props
    AppStore.actionCreators // Selects which action creators are merged into the component's props
)(withLocation(withNavigation(App as any)));
