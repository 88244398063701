import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as FetchShopsStore from '../store/FetchShops';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import * as utils from '../store/Utils';
import { withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...
type FetchShopsProps =
    utils.IClasses
    & FetchShopsStore.FetchShopsState // ... state we've requested from the Redux store
    & typeof FetchShopsStore.actionCreators; // ... plus action creators we've requested
    

export class FetchShops extends React.PureComponent<FetchShopsProps, FetchShopsStore.SearchData> {



    columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 0.1 },
        { field: 'name', headerName: 'Name', flex: 0.1 },
        { field: 'pickup_contact_person', headerName: 'Contact Person', flex: 0.1 },
        { field: 'enabled', headerName: 'Enabled', flex: 0.1 },
        {
            field: 'shop_id',
            headerName: 'Edit',
            width: 200,
            renderCell: (params: GridCellParams) => (
                <span>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={(id) => this.handleEdit(params.row['shop_id'] as number)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={(id) => this.handleDelete(params.row['shop_id'] as number, params.row['name']?.toString() || '')}
                    >
                        Delete
                    </Button>
                </span>
            ),
        },

    ];


    constructor(props: Readonly<FetchShopsProps>) {
        super(props);

        this.state = {
            PageSize: props.searchData.PageSize,
            PageNumber: props.searchData.PageNumber,
            AfterId: props.searchData.AfterId,
            name: props.searchData.name,
            Enabled: props.searchData.Enabled,
            pickup_contact_person: props.searchData.pickup_contact_person,
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleNewShop = this.handleNewShop.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleChangePickupContactPerson = this.handleChangePickupContactPerson.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
    }


    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
    }
    handleChangeEnabled(event: any) {
        this.setState({ Enabled: event.target.value });
    }
    handleChangePickupContactPerson(event: any) {
        this.setState({ pickup_contact_person: event.target.value });
    }
    async handleChangePageSize(event: any) {
        await this.setState({ PageSize: event.target.value, PageNumber: 0 });
        this.search();
    }

    // Handle Delete request for an employee  
    private handleDelete(shop_id: number, name: string) {
        if (!window.confirm("Do you want to delete the shop: " + name))
            return;
        else {
            this.props.requestDeleteShop(shop_id);
        }
    }
    private handleEdit(shopId: number) {
        this.props.navigate("/shop/edit/" + shopId);
    }
    private async handleSearch(event: any) {
        event.preventDefault();
        await this.setState({ PageNumber: 0 });
        if (this.handleValidation()) {
            this.search();
        }
    }

    private async handleNewShop(event: any) {
        this.props.navigate("/shop/edit/0");
    }

    private async handleNavigation(pageNumber: number) {

        await this.setState({ PageNumber: pageNumber });

        if (this.handleValidation()) {
            this.search();
        }
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var name = this.state.name;
        var pickup_contact_person = this.state.pickup_contact_person;

        if (name != null && name.length > 0 && !Validator.isAlphaNumericAndSpace(name)) {
            err += "Name should be alpha numetic!\n";
            result = false;
        }
        if (name != null && name.length > 50) {
            err += "Name should not exceed 50 characters!\n";
            result = false;
        }

        if (pickup_contact_person != null && pickup_contact_person.length > 0 && !Validator.isAlphaNumericAndSpace(pickup_contact_person)) {
            err += "Contact Person should be alpha numetic!\n";
            result = false;
        }
        if (pickup_contact_person != null && pickup_contact_person.length > 100) {
            err += "Contact Person should not exceed 100 characters!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
        }
        return result;
    }

    private search() {
        this.props.requestShopsData(this.state);
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        if (this.props.triggerSearch) {
            this.search();
        }
    }

    private ensureDataFetched() {
        this.props.requestSearchBoxData();

    }

    public render() {

        return (
            <React.Fragment>
                <h1 id="tabelLabel">Manage shops</h1>
                {this.renderSearchBox()}
                {this.renderSearchResult()}
            </React.Fragment>
        );
    }

    private renderSearchBox() {
        return (
            this.props.isLoadingSearchBox ?
                <p><em>Loading search parameters...</em></p> :                
                <fieldset>
                    <legend>Search criteria</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>                            
                            <TextField
                                id="Name"
                                name="Name"
                                label="Name"
                                variant="standard"
                                value={this.state.name}
                                onChange={this.handleChangeName}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="ContactPerson"
                                name="ContactPerson"
                                label="Contact Person"
                                variant="standard"
                                value={this.state.pickup_contact_person}
                                onChange={this.handleChangePickupContactPerson}
                            />
                        </Grid>
                        <Grid item xs={3}>                            
                            <InputLabel shrink id="labelEnabled">
                                Enabled
                            </InputLabel>
                            <Select
                                id="Enabled"
                                labelId="labelEnabled"
                                value={this.state.Enabled}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={this.handleChangeEnabled}                                
                                >
                                {this.props.yesNoList.map(o =>
                                    <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel shrink id="labelPageSize">
                                Page size
                            </InputLabel>
                            <Select
                                id="PageSize"
                                labelId="labelPageSize"
                                value={this.state.PageSize}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={this.handleChangePageSize}
                            >
                                {this.props.pageSizeList.map(o =>
                                    <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={styled.buttonsBox}>
                                <Button variant="contained" color="primary" onClick={this.handleSearch}>
                                    Search
                                </Button>                            
                                <Button variant="contained" color="primary" onClick={this.handleNewShop} >
                                    Create new shop
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </fieldset>
        );
    }

    private renderSearchResult() {
        return (
            <div>
                <fieldset>
                    <legend>Search results</legend>
                    <DataGrid autoHeight
                        rows={this.props.searchResult.Data}
                        columns={this.columns}
                        rowCount={this.props.searchResult.RowCount}                        
                        pagination
                        pageSizeOptions={[this.state.PageSize]}
                        paginationMode="server"
                        paginationModel= {{ pageSize: this.state.PageSize, page: this.state.PageNumber }}
                        onPaginationModelChange={(e) => this.handleNavigation(e.page)}
                    />

                    
                    <div className="form-group text-center">
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.First)} onClick={(id) => this.handleNavigation(this.props.searchResult.First)}>{Navigation.displayNavigationText('First', this.props.searchResult.First)}</Button>
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Prev)} onClick={(id) => this.handleNavigation(this.props.searchResult.Prev)}>{Navigation.displayNavigationText('Previous', this.props.searchResult.Prev)}</Button>
                        <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', this.props.searchResult.Current)}</Button>
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Next)} onClick={(id) => this.handleNavigation(this.props.searchResult.Next)}>{Navigation.displayNavigationText('Next', this.props.searchResult.Next)}</Button>
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Last)} onClick={(id) => this.handleNavigation(this.props.searchResult.Last)}>{Navigation.displayNavigationText('Last', this.props.searchResult.Last)}</Button>
                    </div>
                </fieldset>
            </div>
        );
    }




}

export default connect(
    (state: ApplicationState) => state.fetchShops, // Selects which state properties are merged into the component's props
    FetchShopsStore.actionCreators // Selects which action creators are merged into the component's props
)(withNavigation(FetchShops));
