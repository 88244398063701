import * as React from 'react';
import { connect } from 'react-redux'
import { Form, TextField2, TextAreaField, SelectField, MoneyField, DateTimeField, SubmitButton } from './FormElements';
import { Box, Autocomplete, Button, Grid } from '@mui/material';
import * as Yup from 'yup';
import { ApplicationState } from '../store';
import * as utils from '../store/Utils';
import * as TransportRequestStore from '../store/TransportRequest';
import { FormikValues, FormikHelpers, FieldProps, Field } from 'formik';
import _ from 'lodash';
import { withParams, withNavigation } from '../hocs'
import * as styled from './StyledComponents'


interface TransportRequestLocalState {
    formData: {};
    validationSchema: {};
}

type TransportRequestProps =
    utils.IClasses
    & TransportRequestStore.TransportRequestPropsToPass
    & TransportRequestStore.TransportRequestState // ... state we've requested from the Redux store
    & typeof TransportRequestStore.actionCreators; // ... plus action creators we've requested


class TransportRequest extends React.PureComponent<TransportRequestProps, TransportRequestLocalState> {

    id = 0;

    constructor(props: Readonly<TransportRequestProps>) {
        super(props);

        this.state = {
            formData: [],
            validationSchema: []
        };

        // This binding is necessary to make "this" work in the callback
        this.handleClose = this.handleClose.bind(this);
        this.handleSenderChanged = this.handleSenderChanged.bind(this);
    }    

    componentDidMount = async () => {
        this.ensureDataFetched();
    };

    public componentDidUpdate(prevProps: any, prevState: any) {

        //cand se schimba parametrul "id" din url trebui incarcate datele (este cazul cand se face redirect cu useNavigate)
        if (this.props.params != prevProps.params) {
            this.ensureDataFetched();
        }

        if (this.props.triggerInitForm) {
            this.props.resetTriggerInitForm();
            this.initForm();
        }

        if (this.props.triggerLoad) {
            this.props.resetTriggerLoad();
            this.ensureDataFetched();
        }
    }

    private ensureDataFetched() {

        var idS = "0";
        if (this.props.params.id)
            idS = this.props.params.id;

        this.id = parseInt(idS) || 0;

        //daca apelul vine din pop-ul din componenta <Map> atunci id-ul este cel furnizat in props
        if (this.props.transportId > 0) {
            this.id = this.props.transportId;
        }

        this.props.requestEditData(this.id);
    }

    initForm = () => {

        let _validationSchema = {};

        for (var row of this.props.formSchema)
            for (var element of row) {
                //_formData[key] = "";
                if (element.type === "text") {
                    _validationSchema[element.name] = Yup.string();
                }
                else if (element.type === "email") {
                    _validationSchema[element.name] = Yup.string().email()
                }
                else if (element.type === "select") {
                    if (element.options)
                        _validationSchema[element.name] = Yup.string().oneOf(element.options.map(o => o.value));
                }
                else if (element.type === "money") {
                    _validationSchema[element.name] = Yup.string();
                    if (element.options) {
                        _validationSchema[element.name + "_ccy"] = Yup.string().oneOf(element.options.map(o => o.value));
                    }
                }
                else if (element.type === "datetime") {
                    _validationSchema[element.name] = Yup.date();
                }

                if (element.required) {
                    _validationSchema[element.name] = _validationSchema[element.name].required('Required');

                    if (_validationSchema[element.name + "_ccy"] != null)
                        _validationSchema[element.name + "_ccy"] = _validationSchema[element.name + "_ccy"].required('Required');

                }
            }

        var _validationSchemaObj = Yup.object().shape({ ..._validationSchema });
        this.setState({ formData: this.props.formValues, validationSchema: _validationSchemaObj })
    }

    getFormElement = (element: TransportRequestStore.Element) => {
        const props = {
            name: element.name,
            label: element.label,
            options: element.options,
        };

        if (element.type === "text" || element.type === "email") {
            return <TextField2 {...props} />
        }

        if (element.type === "select") {
            return <SelectField  {...props} />
        }

        if (element.type === "money") {
            return <MoneyField  {...props} />
        }

        if (element.type === "datetime") {
            return <DateTimeField  {...props} />
        }

    }
    handleSenderChanged(event: any, newValue: TransportRequestStore.TransportRequestSender | null) {

        if (newValue) {
            const details_json = JSON.parse(newValue.details);

            let newFormData = Object.assign({}, this.state.formData);

            for (const [key, value] of Object.entries(details_json)) {
                console.log(`${key}: ${value}`);
                newFormData[key] = value;
            }
            this.setState({ formData: newFormData });
            console.log(this.state.formData);
        }
    }
    onSubmit = (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
        //console.log(values);
        actions.setSubmitting(false);
        var s = JSON.stringify(values);
        this.props.requestSaveData(this.id, s);
    }

    private handleClose(e: any) {
        e.preventDefault();
        if (this.props.onCloseCallback)
            this.props.onCloseCallback()
        else
            this.props.navigate("/searchtransportrequests");
    }

    validate = (values: any, props: any /* only available when using withFormik */) => {
        const errors = {};

        if (!values.email) {
            errors["email"] = 'Required';
        } else if (values.email != "a@a.ro") {
            errors["email"] = 'Email address should be a@a.ro';
        }

        //...

        return errors;
    };


    render() {
        return (
            <div className="App">
                {_.isEmpty(this.state.formData) ? "" :
                    <Form
                        enableReinitialize={true}
                        initialValues={this.state.formData}
                        validate={this.validate}
                        validationSchema={this.state.validationSchema}
                        onSubmit={this.onSubmit}
                    >

                        <Grid id="top-row" container spacing={5}>
                            <Grid item xs={6}>
                                <fieldset>
                                    <legend>Sender</legend>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                autoSelect
                                                onChange={(e, newData) => this.handleSenderChanged(e, newData)}
                                                options={this.props.senderList}
                                                getOptionLabel={(option: TransportRequestStore.TransportRequestSender) => option.sender_name}
                                                renderInput={(params) =>
                                                    <TextField2 {...params} name="sender_predefined_list" label="Sender Predefined List" />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 name="sender_name" label="Name" />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField2 name="sender_addess_street" label="Street" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_number" label="Number" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_block" label="Block" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_stair" label="Stair" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_floor" label="Floor" />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_apartment" label="Apartment" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_interphone" label="Interphone" />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField2 name="sender_addess_locality" label="Locality" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_area" label="Area" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextField2 name="sender_addess_country" label="Country" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="sender_addess_postalcode" label="Postal Code" />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField2 name="sender_contact_person" label="Contact Person" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField2 name="sender_contact_phone" label="Phone" />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid item xs={6}>
                                <fieldset>
                                    <legend>Receiver</legend>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <TextField2 name="receiver_name" label="Name" />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField2 name="receiver_addess_street" label="Street" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_number" label="Number" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_block" label="Block" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_stair" label="Stair" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_floor" label="Floor" />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_apartment" label="Apartment" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_interphone" label="Interphone" />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField2 name="receiver_addess_locality" label="Locality" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_area" label="Area" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextField2 name="receiver_addess_country" label="Country" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField2 name="receiver_addess_postalcode" label="Postal Code" />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField2 name="receiver_contact_person" label="Contact Person" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField2 name="receiver_contact_phone" label="Phone" />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                        </Grid>
                        <Grid id="middle-row" container spacing={5}>
                            <Grid item xs={12}>
                                <fieldset>
                                    <legend>Remarks</legend>
                                    <TextAreaField name="remarks" />
                                </fieldset>
                            </Grid>
                        </Grid>
                        <Grid id="middle-row" container spacing={5}>
                            <Grid item xs={12}>
                                <fieldset>
                                    <legend>Additional Informations</legend>
                                    <Grid id="bottom-row-details" container spacing={5}>
                                        {_.isEmpty(this.props.formSchema) || this.props.formSchema.length == 0 ? "" :
                                            this.props.formSchema.map((row, index) => (
                                                row.map(element => (
                                                    <Grid item xs={utils.getGridCol(element.col)}>
                                                        {this.getFormElement(element)}
                                                    </Grid>
                                                ))
                                            ))}
                                    </Grid>
                                </fieldset>
                            </Grid>
                        </Grid>
                        <Box sx={styled.buttonsBox}>
                            <Button variant="contained" color="primary" type="submit">
                                Save
                            </Button>   
                            <Button variant="contained"
                                onClick={this.handleClose}>
                                Close
                            </Button>
                        </Box>
                    </Form>
                }
            </div>

        );
    }
}


export default connect(
    (state: ApplicationState) => state.transportRequest, // Selects which state properties are merged into the component's props
    TransportRequestStore.actionCreators // Selects which action creators are merged into the component's props
)(withParams(withNavigation(TransportRequest)));

