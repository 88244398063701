import { userService } from '../_services';
import { serialize } from '../../store/Utils'

export async function authHeader() {
    // return authorization header with jwt token    
    
    const access_token = await accessToken();
    
    if (access_token != '') {
        return { 'Authorization': 'Bearer ' + access_token };
    } else {
        return {};
    }
}

export async function accessToken() {
    // return access token
    await checkExpSerialized();
    let loginData = JSON.parse(localStorage.getItem('loginData'));
    
    if (loginData && loginData.token && loginData.token.access_token) {
        return loginData.token.access_token;
    } else {
        return '';
    }
}

async function checkExp() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData) {
        const token = loginData.token;
        if (token) {
            const exp = token.exp;
            const refresh_token = token.refresh_token;
            const secondsSinceEpoch = Math.round(Date.now() / 1000);            

            if (secondsSinceEpoch + 10 > exp) {
                console.log('start refresh token');                
                await userService.login('', '', refresh_token);
                console.log('end  refresh token');
            }
        }
    }
}


//https://advancedweb.hu/how-to-serialize-calls-to-an-async-function/
const checkExpSerialized = serialize((() => checkExp() ));

const wait = (time) => new Promise((res) => setTimeout(res, time));
