import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_post, http_axios_get, http_axios_put } from './ApiInterface';
import * as utils from './Utils';
import * as Models from "../models/Models";
import { User } from '../models/Models';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type MyMap<K extends string | number | symbol, V> = {
    [key in K]: V;
};

export interface MapState {
    isLoading: boolean;
    isLoadedStaticData: boolean;
    isSavingAllocation: boolean;
    isSavingStatus: boolean;
    isSavingPriority: boolean;
    transportRequestStatus: TransportRequestStatus[];
    users: User[];
    depots: Depot[];
    filterTransportRequests: FilterTransportRequests;
    filterShippers: FilterShippers;
    mapbox_access_token: string;
    mapbox_styles: MapboxStyle[];
    triggerLoadState: boolean;
}

export class FilterTransportRequests {
    transport_id: string = '';
    sender_name: string = '';
    receiver_name: string = '';
    fromAnyTime: boolean = true;
    from: string = utils.getDateTimeISO();
    toAnyTime: boolean = true;
    to: string = utils.getDateTimeISO();
    status: Array<number> = [];
    allocated_to: number = 0;
}

export class FilterShippers {
    user_name: string = "";
    filterAvailableToWork: boolean = true;
    filterAvailableToWorkConfirmed: boolean = true;
    filterRequestsAllocated: boolean = true;
    availableToWork: string = "";
    availableToWorkConfirmed: string = "";
    requestsAllocated: string = "";
}


export class Shipper {
    user_id: number = 0;
    user_name: string = "";
    first_name: string = "";
    last_name: string = "";
    available_to_work: boolean = false;
    available_to_work_confirmed: boolean = false;
    show_route: boolean = false;
    visible: boolean = true;
}


export class ShipperLocation {
    user_id: number = 0;
    latitude: number = 0;
    longitude: number = 0;
    speed: number = 0;
    status: number = 0;
}

export class Notification102 {
    action: string = "";
    transport_id: number = 0;
    requests: Array<TransportRequest> = [];
}

export class SignalrNotification {
    Id: number = 0;
    Type: number = 0;
    Epoch: number = 0;
    Payload: string = "";
}

export class AllocationUpdateNavigationOrder {
    user_id: number = 0;
    transport_id: number = 0;
    allocation_id: number = 0;
    navigation_order: number = 0;
}

export class TransportRequest {
    transport_id: string = "";
    priority: number = 0;
    status: number = 0;
    status_desc: string = "";
    label_color: string = "";
    label_background_color: string = "";
    sender_latitude: number = 0;
    sender_longitude: number = 0;
    receiver_latitude: number = 0;
    receiver_longitude: number = 0;
    time_since_creation: string = "";
    ins_date: string = "";
    summary: string = "";
    pickup_estimated_date: string = "";
    delivery_estimated_date: string = "";
    details: TransportRequestDetails = {
        sender_name: "",
        sender_contact_person: "",
        sender_contact_phone: "",
        receiver_name: "",
        receiver_contact_person: "",
        pickup_preferred_date: "",
        delivery_preferred_date: "",        
    };
    allocations: Array<Allocation> = [];
    allocations_new: number = 0;
    visible: boolean = true;

}

export class TransportRequestDetails {
    sender_name: string = "";
    sender_contact_person: string = "";
    sender_contact_phone: string = "";
    receiver_name: string = "";
    receiver_contact_person: string = "";
    pickup_preferred_date: string = "";
    delivery_preferred_date: string = "";
}

export class TransportRequestStatus {
    status: string = "-1";
    description: string = "";
    badge_variant: string = "";
    tracking_real_time: number = 0;
}

export class Depot {
    depot_id: number = 0;
    name: string = "";
}

export class MapboxStyle {
    style_id: number = 0;
    url: string = "";
    name: string = "";
}

export class Allocation {
    user_id: number = 0;
    user_name: string = "";
    type: number = 0;
    allocation_id: number = 0;
    transport_id: number = 0;
    details: string = "";
    details_first_line: string = "";
    details_second_line: string = "";
    details_third_line: string = "";
    action_description: string = "";
    location_description: string = "";
    status: number = 0;
    status_description: string = "";
    navigation_order: number = 0;
    latitude: number = 0;
    longitude: number = 0;
    estimated_time_arrival: string = "";
    distance_total: string = "";
    duration_total: string = "";
    in_time_status: number = 0;
    in_time_status_description: string = "";
}
export class AllocationEstimation {
    allocation_id: number = 0;
    distance_total: string = "";
    duration_total: string = "";
    estimated_time_arrival: string = "";
    in_time_status: number = 0;
    in_time_status_description: string = "";
    navigation_order: number = 0;
}

export class UserAllocationSummary {    
    distance_total: string = "";
    duration_total: string = "";
    number_of_accepted_allocations: number = 0;
    number_of_new_allocations: number = 0;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RequestStaticDataAction {
    type: 'MAP_REQUEST_STATIC_DATA';
}
export interface ReceiveStaticDataAction {
    type: 'MAP_RECEIVE_STATIC_DATA';
    transportRequestStatus: TransportRequestStatus[];
    depots: Depot[];
    mapbox_access_token: string;
    mapbox_styles: MapboxStyle[];
    users: User[];
}

export interface RequestAllocationAction {
    type: 'MAP_REQUEST_ALLOCATION';
}
export interface ReceiveAllocationAction {
    type: 'MAP_RECEIVE_ALLOCATION';
}

export interface RequestChangeStatusAction {
    type: 'MAP_REQUEST_CHANGE_STATUS';
}
export interface ReceiveChangeStatusAction {
    type: 'MAP_RECEIVE_CHANGE_STATUS';
}

export interface RequestPriorityUpdateAction {
    type: 'MAP_REQUEST_PRIORITY_UPDATE';
}
export interface ReceivePriorityUpdateAction {
    type: 'MAP_RECEIVE_PRIORITY_UPDATE';
}


export interface ApplyFilterTrasnportRequestsAction {
    type: 'MAP_APPLY_FILTER_TRANSPORT_REQUESTS';
    filterTransportRequests: FilterTransportRequests;
}

export interface ApplyFilterShippersAction {
    type: 'MAP_APPLY_FILTER_SHIPPERS';
    filterShippers: FilterShippers;
}

export interface ResetTriggerLoadState {
    type: 'MAP_RESET_TRIGGER_LOAD_STATE';
}




// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |    
    RequestStaticDataAction |
    ReceiveStaticDataAction |
    RequestAllocationAction |
    ReceiveAllocationAction |
    RequestChangeStatusAction |
    ReceiveChangeStatusAction |
    RequestPriorityUpdateAction |
    ReceivePriorityUpdateAction |
    ApplyFilterTrasnportRequestsAction |
    ApplyFilterShippersAction |
    ResetTriggerLoadState;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,    
    
    loadStaticData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {           

            Promise.all([
                http_axios_get('api/TransportRequestsStatus')
                    .then(requestStatus => {
                        return requestStatus;
                    }),
                http_axios_get('api/Depots')
                    .then(depots => {
                        return depots;
                    }),
                http_axios_get('api/MapboxToken')
                    .then(access_token => {
                        return access_token;
                    }),
                http_axios_get('api/MapboxStyles')
                    .then(styles => {
                        return styles;
                    }),
                http_axios_get('api/Lists/UsersByCompany')
                    .then(users => {
                        return users;
                    }),
            ]).then(([requestStatus, depots, access_token, styles, users]) => {
                const requestStatusList = requestStatus as TransportRequestStatus[];
                const depotsList = depots as Depot[];
                const mapbox_styles = styles as MapboxStyle[];
                const usersList = users as User[];

                var trakingStatus: TransportRequestStatus[] = [];
                requestStatusList.forEach(function (item, index) {
                    if (item.tracking_real_time == 1) {
                        var newitem: TransportRequestStatus = {
                            status: item.status,
                            description: item.description,
                            badge_variant: item.badge_variant,
                            tracking_real_time: item.tracking_real_time
                        };
                        trakingStatus.push(newitem);
                    }
                });
                dispatch({ 
                    type: 'MAP_RECEIVE_STATIC_DATA', 
                    transportRequestStatus: trakingStatus, 
                    depots: depotsList, 
                    mapbox_access_token: access_token,
                    mapbox_styles: mapbox_styles,
                    users: usersList,
                });
            })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'MAP_REQUEST_STATIC_DATA' });
        }
    },
    allocateRequest: (transportId: string, allocationType: number, userId: number, depotId: number, userIdToDepot: number, userIdFromDepot: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = '';

        if (appState) {
            url = 'api/Allocation';

            var data = {
                "transport_id": transportId,
                "allocation_type": allocationType,
                "user_id": userId,
                "depot_id": depotId,
                "user_id_to_depot": userIdToDepot,
                "user_id_from_depot": userIdFromDepot,
            };
            var payload = JSON.stringify(data);

            http_axios_post(url, payload)
                .then(response => {
                    dispatch({ type: 'MAP_RECEIVE_ALLOCATION' });
                })
                .catch(function (e) {
                    console.log(e);
                });
            dispatch({ type: 'MAP_REQUEST_ALLOCATION' });
        }
    },
    changeStatus: (transportId: string, status: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = '';

        if (appState) {
            url = 'api/TransportRequestsStatus';

            var data = {
                "transport_id": transportId,
                "status": status,
            };
            var payload = JSON.stringify(data);

            http_axios_post(url, payload)
                .then(response => {
                    dispatch({ type: 'MAP_RECEIVE_CHANGE_STATUS' });
                })
                .catch(function (e) {
                    console.log(e);
                });
            dispatch({ type: 'MAP_REQUEST_CHANGE_STATUS' });
        }
    },

    updatePriority: (transportId: number, priority: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = '';

        if (appState) {
            url = 'api/TransportRequestsPriority';

            var data = {
                "transport_id": transportId,
                "priority": priority,
            };
            var payload = JSON.stringify(data);

            http_axios_post(url, payload)
                .then(response => {
                    dispatch({ type: 'MAP_RECEIVE_PRIORITY_UPDATE' });
                })
                .catch(function (e) {
                    console.log(e);
                });
            dispatch({ type: 'MAP_REQUEST_PRIORITY_UPDATE' });
        }
    },
    
    availableToWorkConfirmedChanged: (user_id: string, checked: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = '';

        if (appState && appState.map) {

            url = 'api/Users/AvailableToWorkConfirmed';
            var obj = { UserId: Number(user_id), AvailableToWorkConfirmed: checked };
            var data = JSON.stringify(obj);

            http_axios_put(url, data)
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
    
    applyFilterTrasportRequests: (filter: FilterTransportRequests): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'MAP_APPLY_FILTER_TRANSPORT_REQUESTS', filterTransportRequests: filter });
        }
    },
    applyFilterShippers: (filter: FilterShippers): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'MAP_APPLY_FILTER_SHIPPERS', filterShippers: filter });
        }
    },
    resetTriggerLoadState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'MAP_RESET_TRIGGER_LOAD_STATE' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MapState = {
    isLoading: false,
    isLoadedStaticData: false,
    isSavingAllocation: false,
    isSavingStatus: false,
    isSavingPriority: false,
    transportRequestStatus: [],
    depots: [],
    users: [],
    filterTransportRequests: {
        transport_id: '',
        sender_name: '',
        receiver_name: '',
        fromAnyTime: true,
        from: utils.getDateTimeISO(),
        toAnyTime: true,
        to: utils.getDateTimeISO(),
        status: [],
        allocated_to: 0,

    },
    filterShippers: {
        user_name: "",
        filterAvailableToWork: false,
        filterAvailableToWorkConfirmed: false,
        filterRequestsAllocated: false,
        availableToWork: "Yes",
        availableToWorkConfirmed: "Yes",
        requestsAllocated: "Yes",
    },
    mapbox_access_token: "",
    mapbox_styles: [],
    triggerLoadState: false,
    
};

export const reducer: Reducer<MapState> = (state: MapState | undefined, incomingAction: Action): MapState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MAP_REQUEST_STATIC_DATA':
            return {
                ...state,
                isLoadedStaticData: false,
            };
        case 'MAP_RECEIVE_STATIC_DATA':
            return {
                ...state,
                isLoadedStaticData: true,
                transportRequestStatus: action.transportRequestStatus,
                depots: action.depots,
                mapbox_access_token: action.mapbox_access_token,
                mapbox_styles: action.mapbox_styles,
                triggerLoadState: true,
                users: action.users,
            };
        case 'MAP_REQUEST_ALLOCATION':
            return {
                ...state,
                isSavingAllocation: true,
            };
        case 'MAP_RECEIVE_ALLOCATION':
            return {
                ...state,
                isSavingAllocation: false,
            };
        case 'MAP_REQUEST_CHANGE_STATUS':
            return {
                ...state,
                isSavingStatus: true,
            };
        case 'MAP_RECEIVE_CHANGE_STATUS':
            return {
                ...state,
                isSavingStatus: false,
            };
        case 'MAP_REQUEST_PRIORITY_UPDATE':
            return {
                ...state,
                isSavingPriority: true,
            };
        case 'MAP_RECEIVE_PRIORITY_UPDATE':
            return {
                ...state,
                isSavingPriority: false,
            };
        case 'MAP_APPLY_FILTER_TRANSPORT_REQUESTS':
            return {
                ...state,
                filterTransportRequests: action.filterTransportRequests,
            };

        case 'MAP_APPLY_FILTER_SHIPPERS':
            return {
                ...state,
                filterShippers: action.filterShippers,
            };
        case 'MAP_RESET_TRIGGER_LOAD_STATE':
            return {
                ...state,
                triggerLoadState: false,
            };
        default:
            return state;
    }
};
