import * as React from 'react';
import * as mapboxgl from 'mapbox-gl';
import '../css/Map.css';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as MapStore from '../store/Map';
import { withWindowDimensions } from '../hocs'
import {        
    Grid,    
} from '@mui/material';
import * as utils from '../store/Utils';
import { GeoJSONSource } from 'mapbox-gl';
import { Point, LineString, Feature, Geometry, GeoJsonProperties, FeatureCollection } from 'geojson'
import Cookies from 'universal-cookie';
import MapTransportRequests from './MapTransportRequests';
import MapShippers from './MapShippers';

import { HubConnectionBuilder, HubConnection, HubConnectionState, HttpTransportType } from '@microsoft/signalr';
import authService from '../components/api-authorization/AuthorizeService';
import {  Allocation, MyMap, Shipper, ShipperLocation, SignalrNotification, TransportRequest, UserAllocationSummary } from '../store/Map'
import { last_epoch, last_epochs, last_proccessing, notifications_tank, proccessNotification, setLastEpoch, setNotificationsTank } from '../store/SignalRUtils';
import { store } from '../store/configureStore';

// At runtime, Redux will merge together...
type MapProps =
    utils.IClasses
    & MapStore.MapState // ... state we've requested from the Redux store    
    & typeof MapStore.actionCreators; // ... plus action creators we've requested
    

interface MapState {
    mapStyle: number;
    shippers: MyMap<number, Shipper>;
    shippersDate: string;
    shippersLocation: MyMap<number, ShipperLocation>; //to do de inlocuit MyMap cu Map pt ca nu mai e nevoie sa fie serializat obiectul (pt nu il mai punem in redux state)
    shippersLocationDate: string;
    transportRequests: Array<TransportRequest>;
    transportRequestsDate: string;
    allocations_group_by_user_id: MyMap<string, Array<Allocation>>; //to do de inlocuit MyMap cu Map (+modificat user_id din string in number) pt ca nu mai e nevoie sa fie serializat obiectul (pt nu il mai punem in redux state)
    allocations_group_by_user_id_summary: MyMap<string, UserAllocationSummary>; //to do de inlocuit MyMap cu Map (+modificat user_id din string in number) pt ca nu mai e nevoie sa fie serializat obiectul (pt nu il mai punem in redux state)
}

export type desynchronizationDetected = (_notification_type: number,
    _description: string,
    _last_epoch: number,
    _current_epoch: number,
    _last_payload: string,
    _current_payload: string) => void;

export type refreshShippersList = () => void;
export type availableToWorkConfirmed = (user_id: number, confirmed: boolean) => void;
export type refreshTransportRequests = () => void;
export type recomputeRoute = () => void;


const divStyleMap = (windowDimensions: any):React.CSSProperties => {
    return { 
        width: '100%',
        height: windowDimensions.height - 70
    };
};

const divStyleMapSelectStyle = (windowDimensions: any):React.CSSProperties => {
    return { 
        position: 'absolute',
        top: '60px',
        left: windowDimensions.width * 0.15 + 80
    };
};

class Map extends React.PureComponent<MapProps, MapState> {

    connection: HubConnection | undefined;
    connecting: boolean = false;
    intervalId: NodeJS.Timer | undefined;

    mapContainerRef = React.createRef<HTMLDivElement>();
    map?: mapboxgl.Map = undefined;
    markersShippersDisplayed: Array<mapboxgl.LngLat> = [];
    markersTransportRequestsDisplayed: Array<mapboxgl.LngLat> = [];
    fHover?: mapboxgl.MapboxGeoJSONFeature[] | null;
    lng_default: number = 26.090817904089153;
    lat_default: number = 44.425960495078975;
    zoom_default: number = 16;

    constructor(props: Readonly<MapProps>) {

        const cookies = new Cookies();
        var map_style = cookies.get('map_style');

        super(props);

        this.state = {
            mapStyle: map_style ? map_style : 1,
            shippers: {},
            shippersDate: "",
            shippersLocation: {},
            shippersLocationDate: "",
            transportRequests: [],
            transportRequestsDate: "",
            allocations_group_by_user_id: {},
            allocations_group_by_user_id_summary: {},
        };

        this.handleChangeMapStyle = this.handleChangeMapStyle.bind(this);
        this.handleMapClick = this.handleMapClick.bind(this);
        this.handleMapMove = this.handleMapMove.bind(this);        
        this.handleFitAll = this.handleFitAll.bind(this);
    }

    connectSignalR = async () => {
        
        if (this.connection != null &&
            (this.connection.state == HubConnectionState.Connected || this.connection.state == HubConnectionState.Connecting))
        {
            console.log("SignalR connection already connected. Exit!");
            return;
        }

        if (this.connecting)
        {
            console.log("SignalR already connecting. Exit!");
            return;
        }

        this.connecting = true;
        
        console.log('connectSignalR');

        //https://docs.microsoft.com/en-us/aspnet/core/signalr/scale?view=aspnetcore-5.0
        //https://docs.microsoft.com/en-us/aspnet/core/signalr/configuration?view=aspnetcore-5.0&tabs=dotnet#configure-additional-options
        //skip negociation si folosim doar websokets ca sa evitam sticky sessions
        this.connection = new HubConnectionBuilder()
            .withUrl('/hubs/backend', {
                accessTokenFactory: async () => {
                    var token = await authService.getAccessToken();
                    return token || '';

                },
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    console.log(`reconnecting for ${retryContext.elapsedMilliseconds} milliseconds`);
                    return 5000 + Math.random() * 10000; //wait 5 seconds + random between 0 and 10 seconds
                    /*
                    if (retryContext.elapsedMilliseconds < 60000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 10000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }*/
                }
            })
            .build();

        this.connection.start()
            .then(result => {
                
                console.log('Connected!');
                if (this.connection) {
                    this.connection.onclose(() => {
                        console.log('signalr disconected!');
                        //console.log('signalr disconected! Reconnectinggg...');
                        //this.connectSignalR();
                    })
                    this.connection.onreconnecting(error => {
                        
                        console.log(`signalr reconnecting due to error: ${error}`);                    
                    });
                    this.connection.on('broadcastChatMessage', (name, receivedMessage) => {
                        const text = `${name}: ${receivedMessage}`;
                        store.dispatch({ type: 'CHAT_ADD_MESSAGE', message: text });
                    });
                    this.connection.on('Notification_Send', (responseFeedback, notification_json) => {
                        const notification = JSON.parse(notification_json) as SignalrNotification;

                        const Id = notification.Id;
                        const Type = notification.Type as number;
                        const Payload = notification.Payload as string;
                        const Epoch = Number(notification.Epoch); //returnam epoch in milisecundesecunde de la 1970;
                        let skip: boolean = false;

                        const epoch_on_client = Date.now();

                        const last_epoch_on_type = last_epochs.get(Type);
                        const last_epoch_before_processing = last_epoch;


                        if (Payload != null) {
                            
                            //pe aceste notificari (pt ca nu sunt incrementale) parsam doar ultima notificare
                            if (Type === 0 || Type === 100 || Type === 101 || Type === 102 || Type === 105){
                                //nu scoatem din tank daca avem tankate notificarei mai vechi
                                if (last_epoch_on_type === undefined || last_epoch_on_type < Epoch){
                                    const notifications_tank_new = notifications_tank.filter(x => x.Type !== Type);
                                    setNotificationsTank(notifications_tank_new);
                                }

                                //avem parsata o notificare mai noua - facem skip la cea curenta
                                //daca sunt mai multe notificari cu epoch egal le parsez pe toate in ordinea in care au venit
                                if (last_epoch_on_type && last_epoch_on_type > Epoch){
                                    skip = true;
                                }
                            }

                            if (!skip){
                                notifications_tank.push(notification)
                                if (epoch_on_client - last_epoch_before_processing > 1000){
                                    this.proccessNotificationHandler('notification_executed_immediately');
                                }
                                setLastEpoch(epoch_on_client); //nu merge direct sa fie asignata valoare (zice ca )                    
                                last_epochs.set(Type, Epoch)
                            }
                            else{
                                console.log('skip Type', Type, 'last epoch on type', last_epoch_on_type, 'epoch', Epoch, 'notification', notification)
                            }

                        }
                        
                        if (responseFeedback == 1) {
                            this.notificationReceived(Id.toString(), notification_json);
                        }
                    });
                }

            })
            .catch(e => {
                console.log('Connection failed: ', e);
                setTimeout(this.connectSignalR, 5000);
            });

        this.connecting = false;
    }

    disconnectSignalR = async () => {
        
        if (this.connection != null &&
            (this.connection.state == HubConnectionState.Connected || this.connection.state == HubConnectionState.Connecting))
        {
            console.log('stop signalr connection')
            this.connection.stop();
        }
    }


    notificationReceived = async (notificationId: string, transportRequest: string) => {
        if (this.connection) {
            if (this.connection.state == HubConnectionState.Connected) {
                try {
                    await this.connection.invoke('Notification_Received', notificationId, '' /*transportRequest*/);
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                console.log('No connection to server');
            }
        }
    };


    refreshTransportRequests = async () => {
        if (this.connection) {
            if (this.connection.state == HubConnectionState.Connected) {
                try {
                    await this.connection.invoke('refreshTransportRequests');
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                console.log('No connection to server');
            }
        }
    };

    recomputeRoute = async () => {
        if (this.connection) {
            if (this.connection.state == HubConnectionState.Connected) {
                try {
                    await this.connection.invoke('recomputeRoute');
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                console.log('No connection to server');
            }
        }
    };



    desynchronizationDetected = async (_notification_type: number,
                                            _description: string,
                                            _last_epoch: number,
                                            _current_epoch: number,
                                            _last_payload: string,
                                            _current_payload: string) => {
        if (this.connection) {
            if (this.connection.state == HubConnectionState.Connected) {
                try {
                    await this.connection.invoke('desynchronizationDetected', _notification_type, _description, _last_epoch, _current_epoch, _last_payload, _current_payload);
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                console.log('No connection to server');
            }
        }
    };

    refreshShippersList = async () => {
        if (this.connection) {
            if (this.connection.state == HubConnectionState.Connected) {
                try {
                    await this.connection.invoke('refreshShippersList');
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                console.log('No connection to server');
            }
        }
    };

    availableToWorkConfirmed = async (user_id: number, confirmed: boolean) => {
        if (this.connection) {
            if (this.connection.state == HubConnectionState.Connected) {
                try {
                    await this.connection.invoke('availableToWorkConfirmed', user_id, confirmed);
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                console.log('No connection to server');
            }
        }
    };

    handleChangeMapStyle(event: any) {
        
        const map_style = Number(event.target.value);
        
        this.setState({ mapStyle: map_style});

        const cookies = new Cookies();
        cookies.set('map_style', map_style, { path: '/' });

        this.initMap();
    }

    handleMapClick(event: any) {
        var coordinates = event.lngLat;
    }

    handleMapMove() {
        if (this.map) {

            var obj = {
                lng: this.map.getCenter().lng,
                lat: this.map.getCenter().lat,
                zoom: this.map.getZoom()
            };

            var obj_json = JSON.stringify(obj);
            const cookies = new Cookies();
            cookies.set('last_coordinates', obj_json, { path: '/' });

        }
    }

    boundingBox() {

        let allPoints = this.markersTransportRequestsDisplayed.concat(this.markersShippersDisplayed);

        if (!allPoints || !allPoints.length) {
            console.log('undefined');
            return undefined;
        }

        let w: number, s: number, e: number, n: number;
        let init = false;

        w = 0;
        s = 0;
        e = 0;
        n = 0;



        // Calculate the bounding box with a simple min, max of all latitudes and longitudes
        allPoints.forEach((point) => {

            if (!init) {
                n = s = point.lat;
                w = e = point.lng;
                init = true;
            }

            if (point.lat > n) {
                n = point.lat;
            } else if (point.lat < s) {
                s = point.lat;
            }
            if (point.lng > e) {
                e = point.lng;
            } else if (point.lng < w) {
                w = point.lng;
            }
        });
        return [
            [w, s],
            [e, n]
        ]
    }


    handleFitAll() {
        let bounds = this.boundingBox();

        if (bounds != null && this.map != null) {
            var v2 = new mapboxgl.LngLatBounds([bounds[0][0], bounds[0][1]], [bounds[1][0], bounds[1][1]])
            this.map.fitBounds(v2, { padding: { top: 50, bottom: 50, left: 50, right: 50 } });
        }
    }
    

    // Shows any markers currently in redux satate.
    showMarkers() {

        var transportRequestsFeatures: Array<Feature<Geometry>> = [];
        var shippersLocationFeatures: Array<Feature<Geometry>> = [];
        var waypointsFeatures: Array<Feature<Geometry>> = [];

        if (this.map) {

            this.markersShippersDisplayed = [];
            this.markersTransportRequestsDisplayed = [];


            //for (let i = 0; i < this.props.shippers.size; i++) {
            for (const [key, shipper] of Object.entries(this.state.shippers)) {            

                var shipper_location = this.state.shippersLocation[shipper.user_id];

                if (shipper.visible && utils.filterShippers(shipper, this.props.filterShippers, this.state.allocations_group_by_user_id_summary) && shipper_location) {

                    var color = '#000000'; //black
                    var status = '';
                    if (!shipper.available_to_work) {
                        color = '#FF0000'; //red
                        status = 'not available to work';
                    }
                    else if (!shipper.available_to_work_confirmed) {
                        color = '#FFA500'; //orange
                        status = 'available to work not confirmed';
                    }

                    var description = "User name: " + shipper.user_name + "<br/>Speed: " + utils.formatSpeed(shipper_location.speed);
                    if (status != '')
                        description += "<br/>Status: " + status;



                    var location = new mapboxgl.LngLat(shipper_location.longitude, shipper_location.latitude);

                    var point: Point = {
                        type: 'Point',
                        coordinates: [location.lng, location.lat]
                    };

                    var props: GeoJsonProperties = {
                        description: description,
                        icon: 'car_icon',
                        'color': color
                    }

                    var shipperFeature: Feature<Point> = {
                        type: 'Feature',
                        geometry: point,
                        properties: props,
                    };

                    shippersLocationFeatures.push(shipperFeature);

                    this.markersShippersDisplayed.push(location);
                }
            };

            for (let i = 0; i < this.state.transportRequests.length; i++) {
                if (this.state.transportRequests[i].visible && utils.filterTransportRequest(this.state.transportRequests[i], this.props.filterTransportRequests)) {
                    
                    const transport = this.state.transportRequests[i];
                    var sender = new mapboxgl.LngLat(this.state.transportRequests[i].sender_longitude, this.state.transportRequests[i].sender_latitude);
                    var receiver = new mapboxgl.LngLat(this.state.transportRequests[i].receiver_longitude, this.state.transportRequests[i].receiver_latitude);

                    /* sender */
                    var pointSender: Point = {
                        type: 'Point',
                        coordinates: [sender.lng, sender.lat]
                    };

                    const alloc_pickup = transport.allocations.find(x => x.type === 1);
                    const alloc_delivery = transport.allocations.find(x => x.type === 4);

                    const alloc_pickup_in_time_status = alloc_pickup? alloc_pickup.in_time_status_description : 'N/A';
                    const alloc_delivery_in_time_status = alloc_delivery? alloc_delivery.in_time_status_description : 'N/A';

                    var propsSender: GeoJsonProperties = {
                        description: `Transport id: ${transport.transport_id}<br/>`+
                                    `Priority: ${transport.priority}<br/>` +
                                    `Pickup preferred date: ${new Date(transport.details.pickup_preferred_date).toLocaleString()}<br/>`+
                                    `In time status: ${alloc_pickup_in_time_status}`,
                        icon: 'location_pickup_20px',
                        transport_id: this.state.transportRequests[i].transport_id,
                    }

                    var featureSender: Feature<Point> = {
                        type: 'Feature',
                        geometry: pointSender,
                        properties: propsSender,
                    };

                    transportRequestsFeatures.push(featureSender);

                    /* receiver */
                    var pointReceiver: Point = {
                        type: 'Point',
                        coordinates: [receiver.lng, receiver.lat]
                    };

                    var propsReceiver: GeoJsonProperties = {
                        description: `Transport id: ${transport.transport_id}<br/>`+
                                    `Priority: ${transport.priority}<br/>` +
                                    `Delivery preferred date: ${new Date(transport.details.delivery_preferred_date).toLocaleString()}<br/>`+
                                    `In time status: ${alloc_delivery_in_time_status}`,
                        icon: 'location_dropoff_20px',
                        transport_id: this.state.transportRequests[i].transport_id,
                    }

                    var featureReceiver: Feature<Point> = {
                        type: 'Feature',
                        geometry: pointReceiver,
                        properties: propsReceiver,
                    };

                    transportRequestsFeatures.push(featureReceiver);

                    this.markersTransportRequestsDisplayed.push(sender);
                    this.markersTransportRequestsDisplayed.push(receiver);

                }
            }

            /*
            let group = this.props.waypoints.reduce((r, a) => {
                r[a.user_id] = [...r[a.user_id] || [], a];
                return r;
            }, {});            
            */
            //let group = utils.groupArrayOfObjects(this.props.waypoints, "user_id");


            Object.keys(this.state.allocations_group_by_user_id).forEach((key) => {

                var coordinates = new Array<GeoJSON.Position>();
                var shipper_location = this.state.shippersLocation[Number(key)];
                //var shipper = this.props.shippers.find((element: { user_id: any; }) => element.user_id == key);
                var shipper = this.state.shippers[Number(key)];


                if (shipper_location != null && shipper != null && shipper.show_route) {
                    var last_latitude: number = shipper_location.latitude;
                    var last_longitude: number = shipper_location.longitude;
                    var color = '';

                    this.state.allocations_group_by_user_id[key].filter((x: MapStore.Allocation) => x.status == 2).forEach((object: MapStore.Allocation) => {

                        if (object.status == 3 || object.status == 4)
                            color = '#888'; //grey
                        else {
                            if (object.in_time_status == 1)
                                color = '#00FF00'; //green
                            else if (object.in_time_status == 2)
                                color = '#FF0000'; //red
                            else
                                color = '#FFA500'; //orange
                        }

                        coordinates = new Array<GeoJSON.Position>();
                        coordinates.push([last_longitude, last_latitude]);
                        coordinates.push([object.longitude, object.latitude]);

                        var line: LineString = {
                            type: 'LineString',
                            coordinates: coordinates
                        };

                        var waypointsLine: Feature<LineString> = {
                            type: 'Feature',
                            geometry: line,
                            properties: { 'color': color, 'title': object.navigation_order }
                        };

                        waypointsFeatures.push(waypointsLine);

                        last_longitude = object.longitude;
                        last_latitude = object.latitude;
                    });

                }
            });


            var dataRequests: FeatureCollection = {
                type: 'FeatureCollection',
                features: transportRequestsFeatures
            }

            var dataShippers: FeatureCollection = {
                type: 'FeatureCollection',
                features: shippersLocationFeatures
            }

            var dataWaypoints: FeatureCollection = {
                type: 'FeatureCollection',
                features: waypointsFeatures
            }

            if (this.map.getSource('transportRequests'))
                (this.map.getSource('transportRequests') as GeoJSONSource).setData(dataRequests);

            if (this.map.getSource('shippersLocation'))
                (this.map.getSource('shippersLocation') as GeoJSONSource).setData(dataShippers);

            if (this.map.getSource('waypoints'))
                (this.map.getSource('waypoints') as GeoJSONSource).setData(dataWaypoints);

        }
    }

    initMap()
    {

        var parentScope = this;
        var lng: number = this.lng_default;
        var lat: number = this.lat_default;
        var zoom: number = this.zoom_default;

        const cookies = new Cookies();
        var last_coordinates = cookies.get('last_coordinates');
        var map_style = cookies.get('map_style');

        var map_style_url = this.props.mapbox_styles.find(x => x.style_id == map_style);        


        //TODO pt cazul in care nu exista cookie-ul salvat: https://blog.logrocket.com/detect-location-and-local-timezone-of-users-in-javascript-3d9523c011b9/
        if (last_coordinates != null) {
            lng = last_coordinates.lng;
            lat = last_coordinates.lat;
            zoom = last_coordinates.zoom;
        }

        this.map?.remove();

        if (this.mapContainerRef && this.mapContainerRef.current) {
            this.map = new mapboxgl.Map({
                //accessToken: `${process.env.REACT_APP_MAPBOX_API_KEY}`,
                accessToken: this.props.mapbox_access_token,
                container: this.mapContainerRef.current,
                style: map_style_url == null ? this.props.mapbox_styles[0].url : map_style_url.url,
                center: [lng, lat],
                zoom: zoom
            });

            this.map.loadImage(
                '/icons/car_icon_40px.png',
                function (error: any, image: any) {
                    if (error) throw error;
                    if (parentScope && parentScope.map) {
                        parentScope.map.addImage('car_icon', image, {
                            "sdf": true
                        });
                    }
                });
            this.map.loadImage(
                '/icons/location_pickup_20px.png',
                function (error: any, image: any) {
                    if (error) throw error;
                    if (parentScope && parentScope.map) {
                        parentScope.map.addImage('location_pickup_20px', image);
                    }
                });

            this.map.loadImage(
                '/icons/location_dropoff_20px.png',
                function (error: any, image: any) {
                    if (error) throw error;
                    if (parentScope && parentScope.map) {
                        parentScope.map.addImage('location_dropoff_20px', image);
                    }
                });

            this.map.loadImage(
                '/icons/circle_40px_blue.png',
                function (error: any, image: any) {
                    if (error) throw error;
                    if (parentScope && parentScope.map) {
                        parentScope.map.addImage('circle_40px_blue', image);
                    }
                });

            // Add navigation control (the +/- zoom buttons)
            this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');
            this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');


            this.map.on('move', () => {
                this.handleMapMove();
            });

            this.map.on('load', () => {
                if (parentScope && parentScope.map) {

                    parentScope.map.addSource('waypoints', {
                        'type': 'geojson',
                        'data': { "type": "FeatureCollection", "features": [] }

                    });
                    parentScope.map.addLayer({
                        'id': 'waypoints',
                        'type': 'line',
                        'source': 'waypoints',
                        'layout': {
                            'line-join': 'round',
                            'line-cap': 'round',
                        },
                        'paint': {
                            "line-color": ['get', 'color'],
                            'line-width': 8
                        }
                    });

                    parentScope.map.addLayer({
                        "id": "symbols",
                        "type": "symbol",
                        "source": "waypoints",
                        "layout": {
                            "symbol-placement": "line",
                            "text-font": ["Open Sans Regular"],
                            "text-ignore-placement": true,
                            "text-allow-overlap": true,
                            "text-field": '{title}',
                            "text-size": 16
                        }
                    });

                    parentScope.map.addSource('transportRequests', {
                        'type': 'geojson',
                        'generateId': true,
                        'data': { "type": "FeatureCollection", "features": [] }

                    });

                    parentScope.map.addLayer({
                        'id': 'transportRequests',
                        'type': 'symbol',
                        'source': 'transportRequests',
                        'layout': {
                            'icon-image': '{icon}',
                            'icon-size': 1,
                            'icon-allow-overlap': true,
                            'text-allow-overlap': true,
                            /*'text-field': ['get', 'description'],*/
                            'text-variable-anchor': ['bottom'],
                            'text-radial-offset': 1.5,
                            'text-justify': 'right'
                        },
                        /*
                        'paint': {
                            'icon-opacity': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0.3
                            ]
                        }*/
                    });

                    parentScope.map.addLayer({
                        'id': 'transportRequestsCircle',
                        'type': 'symbol',
                        'source': 'transportRequests',
                        'layout': {
                            'icon-image': 'circle_40px_blue',
                            'icon-size': 1,
                            'icon-allow-overlap': true
                        },
                        'paint': {
                            'icon-opacity': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0
                            ]
                        }
                    });


                    parentScope.map.addSource('shippersLocation', {
                        'type': 'geojson',
                        'data': { "type": "FeatureCollection", "features": [] }

                    });
                    parentScope.map.addLayer({
                        'id': 'shippersLocation',
                        'type': 'symbol',
                        'source': 'shippersLocation',
                        'layout': {
                            'icon-image': '{icon}',
                            'icon-size': 1,
                            'icon-allow-overlap': true
                        },
                        "paint": {
                            "icon-color": ['get', 'color'],
                        }
                    });
                
                }
            });

            this.map.on('click', 'transportRequests', function (e) {
                if (e && e.features && parentScope.map) {
                    var feature = e.features[0];
                    var geometry = feature?.geometry;

                    if (geometry.type == 'Point') {

                        var coordinates = geometry.coordinates?.slice();
                        var description = feature?.properties?.description;

                        // Ensure that if the map is zoomed out such that multiple
                        // copies of the feature are visible, the popup appears
                        // over the copy being pointed to.
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        new mapboxgl.Popup()
                            .setLngLat([coordinates[0], coordinates[1]])
                            .setHTML(description)
                            .addTo(parentScope.map);
                    }
                }

            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            this.map.on('mouseenter', 'transportRequests', function (e) {
                if (e && e.features && e.features) {
                    parentScope.mouseover(e.features);
                } else {
                    parentScope.mouseout();
                }
            });

            // Change it back to a pointer when it leaves.
            this.map.on('mouseleave', 'transportRequests', function (e) {
                parentScope.mouseout();
            });

            this.map.on('click', 'shippersLocation', function (e) {
                if (e && e.features && parentScope.map) {
                    var feature = e.features[0];
                    var geometry = feature?.geometry;

                    if (geometry.type == 'Point') {

                        var coordinates = geometry.coordinates?.slice();
                        var description = feature?.properties?.description;


                        // Ensure that if the map is zoomed out such that multiple
                        // copies of the feature are visible, the popup appears
                        // over the copy being pointed to.
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        new mapboxgl.Popup()
                            .setLngLat([coordinates[0], coordinates[1]])
                            .setHTML(description)
                            .addTo(parentScope.map);
                    }
                }

            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            this.map.on('mouseenter', 'shippersLocation', function (e) {
                if (parentScope.map)
                    parentScope.map.getCanvas().style.cursor = 'pointer';
            });

            // Change it back to a pointer when it leaves.
            this.map.on('mouseleave', 'shippersLocation', function (e) {
                if (parentScope.map)
                    parentScope.map.getCanvas().style.cursor = '';
            });

            // The sourcedata event is an example of MapDataEvent.
            // Set up an event listener on the map.
            this.map.on('sourcedata', function (e) {
                if (e.isSourceLoaded) {
                    //de pus coliision check, vezi linkul: https://github.com/mourner/rbush/

                    //console.log('source loaded');
                    //console.log(e);

                    if (parentScope.map) {
                        var relatedFeatures = parentScope.map.querySourceFeatures('transportRequests', {
                            sourceLayer: 'transportRequests'

                        });

                        relatedFeatures.forEach(item => {
                            /*
                            this.map?.setFeatureState({
                                source: 'transportRequests',
                                id: item.id
                            }, {
                                hover: true
                            });*/

                            //console.log(item);
                        });
                    }
                }
            });

        }
    }

    public mouseover(feature: mapboxgl.MapboxGeoJSONFeature[]) {
        this.fHover = feature;

        if (this.map) {
            this.map.getCanvasContainer().style.cursor = 'pointer';

            feature.forEach(feaureItem => {
                if (this.map != null) {
                    var relatedFeatures = this.map.querySourceFeatures('transportRequests', {
                        sourceLayer: 'transportRequests',
                        filter: ['==', 'transport_id', feaureItem.properties?.transport_id]
                    });

                    relatedFeatures.forEach(item => {
                        this.map?.setFeatureState({
                            source: 'transportRequests',
                            id: item.id || 0
                        }, {
                            hover: true
                        });
                    });
                }
            })

        }
    }

    public mouseout() {
        if (this.fHover != null && this.map) {

            this.map.getCanvasContainer().style.cursor = 'default';

            var relatedFeatures = this.map.querySourceFeatures('transportRequests', {
                sourceLayer: 'transportRequests',
                //filter: ['==', 'transport_id', this.fHover.properties?.transport_id]
            });


            relatedFeatures.forEach(item => {
                this.map?.setFeatureState({
                    source: 'transportRequests',
                    id: item.id || 0
                }, {
                    hover: false
                });
            });
            this.fHover = null;

        }

    }

    setShipperProp = (user_id: number, prop: string, visible: boolean) => {
        
        //let newShippers = new Map(appState.map.shippers);
        //let newShippers = { ...appState.map.shippers };
        //let newShippers = Object.assign({}, appState.map.shippers);

        
        const shipper = { ... this.state.shippers[user_id]};
        if (shipper != null) {
            if (prop == "SHOW_ROUTE")
                shipper.show_route = visible;
            else if (prop == "VISIBLE")
                shipper.visible = visible;
        }

        let newShippers: MyMap<number, Shipper>;

        newShippers = { ...this.state.shippers, [shipper.user_id]: shipper}

        this.setState({ ...this.state, shippers: newShippers});
    }

    
    setVisibleTranportRequest = (transport_id: string, visible: boolean) => {        
        
        const elementsIndex = this.state.transportRequests.findIndex(element => element.transport_id == transport_id)

        if (elementsIndex != null && elementsIndex >= 0) {
            let newTransportRequests = [ ...this.state.transportRequests];
            newTransportRequests[elementsIndex] = { ...newTransportRequests[elementsIndex], visible: visible };

            this.setState({ ...this.state, transportRequests: newTransportRequests});
        }
    }
    

    proccessNotificationHandler = (event: string) => {
        var notif_exists: boolean = false;
        var shippers: MyMap<number, Shipper> | null = null;
        var last_date_shippers_date: string | null = null;
        var shippers_updated: boolean = false;
        var locations : MyMap<number, ShipperLocation> | null = null;    
        var last_date_location_date: string | null = null;
        var locations_updated: boolean = false;
        var trasportRequests: Array<TransportRequest> | null = null;
        var allocations_group_by_user_id: MyMap<string, Array<Allocation>> | null = null;
        var allocations_group_by_user_id_summary: MyMap<string, UserAllocationSummary> | null = null;
        var last_date_transport_date: string | null = null;
        var transport_requests_updated: boolean = false;

        [ notif_exists, shippers, last_date_shippers_date, shippers_updated, locations, last_date_location_date, locations_updated, trasportRequests, allocations_group_by_user_id, allocations_group_by_user_id_summary, last_date_transport_date, transport_requests_updated ] = proccessNotification(event, this.desynchronizationDetected, this.state.shippers, this.state.transportRequests, this.state.allocations_group_by_user_id);
        
        if (notif_exists){
            this.setState({
                mapStyle: this.state.mapStyle,
                shippers: shippers !== null && shippers_updated ? shippers : this.state.shippers,
                shippersDate: last_date_shippers_date !== null && shippers_updated ? last_date_shippers_date : this.state.shippersDate,
                shippersLocation: locations !== null && locations_updated ? locations : this.state.shippersLocation,
                shippersLocationDate: last_date_location_date !== null && locations_updated? last_date_location_date : this.state.shippersLocationDate,
                transportRequests: trasportRequests !== null && transport_requests_updated ? trasportRequests : this.state.transportRequests,
                transportRequestsDate: last_date_transport_date !== null && transport_requests_updated? last_date_transport_date : this.state.transportRequestsDate,
                allocations_group_by_user_id: allocations_group_by_user_id !== null && transport_requests_updated? allocations_group_by_user_id : this.state.allocations_group_by_user_id,
                allocations_group_by_user_id_summary: allocations_group_by_user_id_summary !== null && transport_requests_updated ? allocations_group_by_user_id_summary : this.state.allocations_group_by_user_id_summary,

            })
        }
    }
    
    componentDidMount = async () => {
        this.props.loadStaticData();
        this.connectSignalR();
        this.intervalId = setInterval(() => {
            const epoch_on_client = Date.now();
            if (epoch_on_client - last_proccessing > 1000)
            {
                this.proccessNotificationHandler('job')
            }
        }, 1000);

    };

    public componentDidUpdate(prevProps: MapProps, prevState: MapState) {

        if (this.props.triggerLoadState)
        {
            this.props.resetTriggerLoadState();
            this.initMap();
        }

        this.showMarkers();

        //daca fac click pe maximize harta nu se mareste (ramane la fel cum era inainte de maximize); ca sa se maximizeze trebuie apelat "this.map?.resize();"
        if (prevProps.windowDimensions.height != this.props.windowDimensions.height ||
            prevProps.windowDimensions.width != this.props.windowDimensions.width){
            this.map?.resize();
        }
    }
    
    componentWillUnmount = async () => {        
        this.disconnectSignalR();
        if (this.intervalId)
            clearInterval(this.intervalId);
    };
    
    render() {
        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={2} style={{ position: "relative" }}>
                        <MapTransportRequests
                            handleFitAll = {this.handleFitAll}
                            refreshTransportRequests = {this.refreshTransportRequests}
                            recomputeRoute = {this.recomputeRoute}
                            setVisibleTranportRequest = {this.setVisibleTranportRequest}
                            transportRequests={this.state.transportRequests}
                            transportRequestsDate={this.state.transportRequestsDate}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <div style={divStyleMap(this.props.windowDimensions)} ref={this.mapContainerRef}>
                            
                        </div>
                        <div style={divStyleMapSelectStyle(this.props.windowDimensions)}>
                            <div className="map-overlay top">
                                <div className="map-overlay-inner">
                                    <select id="layer" name="layer" value={this.state.mapStyle} onChange={this.handleChangeMapStyle}>
                                        {this.props.mapbox_styles.map((style, index) => (
                                                <option key={style.style_id} value={style.style_id} >
                                                    {style.name}
                                                </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                    </Grid>
                    <Grid item xs={2} style={{ position: "relative" }}>
                        <MapShippers
                            refreshShippersList={this.refreshShippersList}
                            availableToWorkConfirmed={this.availableToWorkConfirmed}
                            shippers={this.state.shippers}
                            shippersDate={this.state.shippersDate}
                            setShipperProp={this.setShipperProp}
                            shippersLocation={this.state.shippersLocation}
                            shippersLocationDate={this.state.shippersLocationDate}
                            allocations_group_by_user_id={this.state.allocations_group_by_user_id}
                            allocations_group_by_user_id_summary={this.state.allocations_group_by_user_id_summary}

                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.map, // Selects which state properties are merged into the component's props
    MapStore.actionCreators // Selects which action creators are merged into the component's props
)(withWindowDimensions(Map));
