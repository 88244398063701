



export function displayNavigationText(navigationText: string, page: number): string {
    var ret = '';

    if (navigationText === 'Current') {
        ret = '(' + Number(page + 1) + ')';
    }
    else {
        if (page >= 0)
            ret = navigationText + '(' + Number(page + 1) + ')';
        else
            ret = navigationText;
    }
    return ret;
}


export function isNavigationDisabled(page: number): boolean {
    var ret = true;

    if (page >= 0)
        ret = false;
    
    return ret;
}