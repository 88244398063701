import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class LoginMenuAlternative extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        //this.populateState();
    }

    componentDidUpdate() {
        
    }

    componentWillUnmount() {
        
    }

    async populateState() {

    }

    render() {

        const user = this.props.user;
        var userName = '';
        var isAuthenticated = false;

        if (user != null) {
            isAuthenticated = true;
            userName = user.UserName;
        }        

        if (!isAuthenticated) {
            return this.anonymousView('/register', '/login');
        } else {
            return this.authenticatedView(userName, '/profile', '/login');
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavItem className="navItem">
                <NavLink tag={Link} className="navLink" to={profilePath}>
                    Hello2 {userName}
                </NavLink>
            </NavItem>
            <NavItem className="navItem">
                <NavLink tag={Link} className="navLink" to={logoutPath}>Logout2</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem className="navItem">
                <NavLink tag={Link} className="navLink" to={registerPath}>Register2</NavLink>
            </NavItem>
            <NavItem className="navItem">
                <NavLink tag={Link} className="navLink" to={loginPath}>Login2</NavLink>
            </NavItem>
        </Fragment>);
    }
}


function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
}

const connectedLoginMenuAlternative = connect(mapState, actionCreators)(LoginMenuAlternative);
export { connectedLoginMenuAlternative as LoginMenuAlternative };
