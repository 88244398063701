import * as React from 'react';
import {
    IconButton,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,    
    Paper as MuiPaper,
    Radio,
    Checkbox,
    Typography
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Close as CloseIcon } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { styled } from '@mui/system';


export const buttonsBox = {
    marginTop: "2px",
    '& > *': { margin: "8px!important" as any },
};

export const paperStyle = {
    marginBottom: '200px',
    padding: '5px',
}


export const Dialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


export const DialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <MuiDialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};


export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({

}));


export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({

}));

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: '15px',
        border: '1px solid #dadde9',
    },
}));

export const Paper = styled(MuiPaper)(({ theme }) => ({    
    marginBottom: '20px',
    padding: '5px'
}));

/*
export const Paper2 = styled(MuiPaper)(({ theme }) => ({
    marginBottom: '20px',
    padding: '5px'
}));
*/


export const GreenRadio = styled(Radio)`
  color: ${green[400]};
  &.Mui-checked {
    color: ${green[600]};
  }
`;

export const GreenCheckbox = styled(Checkbox)`
  color: ${green[400]};
  &.Mui-checked {
    color: ${green[600]};
  }
`;

