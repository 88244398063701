import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_delete, http_axios_get, http_axios_post } from './ApiInterface';
import * as Models from "../models/Models";
import * as utils from './Utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DocumentsState {
    navigateMore?: any;
    searchData: SearchData;
    searchResult: SearchResult;    
    pageSizeList: Models.ListValue[];    
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingSearchBox: boolean;
    isLoadedSearchBox: boolean;
    triggerSearch: boolean;
    triggerRefresh: boolean;
}

export class SearchData extends Models.SearchPaginationInput {    
    start_date: string = "";
    end_date: string = "";
    time_zone_offset: string = "";
    description: string = "";
}

export class Document {
    document_id: number = 0;
    reference: string = "";
    type: string = "";
    description: string = "";
    ins_date: string = "";
    ins_user: string = "";
    
}

export class SearchResult extends Models.SearchPaginationResult {
    Data: Document[] = [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestDataAction {
    type: 'DOCUMENTS_REQUEST_DATA';
    searchData: SearchData;
}
export interface ReceiveDataAction {
    type: 'DOCUMENTS_RECEIVE_DATA';
    searchResult: SearchResult;
}

export interface RequestFiltersAction {
    type: 'DOCUMENTS_FILTERS';
}

export interface ReceiveFiltersAction {
    type: 'DOCUMENTS_RECEIVE_FILTERS';    
    pageSizeList: Models.ListValue[];
}

export interface ResetTriggerSearch {
    type: 'DOCUMENTS_RESET_TRIGGER_SEARCH';
}

export interface SetTriggerRefresh {
    type: 'DOCUMENTS_SET_TRIGGER_REFRESH';
}

export interface ResetTriggerRefresh {    
    type: 'DOCUMENTS_RESET_TRIGGER_REFRESH';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestDataAction |
    ReceiveDataAction |    
    RequestFiltersAction |
    ReceiveFiltersAction |
    ResetTriggerSearch |
    SetTriggerRefresh |
    ResetTriggerRefresh;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    ...ApiInterface.actionCreators,

    requestFilters: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all

            Promise.all([
                http_axios_get('api/Lists/3')
                    .then(pageSize => {
                        return pageSize;
                    }),
            ]).then(([pageSize]) => {
                const pageSizeList = pageSize as Models.ListValue[];
                dispatch({
                    type: 'DOCUMENTS_RECEIVE_FILTERS',                    
                    pageSizeList: pageSizeList,
                });
            })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'DOCUMENTS_FILTERS' });
        }

    },
    requestData: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var payload = JSON.stringify(data);


            http_axios_post('api/Documents', payload)
                .then(response => {
                    return response as Promise<SearchResult>;
                })
                .then(data => {
                    dispatch({ type: 'DOCUMENTS_RECEIVE_DATA', searchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            var searchData = Object.assign(new SearchData, data);
            dispatch({ type: 'DOCUMENTS_REQUEST_DATA', searchData });
        }
    },
    refreshData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var searchData: SearchData;
            
            searchData = Object.assign({}, appState.documents?.searchData);
            
            var payload = JSON.stringify(searchData);

            http_axios_post('api/Documents', payload)
                .then(response => {
                    return response as Promise<SearchResult>;
                })
                .then(data => {
                    dispatch({ type: 'DOCUMENTS_RECEIVE_DATA', searchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            var searchData = Object.assign(new SearchData, appState.documents?.searchData);
            dispatch({ type: 'DOCUMENTS_REQUEST_DATA', searchData });
        }
    },
    resetTriggerTripsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'DOCUMENTS_RESET_TRIGGER_SEARCH' });
        }
    },
    resetTriggerRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'DOCUMENTS_RESET_TRIGGER_REFRESH' });
        }
    },
    setTriggerRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'DOCUMENTS_SET_TRIGGER_REFRESH' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DocumentsState = {
    searchData: {
        ...utils.getSearchPaginationInputDefaultValues(),
        start_date: utils.getDateISO(-1),
        end_date: utils.getDateISO(),
        time_zone_offset: utils.getTimezoneOffset().toString(),
        description: "",
    },
    searchResult: {
        ...utils.getSearchPaginationResultDefaultValues(),
        Data: []
    },    
    pageSizeList: [],
    isLoading: false,
    isLoaded: false,
    isLoadingSearchBox: false,
    isLoadedSearchBox: false,
    triggerSearch: false,
    triggerRefresh: false,

};

export const reducer: Reducer<DocumentsState> = (state: DocumentsState | undefined, incomingAction: Action): DocumentsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'DOCUMENTS_REQUEST_DATA':
            return {
                searchData: action.searchData,
                searchResult: state.searchResult,                
                pageSizeList: state.pageSizeList,
                isLoading: true,
                isLoaded: false,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh
            };
        case 'DOCUMENTS_RECEIVE_DATA':
            return {
                searchData: state.searchData,
                searchResult: action.searchResult,
                pageSizeList: state.pageSizeList,
                isLoading: false,
                isLoaded: true,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'DOCUMENTS_FILTERS':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: true,
                isLoadedSearchBox: false,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'DOCUMENTS_RECEIVE_FILTERS':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                pageSizeList: action.pageSizeList,
                isLoadingSearchBox: false,
                isLoadedSearchBox: true,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: true,
                triggerRefresh: state.triggerRefresh,
            };
        case 'DOCUMENTS_RESET_TRIGGER_SEARCH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,                
                pageSizeList: state.pageSizeList,                
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: false,
                triggerRefresh: state.triggerRefresh,
            };
        case 'DOCUMENTS_SET_TRIGGER_REFRESH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: true,
            };
        case 'DOCUMENTS_RESET_TRIGGER_REFRESH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: false,
            };

        default:
            return state;
    }
};
