import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as FetchTripsStore from '../store/FetchTrips';
import { FetchTripsTable } from './FetchTripsTable';
import { Box, Select, Grid, TextField, Button, MenuItem, InputLabel, } from '@mui/material';
import { Component } from 'react';
import * as Validator from "../validator/Validator";
import * as utils from '../store/Utils';
import { withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...
type FetchTripsProps =
    utils.IClasses
    & FetchTripsStore.FetchTripsState // ... state we've requested from the Redux store
    & typeof FetchTripsStore.actionCreators; // ... plus action creators we've requested
    


export class FetchTrips extends React.PureComponent<FetchTripsProps, FetchTripsStore.SearchData> {
    componentRef: Component<any, {}, any> | Element | null = null;

    constructor(props: Readonly<FetchTripsProps>) {
        super(props);
        
        this.state = {
            PageSize: props.searchData.PageSize,
            PageNumber: props.searchData.PageNumber,
            AfterId: props.searchData.AfterId,
            Destination: props.searchData.Destination,
            StartDate: props.searchData.StartDate,
            EndDate: props.searchData.EndDate,
            Comment: props.searchData.Comment,
            UserName: props.searchData.UserName
        };

        // This binding is necessary to make "this" work in the callback
        this.handleSearch = this.handleSearch.bind(this);
        this.handleNewTrip = this.handleNewTrip.bind(this);
        
        this.handleNavigateMore = this.handleNavigateMore.bind(this);

        this.handleChangeDestination = this.handleChangeDestination.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleChangeComment = this.handleChangeComment.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);

    }


    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeDestination(event: any) {
        this.setState({ Destination: event.target.value });
    }
    handleChangeStartDate(event: any) {
        this.setState({ StartDate: event.target.value });
    }
    handleChangeEndDate(event: any) {
        this.setState({ EndDate: event.target.value });
    }
    handleChangeComment(event: any) {
        this.setState({ Comment: event.target.value });
    }
    handleChangeUserName(event: any) {
        this.setState({ UserName: event.target.value });
    }
    handleChangePageSize(event: any) {
        this.setState({ PageSize: event.target.value });
    }

    private async handleSearch(event: any) {
        event.preventDefault();
        if (this.handleValidation()) {
            await this.search(false, 0);
        }
    }

    private async handleNavigateMore() {
        if (this.handleValidation()) {
            this.search(true, this.props.tripSearchResult.AfterIdNext);
        }
    }

    private async handleNewTrip(event: any) {
        this.props.navigate("/trip/edit/0");
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var Destination = this.state.Destination;
        var StartDate = this.state.StartDate;
        var EndDate = this.state.EndDate;
        var Comment = this.state.Comment;

        if (Destination != "" && Destination.length > 0 && !Validator.isAlphaNumericAndSpace(Destination)) {
            err += "Destination should be alpha numeric!\n";
            result = false;
        }
        if (Destination != null && Destination.length > 50) {
            err += "Destination should not exceed 50 characters!\n";
            result = false;
        }

        if (Comment != null && Comment.length > 0 && !Validator.isText(Comment)) {
            err += "Illegal character in Comment field!\n";
            result = false;
        }
        if (Comment != null && Comment.length > 200) {
            err += "Comment should not exceed 200 characters!\n";
            result = false;
        }

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
        }
        return result;
    }

    private async search(appendToState: boolean, AfterId: number) {
        await this.setState({ AfterId: AfterId });
        this.props.requestTripData(this.state, appendToState);
    }
    
    // This method is called when the component is first added to the document
    public async componentDidMount() {
        if (!this.props.isLoadedSearchBox)
            this.props.requestTripSearchBoxData();

    }

    // This method is called when the route parameters change
    public async componentDidUpdate(prevProps: Readonly<FetchTripsProps>, prevState: any, snapshot: any) {
        if (this.props.triggerSearch) {
            {
                this.props.resetTriggerTripsSearch();
                await this.search(false, 0);
            }
        }

        //in case of delete
        if (this.props.triggerRefresh) {
            {
                this.props.resetTriggerTripsRefresh();
                await this.props.refreshData();
            }
        }
    }

    public render() {
        return (
            <React.Fragment>
                <h1 id="tabelLabel">Trips</h1>
                {this.renderSearchBox()}
                {this.renderTripsTable()}
            </React.Fragment>
        );
    }

    private renderSearchBox() {
        
        return (
            this.props.isLoadingSearchBox ?
                <p><em>Loading search parameters...</em></p> :
                <div>
                    <fieldset>
                        <legend>Search criteria</legend>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    id="Destination"
                                    name="Destination"
                                    label="Destination"
                                    value={this.state.Destination}
                                    onChange={this.handleChangeDestination}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="Comment"
                                    name="Comment"
                                    label="Comment"
                                    value={this.state.Comment}
                                    onChange={this.handleChangeComment}
                                />
                            </Grid>                        
                            <Grid item xs={6}>
                                <TextField
                                    id="StartDate"
                                    name="StartDate"
                                    label="Start Date"
                                    type="date"
                                    value={this.state.StartDate}
                                    onChange={this.handleChangeStartDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="EndDate"
                                    name="EndDate"
                                    label="End Date"
                                    type="date"
                                    value={this.state.EndDate}
                                    onChange={this.handleChangeEndDate}
                                />
                            </Grid>             
                            <Grid item xs={6}>
                                <InputLabel id="label-username">User Name</InputLabel>
                                <Select
                                    labelId="label-username"
                                    id="UserName"
                                    name="UserName"
                                    value={this.state.UserName}
                                    onChange={this.handleChangeUserName}
                                >
                                    {this.props.userList.map(user =>
                                        <MenuItem key={user.UserName} value={user.UserName}>{user.DisplayName}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel id="label-pagesize">Page size</InputLabel>
                                <Select
                                    labelId="label-pagesize"
                                    id="PageSize"
                                    name="PageSize"
                                    value={this.state.PageSize}
                                    onChange={this.handleChangePageSize}
                                >
                                    {this.props.pageSizeList.map(pageSize =>
                                        <MenuItem key={pageSize.Value} value={pageSize.Value}>{pageSize.Description}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        
                            <Grid item xs={12}>
                                <Box sx={styled.buttonsBox}>
                                    <Button variant="contained" color="primary" onClick={this.handleSearch}>
                                        Search
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={this.handleNewTrip} >
                                        Create Trip
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </fieldset>
                </div>
        );
    }

    private renderTripsTable() {
        return (
            <div>
                <fieldset>
                    <legend>Search results</legend>
                    <FetchTripsTable
                        ref={el => (this.componentRef = el)}
                        {...this.props}
                        navigateMore={this.handleNavigateMore}
                    />
                </fieldset>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.fetchTrips, // Selects which state properties are merged into the component's props
    FetchTripsStore.actionCreators // Selects which action creators are merged into the component's props
)(withNavigation(FetchTrips));
