import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { accessToken } from '../_users/_helpers';
import { store } from './configureStore'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import queryString from 'query-string';
import { navigateToUrl } from '../components/Navigation'


async function handleError(err : any) {

    var errMsg = ''
    if (err.response && err.response.status == 401) {
        errMsg = 'You are not authorized';
        store.dispatch({ type: 'SET_ERROR', error: errMsg });
        throw errMsg;
    }
    else if (err.response && err.response.status == 403) {
        errMsg = 'Access forbidden';
        store.dispatch({ type: 'SET_ERROR', error: errMsg });
        throw errMsg;
    }
    else {

        const apiResponse = await err.response.data;

        if (apiResponse.error != undefined) {
            store.dispatch({ type: 'SET_ERROR', error: apiResponse.error });
            throw apiResponse.error;
        }
        else {
            errMsg = 'Error occured processing API request';
            store.dispatch({ type: 'SET_ERROR', error: errMsg });
            throw 'Error occured processing API request';
        }
    }
}

async function parseResponse(response: AxiosResponse): Promise<any> {

    if (response.status == 200 /*OK*/ || response.status == 201 /*created*/) {
        const apiResponse = await response.data;

        if (apiResponse.error != undefined) {
            store.dispatch({ type: 'SET_ERROR', error: apiResponse.error });
            throw apiResponse.error;
        }
        
        if (apiResponse.info != undefined) {
            store.dispatch({ type: 'SET_INFO', info: apiResponse.info });
        }        
        if (response.headers['location'] != null) {            
            navigateToUrl(response.headers['location']);
        }
        
        return JSON.parse(apiResponse.payload);
    }
    else {
        throw new Error("Unknow status code");
    }
}

export async function http_axios_get(request: RequestInfo, params? :any): Promise<any> {
    var token = null;
    var response: AxiosResponse;
    var qstr = '';

    if (!areWeTestingWithJest()) {
        token = await accessToken();
    }

    if (params != null) {
        qstr = '?' + queryString.stringify(params);  
    }
    
    try {
        response = await axios.get(request.toString() + qstr,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
        return parseResponse(response);
    }
    catch (err) {
        handleError(err);
    }
}

export async function http_axios_post(request: RequestInfo, data?: string): Promise<any> {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        token = await accessToken();
    }
    try {        
        
        response = await axios.post(request.toString(), data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }

            });        
        return parseResponse(response);
    }
    catch (err) {
        handleError(err);
    }
}


export async function http_axios_put(request: RequestInfo, data?: string): Promise<any> {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        token = await accessToken();
    }
    try {        
        response = await axios.put(request.toString(), data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }

            });
        return parseResponse(response);
    }
    catch (err) {
        handleError(err);
    }
}

export async function http_axios_delete(request: RequestInfo): Promise<any> {
    var token = null;
    var response: AxiosResponse;    

    if (!areWeTestingWithJest()) {
        token = await accessToken();
    }
    try {
        response = await axios.delete(request.toString(),
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
        return parseResponse(response);
    }
    catch (err) {
        handleError(err);
    }
}



function areWeTestingWithJest() {
    return process.env.JEST_WORKER_ID !== undefined;
}

