import moment from 'moment';

export function isNumeric(input: string): boolean {
    if (!input.match(/^[0-9]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumericAndUnderscore(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9_]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumeric(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9]+$/)) {
        return false;
    }
    return true;
}


export function isAlphaNumericAndSpace(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9\s]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumericAndSpaceUnderscore(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9\s_]+$/)) {
        return false;
    }
    return true;
}

export function isLetters(input: string): boolean {
    if (!input.match(/^[a-zA-Z]+$/)) {
        return false;
    }
    return true;
}

export function isText(input: string): boolean {
    if (!input.match(/^[-@.,\/#&+\w\s]*$/)) {
        return false;
    }
    return true;
}

export function isEmail(input: string): boolean {
    if (!input.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        return false;
    }
    return true;
}

/*
export function isDateISO(input: string): boolean {
    var date: Date;
    var result: boolean;
    try {
        date = new Date(input);
        result = true;
    }
    catch (err)
    {
        result = false;        
    }
    return result;
}
*/

export function isDateISO(date: string): boolean {
    return moment(date, 'YYYY-MM-DD', true).isValid();
}
