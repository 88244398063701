import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as MapStore from '../store/Map';
import { withWindowDimensions } from '../hocs'
import {
    FormControlLabel,
    Checkbox,
    Grid,
    MenuItem,
    Input,
    Select,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';

import {    
    FindInPageOutlined as FindInPageOutlinedIcon,
    Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Allocation, MyMap, Shipper, ShipperLocation, UserAllocationSummary } from '../store/Map'
import * as utils from '../store/Utils';

import { Dialog, DialogTitle, DialogContent, DialogActions, Paper, } from './StyledComponents'
import ShipperItem from './MapShipperItem';
import { availableToWorkConfirmed, refreshShippersList } from './Map';

interface ShippersProps {
    refreshShippersList: refreshShippersList;
    availableToWorkConfirmed: availableToWorkConfirmed;
    setShipperProp: (user_id: number, prop: string, visible: boolean) => void;
    shippers: MyMap<number, Shipper>;
    shippersDate: string;
    shippersLocation: MyMap<number, ShipperLocation>;
    shippersLocationDate: string;
    allocations_group_by_user_id: MyMap<string, Array<Allocation>>;
    allocations_group_by_user_id_summary: MyMap<string, UserAllocationSummary>;
}

// At runtime, Redux will merge together...
type MapShippersProps =
    utils.IClasses
    & ShippersProps
    & MapStore.MapState // ... state we've requested from the Redux store    
    & typeof MapStore.actionCreators; // ... plus action creators we've requested
    

interface MapShippersState {    
    showViewStopsModal: boolean;
    showFilterShippersModal: boolean;
    filterShippers: MapStore.FilterShippers;    
    viewStopsUserId: number;
}

const divStyleToast: React.CSSProperties = {
    position: 'absolute',
    top: '70px',
    bottom: '0px',
    overflowY: 'scroll',
    width: '100%',
    padding: '10px',
};

const divFixed: React.CSSProperties = {
    position: 'sticky',
    padding: '10px',
};


const divStyleBadge: React.CSSProperties = {
    display: 'inline-block',
    padding: '0.25em 0.4em',
    fontSize: '75%',
    fontWeight: 'bolder',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem'
    //transition: 'color 0.15s ease -in -out, background - color 0.15s ease -in -out, border - color 0.15s ease -in -out, box - shadow 0.15s ease -in -out',
};


class MapShippers extends React.PureComponent<MapShippersProps, MapShippersState> {
    constructor(props: Readonly<MapShippersProps>) {
        super(props);

        this.state = {
            showViewStopsModal: false,            
            showFilterShippersModal: false,
            filterShippers: { ... this.props.filterShippers },
            viewStopsUserId: 0,
        };
        
        this.handleAvailableToWorkConfirmedChanged = this.handleAvailableToWorkConfirmedChanged.bind(this);
        this.handleSetShowRoute = this.handleSetShowRoute.bind(this);
        this.handleSetVisible = this.handleSetVisible.bind(this);

        this.handleShowViewStopsModal = this.handleShowViewStopsModal.bind(this);
        this.handleCloseViewStopsModal = this.handleCloseViewStopsModal.bind(this);                
        
        

        this.handleShowFilterShippersModal = this.handleShowFilterShippersModal.bind(this);
        this.handleCloseFilterShippersModal = this.handleCloseFilterShippersModal.bind(this);
        this.handleApplyFilterShippers = this.handleApplyFilterShippers.bind(this);
        this.handleRefreshShippersList = this.handleRefreshShippersList.bind(this);

        
        this.handleFilterUserNameChanged = this.handleFilterUserNameChanged.bind(this);
        this.handleFilterAvailableToWorkChanged = this.handleFilterAvailableToWorkChanged.bind(this);
        this.handleFilterAvailableToWorkConfirmedChanged = this.handleFilterAvailableToWorkConfirmedChanged.bind(this);
        this.handleFilterRequestsAllocatedChanged = this.handleFilterRequestsAllocatedChanged.bind(this);
        this.handleFilterAvailableToWorkSelectChange = this.handleFilterAvailableToWorkSelectChange.bind(this);
        this.handleFilterAvailableToWorkConfirmedSelectChanged = this.handleFilterAvailableToWorkConfirmedSelectChanged.bind(this);
        this.handleFilterRequestsAllocatedSelectChanged = this.handleFilterRequestsAllocatedSelectChanged.bind(this);
    }   
    

    handleRefreshShippersList() {
        this.props.refreshShippersList();
    }
    
    componentDidMount = async () => {        
        
    };

    public componentDidUpdate(prevProps: MapShippersProps, prevState: MapShippersState) {

        
    }

    handleAvailableToWorkConfirmedChanged(event: React.ChangeEvent<HTMLInputElement>, user_id: number) {
        //this.props.availableToWorkConfirmedChanged(user_id, event.target.checked);
        //incerare varianta prin signalR (cand sunt f multi livratori pe harta apelul de API dureaza f mult; la 500 de livratori pe profiler apare un timp de 5 secunde pt "XHR Ready State Change")
        this.props.availableToWorkConfirmed(user_id, event.target.checked);
    }

    handleSetShowRoute(event: React.ChangeEvent<HTMLInputElement>, user_id: number) {
        this.props.setShipperProp(user_id, 'SHOW_ROUTE', event.target.checked);
    }

    handleSetVisible(event: React.ChangeEvent<HTMLInputElement>, user_id: number) {
        this.props.setShipperProp(user_id, 'VISIBLE', event.target.checked);
    }

    handleShowViewStopsModal(user_id: number) {
        this.setState({
            showViewStopsModal: true,
            viewStopsUserId: user_id
        });
    }

    handleCloseViewStopsModal() {
        this.setState({
            showViewStopsModal: false,
            viewStopsUserId: 0
        });
    }

    handleFilterUserNameChanged(event: React.ChangeEvent<HTMLInputElement>) {

        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                user_name: event.target.value
            }
        }))
    }

    handleFilterAvailableToWorkChanged(event: React.ChangeEvent<HTMLInputElement>) {
        var checked = event.target.checked;
        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                filterAvailableToWork: checked
            }
        }))
    }

    handleFilterAvailableToWorkConfirmedChanged(event: React.ChangeEvent<HTMLInputElement>) {
        var checked = event.target.checked;
        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                filterAvailableToWorkConfirmed: checked
            }
        }))
    }

    handleFilterAvailableToWorkSelectChange(event: any) {
        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                availableToWork: event.target.value
            }
        }))
    }

    handleFilterAvailableToWorkConfirmedSelectChanged(event: any) {
        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                availableToWorkConfirmed: event.target.value
            }
        }))
    }

    handleFilterRequestsAllocatedSelectChanged(event: any) {
        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                requestsAllocated: event.target.value
            }
        }))
    }

    handleFilterRequestsAllocatedChanged(event: React.ChangeEvent<HTMLInputElement>) {
        var checked = event.target.checked;
        this.setState(prevState => ({
            filterShippers: {
                ...prevState.filterShippers,
                filterRequestsAllocated: checked
            }
        }))
    }

    handleShowFilterShippersModal() {
        this.setState({
            showFilterShippersModal: true,
            filterShippers: { ... this.props.filterShippers },
        });
    }

    handleCloseFilterShippersModal() {
        this.setState({
            showFilterShippersModal: false,
        });
    }

    handleApplyFilterShippers() {
        this.props.applyFilterShippers(this.state.filterShippers);
        this.setState({ showFilterShippersModal: false });
    }

    

    render() {
        return (
            <div>
                <Dialog fullScreen
                    onClose={this.handleCloseViewStopsModal}
                    aria-labelledby="customized-dialog-title-stops"
                    open={this.state.showViewStopsModal}>
                    <DialogTitle id="customized-dialog-title-stops" onClose={this.handleCloseViewStopsModal}>
                        Stops
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right">Transport Id</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right">Action Description</TableCell>
                                                    <TableCell align="right">Location Description</TableCell>
                                                    <TableCell align="right">Navigation Order</TableCell>
                                                    <TableCell align="right">Sender Name</TableCell>
                                                    <TableCell align="right">Sender Street</TableCell>
                                                    <TableCell align="right">Receiver Street</TableCell>
                                                    <TableCell align="right">Distance</TableCell>
                                                    <TableCell align="right">Duration</TableCell>
                                                    <TableCell align="right">Estimated Time Arrival</TableCell>
                                                    <TableCell align="right">Status description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.allocations_group_by_user_id[this.state.viewStopsUserId]?.map((row: MapStore.Allocation) => (
                                                    <TableRow key={row.allocation_id}>
                                                        <TableCell align="right">{row.transport_id}</TableCell>
                                                        <TableCell align="right">{row.status_description}</TableCell>
                                                        <TableCell align="right">{row.action_description}</TableCell>
                                                        <TableCell align="right">{row.location_description}</TableCell>
                                                        <TableCell align="right">{row.navigation_order}</TableCell>
                                                        <TableCell align="right">{row.details_first_line}</TableCell>
                                                        <TableCell align="right">{row.details_second_line}</TableCell>
                                                        <TableCell align="right">{row.details_third_line}</TableCell>
                                                        <TableCell align="right">{row.distance_total}</TableCell>
                                                        <TableCell align="right">{row.duration_total}</TableCell>
                                                        <TableCell align="right">{row.estimated_time_arrival}</TableCell>
                                                        <TableCell align="right">{row.in_time_status_description}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseViewStopsModal}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>                
                
                <Dialog onClose={this.handleCloseFilterShippersModal}
                    aria-labelledby="customized-dialog-title-filter-shippers"
                    open={this.state.showFilterShippersModal}
                    fullWidth={true}>
                    <DialogTitle id="customized-dialog-title-filter-shippers" onClose={this.handleCloseFilterShippersModal}>
                        Filter Shippers
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    User Name:
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="FilterUserName"
                                        name="FilterUserName"
                                        variant="standard"
                                        value={this.state.filterShippers.user_name}
                                        onChange={this.handleFilterUserNameChanged}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="filterAvailableToWork"
                                                id="filterAvailableToWork"
                                                color="primary"
                                                checked={this.state.filterShippers.filterAvailableToWork}
                                                onChange={(e) => this.handleFilterAvailableToWorkChanged(e)} />}
                                        label="Filter by available to work"
                                        labelPlacement="end"
                                    />
                                    <Select
                                        id="filterAvailableToWorkSelect"
                                        value={this.state.filterShippers.availableToWork}
                                        input={<Input />}
                                        disabled={!this.state.filterShippers.filterAvailableToWork}
                                        MenuProps={utils.MenuProps}
                                        onChange={this.handleFilterAvailableToWorkSelectChange}
                                    >
                                        <MenuItem value={"Yes"}>Yes</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="filterAvailableToWorkConfirmed"
                                                id="filterAvailableToWorkConfirmed"
                                                color="primary"
                                                checked={this.state.filterShippers.filterAvailableToWorkConfirmed}
                                                onChange={(e) => this.handleFilterAvailableToWorkConfirmedChanged(e)} />}
                                        label="Filter by available to work confirmed"
                                        labelPlacement="end"
                                    />

                                    <Select
                                        id="filterAvailableToWorkConfirmedSelect"
                                        value={this.state.filterShippers.availableToWorkConfirmed}
                                        input={<Input />}
                                        disabled={!this.state.filterShippers.filterAvailableToWorkConfirmed}
                                        MenuProps={utils.MenuProps}
                                        onChange={this.handleFilterAvailableToWorkConfirmedSelectChanged}
                                    >
                                        <MenuItem value={"Yes"}>Yes</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="filterAvailableRequestsAllocated"
                                                id="filterAvailableRequestsAllocated"
                                                color="primary"
                                                checked={this.state.filterShippers.filterRequestsAllocated}
                                                onChange={(e) => this.handleFilterRequestsAllocatedChanged(e)} />}
                                        label="Filter by requests allocated"
                                        labelPlacement="end"
                                    />

                                    <Select
                                        id="filterRequestsAllocatedSelect"
                                        value={this.state.filterShippers.requestsAllocated}
                                        input={<Input />}
                                        disabled={!this.state.filterShippers.filterRequestsAllocated}
                                        MenuProps={utils.MenuProps}
                                        onChange={this.handleFilterRequestsAllocatedSelectChanged}
                                    >
                                        <MenuItem value={"Yes"}>Yes</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseFilterShippersModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleApplyFilterShippers}>
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>               
                
                <div style={divFixed}>
                    <IconButton color="primary"
                        aria-label="Refresh"
                        onClick={(e) => this.handleRefreshShippersList()}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton color="primary"
                        aria-label="Filter"
                        onClick={(e) => this.handleShowFilterShippersModal()}>
                        <FindInPageOutlinedIcon />
                    </IconButton>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <small>
                                {"Upd time: (L): " + (this.props.shippersLocationDate == "" ? "N/A" : this.props.shippersLocationDate) +
                                    " (S): " + (this.props.shippersDate == "" ? "N/A" : this.props.shippersDate)
                                }
                            </small>
                        </Grid>
                    </Grid>
                </div>
                <div style={divStyleToast}>
                    {Object.entries(this.props.shippers).map(([key, shipper]) => {
                        if (utils.filterShippers(shipper, this.props.filterShippers, this.props.allocations_group_by_user_id_summary)) {
                            var shipperLocation = this.props.shippersLocation[shipper.user_id] || new ShipperLocation();
                            return(
                                <ShipperItem
                                    key={shipper.user_id}
                                    shipper={shipper}
                                    shipperLocation={shipperLocation}
                                    allocations_group_by_user_id_summary={this.props.allocations_group_by_user_id_summary[shipper.user_id]}
                                    handleSetVisible={this.handleSetVisible}
                                    handleShowViewStopsModal={this.handleShowViewStopsModal}
                                    handleSetShowRoute={this.handleSetShowRoute}
                                    handleAvailableToWorkConfirmedChanged={this.handleAvailableToWorkConfirmedChanged} />
                            )
                        }
                    }
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.map, // Selects which state properties are merged into the component's props
    MapStore.actionCreators // Selects which action creators are merged into the component's props
)(withWindowDimensions(MapShippers)); //TODO lasam cu withWindowDimensions chair daca nu e folosit pt ca nu merge sa transmit "handleFitAll" ca props ... nu stiu ce are zice ca nu vede handleFitAll ...
