import { NavigateFunction, Params } from "react-router";
import * as MapStore from '../store/Map';
import * as Models from "../models/Models";
import moment from 'moment';
import { MyMap, UserAllocationSummary } from "../store/Map";

type Col = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const ten = function (i: number) {
    return (i < 10 ? '0' : '') + i;
}

export function formatDate(date: Date | null): string {
    return moment(date).format('YYYY-MM-DD');
}

export function isDateValid(date: string): boolean {
    return moment(date, 'YYYY-MM-DD', true).isValid();
}

export const getDateISO = function (offset: number = 0) {
    var d = new Date();
    d.setDate(d.getDate() + offset);

    var ret = d.getFullYear() + "-" + ten(d.getMonth() + 1) + "-" + ten(d.getDate());

    return ret;
}

export const getDateTimeISO = function (offset: number = 0) {
    var d = new Date();
    d.setDate(d.getDate() + offset);

    var ret = d.getFullYear() + "-" + ten(d.getMonth() + 1) + "-" + ten(d.getDate()) + "T" + ten(d.getHours()) + ":" + ten(d.getMinutes());
    

    return ret;
}

export const getTimezoneOffset = function () {
    var d = new Date();
    return d.getTimezoneOffset();
}

//TODO: de vazut cum se face mai bine
//daca nu fac asa atunci instructiunea: < Grid item xs = { utils.getGridCol(element.col) } >
//da eroare cum ca xs nu accepta decat numar intre 1 si 12
export const getGridCol = function (col: number): Col
{
    if (col == 1)
        return 1;
    else if (col == 2)
        return 2;
    else if (col == 3)
        return 3;
    else if (col == 4)
        return 4;
    else if (col == 5)
        return 5;
    else if (col == 6)
        return 6;
    else if (col == 7)
        return 7;
    else if (col == 8)
        return 8;
    else if (col == 9)
        return 9;
    else if (col == 10)
        return 10;
    else if (col == 11)
        return 11;
    else if (col == 12)
        return 12;
    else
        return 2;
        
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface WindowDimension {
    width: number;
    height: number;
}

export interface IClasses {
    classes: any;
    navigate: NavigateFunction;
    params: Params<string>;
    location: Location;
    windowDimensions: WindowDimension;
}


export function groupArrayOfObjects(list: any, key:any) {
    return list.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


export const pageVisibilityApi = () => {
    let hidden, visibilityChange;
    var doc: any = document;

    if (typeof doc.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof doc.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof doc.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    else {
        visibilityChange = "visibilitychange";
        hidden = "hidden";
    }

    return { hidden, visibilityChange };
};


export function getBoolean(value: any) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
        case "Yes":
            return true;
        default:
            return false;
    }
};


export function decodeTokenAsJson(token: string) {
    //https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    var jsonObject = JSON.parse(jsonPayload);

    return jsonObject;
};

//https://stackoverflow.com/questions/41431322/how-to-convert-formdatahtml5-object-to-json
export function formToJSON(elem?: FormData) {
    let output = {};
    if (elem != null) {
        elem.forEach(
            (value, key) => {
                // Check if property already exist
                if (Object.prototype.hasOwnProperty.call(output, key)) {
                    let current = output[key];
                    if (!Array.isArray(current)) {
                        // If it's not an array, convert it to an array.
                        current = output[key] = [current];
                    }
                    current.push(value); // Add the new value to the array.
                } else {
                    output[key] = value;
                }
            }
        );
    }
    return JSON.stringify(output);
}

//https://advancedweb.hu/how-to-serialize-calls-to-an-async-function/
export const serialize = (fn: (...args: any[]) => any ) => {
    let queue = Promise.resolve();

    return (...args: any[]) => {
        const res = queue.then(() => fn(...args));
        queue = res.catch(() => { });
        return res;
    };
};

export function getSearchPaginationResultDefaultValues(): Models.SearchPaginationResult {
    let output: Models.SearchPaginationResult = {
        RowCount: 0,
        First: -1,
        Prev: -1,
        Current: 0,
        Next: -1,
        Last: -1,
        AfterIdNext: -1,
    };
    
    return output;
}

export function getSearchPaginationInputDefaultValues(): Models.SearchPaginationInput {
    let output: Models.SearchPaginationInput = {
        PageSize: 3,
        PageNumber: 0,
        AfterId: 0,
    };
    return output;
}

export function formatSpeed(speed: number): string {
    if (speed == -1)
        return "N/A"
    else
        return (speed * 3.6).toFixed(0) + " km/h";
}

export function shallowCompare(obj1: any, obj2: any): boolean {
    var obj_1_null_undefined = false;
    var obj_2_null_undefined = false;

    if (obj1 == null || obj1 == undefined)
        obj_1_null_undefined = true;

    if (obj2 == null || obj2 == undefined)
        obj_2_null_undefined = true;

    if (obj_1_null_undefined == true && obj_2_null_undefined == true)
        return true;
    else if (obj_1_null_undefined == true && obj_2_null_undefined == false || obj_1_null_undefined == false && obj_2_null_undefined == true)
        return false;
    else /*obj1 si obj2 nu sunt null*/
        return Object.keys(obj1).length === Object.keys(obj2).length &&
            Object.keys(obj1).every(key =>
                obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
}

export function filterTransportRequest (request: MapStore.TransportRequest, filterTransportRequests: MapStore.FilterTransportRequests): boolean {
    
    var ins_date = new Date(request.ins_date);
    var transport_id = filterTransportRequests.transport_id;
    var sender_name = filterTransportRequests.sender_name.toLowerCase();
    var receiver_name = filterTransportRequests.receiver_name.toLowerCase();
    var from_date = new Date(filterTransportRequests.from);
    var to_date = new Date(filterTransportRequests.to);
    var status = filterTransportRequests.status;
    var allocated_to = filterTransportRequests.allocated_to;

    if (
        (transport_id === '' || request.transport_id.toString().includes(transport_id)) &&
        (sender_name === '' || request.details.sender_name.toLowerCase().includes(sender_name)) &&
        (receiver_name === '' || request.details.receiver_name.toLowerCase().includes(receiver_name)) &&
        (filterTransportRequests.fromAnyTime || ins_date >= from_date) &&
        (filterTransportRequests.toAnyTime || ins_date <= to_date) &&
        (status.length === 0 || status.some(item => item === request.status)) &&
        (allocated_to === 0 || request.allocations.some(alloc => alloc.user_id === allocated_to))
    )
        return true;
    else
        return false;
}

export function filterShippers(shipper: MapStore.Shipper, filterShippers: MapStore.FilterShippers, allocation_summary_all: MyMap<string, UserAllocationSummary>): boolean {
    var result = true;
    var available_to_work = shipper.available_to_work;
    var available_to_work_confirmed = shipper.available_to_work_confirmed;
    const allocation_summary = allocation_summary_all[shipper.user_id];
    var requestsAllocated = allocation_summary ? allocation_summary.number_of_accepted_allocations > 0 || allocation_summary.number_of_new_allocations > 0 : false;

    if (filterShippers.user_name && !shipper.user_name.toLowerCase().includes(filterShippers.user_name.toLowerCase())){
        result = false;
    }
    if (filterShippers.filterAvailableToWork && getBoolean(filterShippers.availableToWork) != available_to_work){
        result = false;
    }        
    if (filterShippers.filterAvailableToWorkConfirmed && getBoolean(filterShippers.availableToWorkConfirmed) != available_to_work_confirmed){
        result = false;
    }
    if (filterShippers.filterRequestsAllocated && getBoolean(filterShippers.requestsAllocated) != requestsAllocated){
        result = false;
    }
    return result;

}