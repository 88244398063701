import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as FetchUsersStore from '../store/FetchUsers';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import * as utils from '../store/Utils';
import { withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...
type FetchUsersProps =
    utils.IClasses
    & FetchUsersStore.FetchUsersState // ... state we've requested from the Redux store
    & typeof FetchUsersStore.actionCreators; // ... plus action creators we've requested
    

export class FetchUsers extends React.PureComponent<FetchUsersProps, FetchUsersStore.SearchData> {



    columns: GridColDef[] = [        
        { field: 'UserName', headerName: 'User Name', flex: 0.1 },
        { field: 'Email', headerName: 'Email', flex: 0.1 },
        { field: 'FirstName', headerName: 'First Name', flex: 0.1 },
        { field: 'LastName', headerName: 'Last Name', flex: 0.1 },
        { field: 'PhoneNumber', headerName: 'Phone Number', flex: 0.1 },
        
        {
            field: 'user_id',
            headerName: 'Edit',
            width: 200,
            renderCell: (params: GridCellParams) => (
                <span>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={(id) => this.handleEdit(params.row['UserId'] as number)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={(id) => this.handleDelete(params.row['UserId'] as number, params.row['UserName']?.toString() || '')}
                    >
                        Delete
                    </Button>
                </span>
            ),
        },

    ];


    constructor(props: Readonly<FetchUsersProps>) {
        super(props);

        this.state = {
            PageSize: props.searchData.PageSize,
            PageNumber: props.searchData.PageNumber,
            AfterId: props.searchData.AfterId,
            FirstName: props.searchData.FirstName,
            LastName: props.searchData.LastName,
            UserName: props.searchData.UserName,
            Email: props.searchData.Email,
            Phone: props.searchData.Phone,
            Enabled: props.searchData.Enabled,
            EmailConfirmed: props.searchData.EmailConfirmed,
            PhoneConfirmed: props.searchData.PhoneConfirmed
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleNewUser = this.handleNewUser.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleChangeEmailConfirmed = this.handleChangeEmailConfirmed.bind(this);
        this.handleChangePhoneConfirmed = this.handleChangePhoneConfirmed.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
    }


    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeFirstName(event: any) {
        this.setState({ FirstName: event.target.value });
    }
    handleChangeLastName(event: any) {
        this.setState({ LastName: event.target.value });
    }
    handleChangeUserName(event: any) {
        this.setState({ UserName: event.target.value });
    }
    handleChangeEmail(event: any) {
        this.setState({ Email: event.target.value });
    }
    handleChangePhone(event: any) {
        this.setState({ Phone: event.target.value });
    }
    handleChangeEnabled(event: any) {
        this.setState({ Enabled: event.target.value });
    }
    handleChangeEmailConfirmed(event: any) {
        this.setState({ EmailConfirmed: event.target.value });
    }
    handleChangePhoneConfirmed(event: any) {
        this.setState({ PhoneConfirmed: event.target.value });
    }
    async handleChangePageSize(event: any) {
        await this.setState({ PageSize: event.target.value, PageNumber: 0 });
        this.search();
    }

    // Handle Delete request for an employee  
    private handleDelete(userId: number, userName: string) {
        if (!window.confirm("Do you want to delete the user: " + userName))
            return;
        else {
            this.props.requestDeleteUsers(userId);
        }
    }
    private handleEdit(userId: number) {
        this.props.navigate("/user/edit/" + userId);
    }
    private async handleSearch(event: any) {
        event.preventDefault();
        await this.setState({ PageNumber: 0 });
        if (this.handleValidation()) {
            this.search();
        }
    }

    private async handleNewUser(event: any) {
        this.props.navigate("/user/edit/0");
    }

    private async handleNavigation(pageNumber: number) {

        await this.setState({ PageNumber: pageNumber });

        if (this.handleValidation()) {
            this.search();
        }
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var FirstName = this.state.FirstName;
        var LastName = this.state.LastName;
        var Email = this.state.Email;
        var UserName = this.state.UserName;
        var Phone = this.state.Phone;

        if (FirstName != null && FirstName.length > 0 && !Validator.isAlphaNumericAndSpace(FirstName)) {
            err += "First Name should be alpha numetic!\n";
            result = false;
        }
        if (FirstName != null && FirstName.length > 50) {
            err += "First Name should not exceed 50 characters!\n";
            result = false;
        }

        if (LastName != null && LastName.length > 0 && !Validator.isAlphaNumericAndSpace(LastName)) {
            err += "Last Name should be alpha numetic!\n";
            result = false;
        }
        if (LastName != null && LastName.length > 50) {
            err += "Last Name should not exceed 50 characters!\n";
            result = false;
        }

        if (Email != null && Email.length > 0 && !Validator.isText(Email)) {
            err += "Email is not valid!\n";
            result = false;
        }
        if (Email != null && Email.length > 100) {
            err += "Email should not exceed 100 characters!\n";
            result = false;
        }

        if (UserName != null && UserName.length > 0 && !Validator.isText(UserName)) {
            err += "User name is not valid!\n";
            result = false;
        }
        if (UserName != null && UserName.length > 100) {
            err += "User name should not exceed 100 characters!\n";
            result = false;
        }

        if (Phone != null && Phone.length > 0 && !Validator.isNumeric(Phone)) {
            err += "Phone Number is not valid!\n";
            result = false;
        }
        if (Phone != null && Phone.length > 20) {
            err += "Phone Number should not exceed 20 characters!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
        }
        return result;
    }

    private search() {
        this.props.requestUsersData(this.state);
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        if (this.props.triggerSearch) {
            this.search();
        }
    }


    private ensureDataFetched() {
        this.props.requestSearchBoxData();

    }

    public render() {

        return (
            <React.Fragment>
                <h1 id="tabelLabel">Manage users</h1>
                {this.renderSearchBox()}
                {this.renderSearchResult()}
            </React.Fragment>
        );
    }

    private renderSearchBox() {
        return (
            this.props.isLoadingSearchBox ?
                <p><em>Loading search parameters...</em></p> :                
                <fieldset>
                    <legend>Search criteria</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={4}>                            
                            <TextField
                                id="FirstName"
                                name="FirstName"
                                label="First Name"
                                variant="standard"
                                value={this.state.FirstName}
                                onChange={this.handleChangeFirstName}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="LastName"
                                name="LastName"
                                label="Last Name"
                                variant="standard"
                                value={this.state.LastName}
                                onChange={this.handleChangeLastName}
                            />
                        </Grid>                        
                        <Grid item xs={4}>
                            <TextField
                                id="UserName"
                                name="UserName"
                                label="User name"
                                variant="standard"
                                value={this.state.UserName}
                                onChange={this.handleChangeUserName}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                id="Email"
                                name="Email"
                                label="Email"
                                variant="standard"
                                value={this.state.Email}
                                onChange={this.handleChangeEmail}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="Phone"
                                name="Phone"
                                label="Phone"
                                variant="standard"
                                value={this.state.Phone}
                                onChange={this.handleChangePhone}
                            />
                        </Grid>                        
                        <Grid item xs={4}>                            
                            <InputLabel shrink id="labelEnabled">
                                Enabled
                            </InputLabel>
                            <Select
                                id="Enabled"
                                labelId="labelEnabled"
                                value={this.state.Enabled}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={this.handleChangeEnabled}                                
                                >
                                {this.props.yesNoList.map(o =>
                                    <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel shrink id="labelEmailConfirmed">
                                Email confirmed
                            </InputLabel>
                            <Select
                                id="EmailConfirmed"
                                labelId="labelEmailConfirmed"
                                value={this.state.EmailConfirmed}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={this.handleChangeEmailConfirmed}
                            >
                                {this.props.yesNoList.map(o =>
                                    <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink id="labelPhoneConfirmed">
                                Phone confirmed
                            </InputLabel>
                            <Select
                                id="PhoneConfirmed"
                                labelId="labelPhoneConfirmed"
                                value={this.state.PhoneConfirmed}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={this.handleChangePhoneConfirmed}
                            >
                                {this.props.yesNoList.map(o =>
                                    <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink id="labelPageSize">
                                Page size
                            </InputLabel>
                            <Select
                                id="PageSize"
                                labelId="labelPageSize"
                                value={this.state.PageSize}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={this.handleChangePageSize}
                            >
                                {this.props.pageSizeList.map(o =>
                                    <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={styled.buttonsBox}>

                                <Button variant="contained" color="primary" onClick={this.handleSearch}>
                                    Search
                                </Button>
                                <Button variant="contained" color="primary" onClick={this.handleNewUser} >
                                    Create new user
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </fieldset>
        );
    }

    private renderSearchResult() {
        return (
            <div>
                <fieldset>
                    <legend>Search results</legend>
                    <DataGrid autoHeight
                        rows={this.props.searchResult.Data}
                        columns={this.columns}
                        rowCount={this.props.searchResult.RowCount}
                        getRowId={(r) => r.UserId}
                        pagination
                        pageSizeOptions={[this.state.PageSize]}
                        paginationMode="server"
                        paginationModel= {{ pageSize: this.state.PageSize, page: this.state.PageNumber }}
                        onPaginationModelChange={(e) => this.handleNavigation(e.page)}
                    />

                    
                    <div className="form-group text-center">
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.First)} onClick={(id) => this.handleNavigation(this.props.searchResult.First)}>{Navigation.displayNavigationText('First', this.props.searchResult.First)}</Button>
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Prev)} onClick={(id) => this.handleNavigation(this.props.searchResult.Prev)}>{Navigation.displayNavigationText('Previous', this.props.searchResult.Prev)}</Button>
                        <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', this.props.searchResult.Current)}</Button>
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Next)} onClick={(id) => this.handleNavigation(this.props.searchResult.Next)}>{Navigation.displayNavigationText('Next', this.props.searchResult.Next)}</Button>
                        <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Last)} onClick={(id) => this.handleNavigation(this.props.searchResult.Last)}>{Navigation.displayNavigationText('Last', this.props.searchResult.Last)}</Button>
                    </div>
                </fieldset>
            </div>
        );
    }




}

export default connect(
    (state: ApplicationState) => state.fetchUsers, // Selects which state properties are merged into the component's props
    FetchUsersStore.actionCreators // Selects which action creators are merged into the component's props
)(withNavigation(FetchUsers));
