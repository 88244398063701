import * as React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SearchTransportRequestsStore from '../store/SearchTransportRequests';
import { Grid, Button, Input, TextField, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import * as utils from '../store/Utils';
import { withNavigation } from '../hocs'


// At runtime, Redux will merge together...
type SearchTransportRequestsProps =
    utils.IClasses
    & SearchTransportRequestsStore.SearchTransportRequestsState // ... state we've requested from the Redux store        
    & typeof SearchTransportRequestsStore.actionCreators; // ... plus action creators we've requested
    

const divStyleTable: React.CSSProperties = {
    overflow: 'auto'
};
export class SearchTransportRequests extends React.PureComponent<SearchTransportRequestsProps, SearchTransportRequestsStore.SearchData> {
    
    constructor(props: Readonly<SearchTransportRequestsProps>) {
        super(props);

        this.state = {
            PageSize: props.searchData.PageSize,
            PageNumber: props.searchData.PageNumber,
            AfterId: props.searchData.AfterId,
            transport_id: props.searchData.transport_id,
            status: props.searchData.status,
            start_date: props.searchData.start_date,
            end_date: props.searchData.end_date,
            time_zone_offset: props.searchData.time_zone_offset,
            sender_name: props.searchData.sender_name,
            payment_type: props.searchData.payment_type,
            user_id: props.searchData.user_id,
            company_id: props.searchData.company_id,
        };

        // This binding is necessary to make "this" work in the callback

        this.handleSearch = this.handleSearch.bind(this);
        this.handleNavigateMore = this.handleNavigateMore.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

        this.handleChangeSenderName = this.handleChangeSenderName.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleChangeUserId = this.handleChangeUserId.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.handleChangePaymentType = this.handleChangePaymentType.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);

    }


    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeSenderName(event: any) {
        this.setState({ sender_name: event.target.value });
    }
    handleStatusChanged(event: any) {
        this.setState({ status: event.target.value as Array<number> });
    }

    handleChangeStartDate(event: any) {
        this.setState({ start_date: event.target.value });
    }
    handleChangeEndDate(event: any) {
        this.setState({ end_date: event.target.value });
    }
    handleChangeUserId(event: any) {
        this.setState({ user_id: Number(event.target.value) });
    }
    handleChangePageSize(event: any) {
        this.setState({ PageSize: parseInt(event.target.value) });
    }
    handleChangePaymentType(event: any) {
        this.setState({ payment_type: event.target.value });
    }
    handleChangeCompany(event: any) {
        this.setState({ company_id: event.target.value });
    }

    private async handleSearch(event: any) {
        event.preventDefault();
        if (this.handleValidation()) {
            await this.search(false, 0);
        }
    }

    private async handleNavigateMore() {

        if (this.handleValidation()) {
            this.search(true, this.props.searchResult.AfterIdNext);
        }
    }

    private handleEdit(id: string) {
        this.props.navigate("/transportrequest/edit/" + id);
    }

    private handleValidation() {
        let result = true;
        let err = '';
        /*
        var Destination = this.state.Destination;
        var StartDate = this.state.StartDate;
        var EndDate = this.state.EndDate;
        var Comment = this.state.Comment;

        if (Destination != "" && Destination.length > 0 && !Validator.isAlphaNumericAndSpace(Destination)) {
            err += "Destination should be alpha numeric!\n";
            result = false;
        }
        if (Destination != null && Destination.length > 50) {
            err += "Destination should not exceed 50 characters!\n";
            result = false;
        }

        if (Comment != null && Comment.length > 0 && !Validator.isText(Comment)) {
            err += "Illegal character in Comment field!\n";
            result = false;
        }
        if (Comment != null && Comment.length > 200) {
            err += "Comment should not exceed 200 characters!\n";
            result = false;
        }

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
        }
        */
        return result;
    }

    private async search(appendToState: boolean, AfterId: number) {
        await this.setState({ AfterId: AfterId });
        this.props.requestData(this.state, appendToState);
    }

    private async refresh() {
        this.props.refreshTripData();
    }

    // This method is called when the component is first added to the document
    public async componentDidMount() {
        if (!this.props.isLoadedSearchBox)
            this.props.requestFilters();

        //in case of update existing item
        if (this.props.triggerRefresh) {
            {
                this.props.resetTriggerRefresh();
                await this.refresh();
            }
        }
    }
    // This method is called when the route parameters change
    public async componentDidUpdate() {
        if (this.props.triggerSearch) {
            {
                this.props.resetTriggerTripsSearch();
                await this.search(false, 0);
            }
        }

        //in case of delete
        if (this.props.triggerRefresh) {
            {
                this.props.resetTriggerRefresh();
                await this.refresh();
            }
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderSearchBox()}
                {this.renderSearchResultTable()}
            </React.Fragment>
        );
    }

    private renderSearchBox() {        
        return (
            this.props.isLoadingSearchBox ?
                <p><em>Loading search parameters...</em></p> :
                <div>
                    <fieldset>
                        <legend>Search criteria</legend>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <TextField
                                    id="start_date"
                                    name="start_date"
                                    label="Start Date"
                                    type="datetime-local"
                                    value={this.state.start_date}
                                    variant="standard"
                                    onChange={this.handleChangeStartDate}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="sender_name"
                                    label="Sender Name"
                                    value={this.state.sender_name}
                                    onChange={this.handleChangeSenderName}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl sx={{ margin: 1, minWidth: "120px", maxWidth: "300px" }}>
                                    <InputLabel id="label-status">Status</InputLabel>
                                    <Select
                                        labelId="label-status"
                                        id="status"
                                        name="status"
                                        multiple
                                        value={this.state.status}
                                        input={<Input />}
                                        MenuProps={utils.MenuProps}
                                        onChange={this.handleStatusChanged}
                                    >
                                        {this.props.transportRequestStatus.map((status, index) => (
                                            <MenuItem key={status.status} value={status.status} >
                                                {status.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel id="label-payment-type">Payment Type</InputLabel>
                                <Select
                                    labelId="label-payment-type"
                                    id="payment_type"
                                    name="payment_type"
                                    value={this.state.payment_type}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleChangePaymentType}
                                >
                                    {this.props.paymentTypeList.map(payment =>
                                        <MenuItem key={payment.Value} value={payment.Value}>{payment.Description}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <TextField
                                    id="end_date"
                                    name="end_date"
                                    label="End Date"
                                    type="datetime-local"
                                    value={this.state.end_date}
                                    variant="standard"
                                    onChange={this.handleChangeEndDate}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel id="label-company">Company</InputLabel>
                                <Select
                                    labelId="label-company"
                                    id="company_id"
                                    name="company_id"
                                    value={this.state.company_id}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleChangeCompany}
                                >
                                    {this.props.companyList.map(company =>
                                        <MenuItem key={company.company_id} value={company.company_id}>{company.name}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel id="label-user">User</InputLabel>
                                <Select
                                    labelId="label-user"
                                    id="user_id"
                                    name="user_id"
                                    value={this.state.user_id}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleChangeUserId}
                                >
                                    {this.props.userList.map(user =>
                                        <MenuItem key={user.user_id} value={user.user_id}>{user.name}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel id="label-page-size">Page size</InputLabel>
                                <Select
                                    labelId="label-page-size"
                                    id="PageSize"
                                    name="PageSize"
                                    value={this.state.PageSize}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={this.handleChangePageSize}
                                >
                                    {this.props.pageSizeList.map(pageSize =>
                                        <MenuItem key={pageSize.Value} value={pageSize.Value}>{pageSize.Description}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" onClick={this.handleSearch}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </div>
        );
    }

    private renderSearchResultTable() {
        return (
            <div>
                <fieldset>
                    <legend>Search results</legend>
                    <div style={divStyleTable}>
                        <table className='table table-striped' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Transport Id</th>
                                    <th>Status</th>
                                    <th>Create Date</th>
                                    <th>Company</th>
                                    <th>Sender Name</th>
                                    <th>Payment Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.searchResult.Data.map((req: SearchTransportRequestsStore.TransportRequestSearchResult) =>
                                    <tr key={req.transport_id}>
                                        <td>{req.transport_id}</td>
                                        <td>{req.status}</td>
                                        <td>{new Date(req.ins_date).toLocaleString()}</td>
                                        <td>{req.company}</td>
                                        <td>{req.sender_name}</td>
                                        <td>{req.payment_type}</td>
                                        <td>
                                            <div className="buttons">
                                                <style>
                                                    {`@media print {.buttons{display: none;}}`}
                                                </style>
                                                <Button variant="contained" color="secondary" onClick={(id) => this.handleEdit(req.transport_id)}>
                                                    Edit
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="form-group text-center">
                            <Button variant="outlined" color="secondary" disabled={this.props.searchResult.AfterIdNext == -1} onMouseDown={this.handleNavigateMore}>
                                More
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }
}


export default connect(
    (state: ApplicationState) => state.searchTransportRequests, // Selects which state properties are merged into the component's props
    SearchTransportRequestsStore.actionCreators // Selects which action creators are merged into the component's props
)(withNavigation(SearchTransportRequests));
