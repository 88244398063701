import * as React from 'react';
import { connect } from 'react-redux'
import * as MobileAppStore from '../store/MobileApp';
import { ApplicationState } from '../store';
import authService from './api-authorization/AuthorizeService';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { LinearProgress } from '@mui/material';

type MobileAppProps =
    MobileAppStore.MobileAppState // ... state we've requested from the Redux store
    & typeof MobileAppStore.actionCreators; // ... plus action creators we've requested
    

class MobileApp extends React.PureComponent<MobileAppProps, MobileAppStore.MobileAppState> {
    
    constructor(props: Readonly<MobileAppProps>) {
        super(props);

        this.http_axios_download = this.http_axios_download.bind(this);
        this.state = {
            message: '',
            downloading: false,
            progress: 0,            
        };

    }

    componentDidMount = async () => {               

    };

    setProgress = (progress: number) =>{
        this.setState({ progress: progress});
    }

    async http_axios_download()
    {
        var token = null;
        var response: AxiosResponse;

        token = await authService.getAccessToken();

        this.setState({ downloading: true, progress: 0});

        axios.get('/api/MobileApp',
            {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob', // Important
                onDownloadProgress: (progressEvent) => {
                    if (progressEvent.total != null){
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
                        this.setProgress(percentCompleted);
                    }
                }
            }).then((response) => {
                //FileDownload(response.data, "app.aab");
                this.setState({ downloading: false, progress: 0});
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'app.apk'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        //FileDownload(response.data, "app.aab");
    }

    render() {
        return (
            <div>
                <button onClick={this.http_axios_download}>Download</button>                
                {this.state.downloading ? 
                    <LinearProgress variant="determinate" value={this.state.progress} />
                    :
                    null
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.mobileApp, // Selects which state properties are merged into the component's props
    MobileAppStore.actionCreators // Selects which action creators are merged into the component's props
)(MobileApp as any);

