import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_delete, http_axios_get } from './ApiInterface';
import * as Models from "../models/Models";
import * as utils from './Utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchCompaniesState {
    searchData: SearchData;
    searchResult: SearchResult;
    yesNoList: Models.ListValue[];
    pageSizeList: Models.ListValue[];
    isLoadingCompanies: boolean;
    isLoadingSearchBox: boolean;
    triggerSearch: boolean;
}

export class SearchData extends Models.SearchPaginationInput {
    name: string = "";
    Enabled: string = "0";
}

export class CompanyData {
    id: number = 0;
    company_id: number = 0;
    name: string = "";
    delivery_price: number = 0;
    delivery_price_ccy: string = "";
    enabled: boolean = false;
}

export class SearchResult extends Models.SearchPaginationResult {
    Data: CompanyData[] = [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestCompaniesDataAction {
    type: 'REQUEST_COMPANIES_DATA';
    searchData: SearchData;
}
export interface ReceiveCompaniesDataAction {
    type: 'RECEIVE_COMPANIES_DATA';
    searchResult: SearchResult;
}

export interface RequestCompaniesSearchBoxAction {
    type: 'REQUEST_COMPANIES_SEARCH_BOX_DATA';
}
export interface ReceiveCompaniesSearchBoxAction {
    type: 'RECEIVE_COMPANIES_SEARCH_BOX_DATA';
    yesNoList: Models.ListValue[];
    pageSizeList: Models.ListValue[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestCompaniesDataAction |
    ReceiveCompaniesDataAction |
    RequestCompaniesSearchBoxAction |
    ReceiveCompaniesSearchBoxAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestCompaniesData: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            http_axios_get('api/CompaniesSearch', data)
                .then(response => response as Promise<SearchResult>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_COMPANIES_DATA', searchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            var searchData = Object.assign(new SearchData, data);           
            dispatch({ type: 'REQUEST_COMPANIES_DATA', searchData });
        }
    },    
    requestDeleteCompany: (company_id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)            
        
        http_axios_delete('api/Companies/' + company_id)
            .then(response => {

                //https://github.com/aspnet/JavaScriptServices/issues/1321
                const untypedDispath = <any>dispatch;
                const jsonSearchForm = getState().fetchCompanies?.searchData;
                untypedDispath(actionCreators.requestCompaniesData(jsonSearchForm));
            })
            .catch(function (e) {
                console.log(e);
            })
    },
    requestSearchBoxData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all

            Promise.all([
                http_axios_get('api/Lists/1')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/Lists/3')
                    .then(list => {
                        return list;
                    })
            ]).then(([listYesNo, pageSize]) => {
                const yesNoList = listYesNo as Models.ListValue[];
                const pageSizeList = pageSize as Models.ListValue[];
                dispatch({ type: 'RECEIVE_COMPANIES_SEARCH_BOX_DATA', yesNoList: yesNoList, pageSizeList: pageSizeList });
            })
            .catch(function (e) {
                console.log(e);
            });

            dispatch({ type: 'REQUEST_COMPANIES_SEARCH_BOX_DATA' });
        }
        
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: FetchCompaniesState = {
    searchResult: {
        ...utils.getSearchPaginationResultDefaultValues(),
        Data: []
    },
    yesNoList: [],
    pageSizeList: [],
    isLoadingCompanies: false,
    isLoadingSearchBox: false,
    triggerSearch: false,
    searchData: {
        ...utils.getSearchPaginationInputDefaultValues(),
        name: "",
        Enabled: "0",
    }
};

export const reducer: Reducer<FetchCompaniesState> = (state: FetchCompaniesState | undefined, incomingAction: Action): FetchCompaniesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_COMPANIES_DATA':
            return {
                searchData: action.searchData,
                searchResult: state.searchResult,
                isLoadingCompanies: true,
                yesNoList: state.yesNoList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                triggerSearch: false,
            };
        case 'RECEIVE_COMPANIES_DATA':
            return {
                searchData: state.searchData,
                searchResult: action.searchResult,
                isLoadingCompanies: false,
                yesNoList: state.yesNoList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                triggerSearch: state.triggerSearch,
            };
        case 'REQUEST_COMPANIES_SEARCH_BOX_DATA':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                isLoadingCompanies: state.isLoadingCompanies,
                yesNoList: state.yesNoList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: true,
                triggerSearch: state.triggerSearch,
            };
        case 'RECEIVE_COMPANIES_SEARCH_BOX_DATA':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                isLoadingCompanies: state.isLoadingCompanies,
                yesNoList: action.yesNoList,
                pageSizeList: action.pageSizeList,
                isLoadingSearchBox: false,
                triggerSearch: true,
            };


        default:
            return state;
    }
};
