import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_get, http_axios_post, http_axios_put } from './ApiInterfaceAlternative';
import { TripData } from './FetchTrips';
import * as FetchTrips from './FetchTrips'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EditTripState {
    isLoading: boolean;
    tripData: TripData;
    triggerLoad: boolean;
    triggerLoadTripState: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestEditTripDataAction {
    type: 'REQUEST_EDIT_TRIP_DATA';
}
export interface ReceiveEditTripDataAction {
    type: 'RECEIVE_EDIT_TRIP_DATA';
    tripData: TripData;
    triggerLoadTripState: boolean;
}
export interface RequestSaveTripDataAction {
    type: 'REQUEST_SAVE_TRIP_DATA';
}
export interface ReceiveSaveTripDataAction {
    type: 'RECEIVE_SAVE_TRIP_DATA';
    triggerLoad: boolean;
}
export interface ResetTriggerLoadTripId {
    type: 'RESET_TRIGGER_LOAD_TRIP_ID';
}
export interface ResetTriggerLoadTripState {
    type: 'RESET_TRIGGER_LOAD_TRIP_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |    
    RequestEditTripDataAction |
    ReceiveEditTripDataAction |
    RequestSaveTripDataAction |
    ReceiveSaveTripDataAction |
    ResetTriggerLoadTripId |
    ResetTriggerLoadTripState;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,    
    requestEditData: (tripId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {

            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all
            Promise.all([
                http_axios_get('api/Trips', {"tripId": tripId })
                    .then(response =>  {
                        return response;
                    })
            ]).then(([trData]) => {
                const tripData = trData as TripData;
                dispatch({ type: 'RECEIVE_EDIT_TRIP_DATA', tripData: tripData, triggerLoadTripState: true });
            })
            .catch(function (e) {
                    console.log(e);
                });

            
            dispatch({ type: 'REQUEST_EDIT_TRIP_DATA' });
        }
    },
    requestSaveData: (tripId: number, data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/Trips';

            if (tripId > 0) {
                url = url + '/' + tripId;
                http_axios_put(url, data)
                    .then(response => {
                        const untypedDispath = <any>dispatch;
                        untypedDispath(FetchTrips.actionCreators.setTriggerTripsRefresh());

                        dispatch({ type: 'RECEIVE_SAVE_TRIP_DATA', triggerLoad: true });
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
            }
            else {
                http_axios_post(url, data)
                    .then(response => {
                        const untypedDispath = <any>dispatch;
                        untypedDispath(FetchTrips.actionCreators.setTriggerTripsRefresh());

                        dispatch({ type: 'RECEIVE_SAVE_TRIP_DATA', triggerLoad: false });
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
            }
            dispatch({ type: 'REQUEST_SAVE_TRIP_DATA' });
        }
    },
    resetTriggerLoadTripId: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_TRIP_ID' });
        }
    },
    resetTriggerLoadTripState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_TRIP_STATE' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: EditTripState = {
    isLoading: false,
    tripData: { TripId: 0, Destination: '', StartDate: '', EndDate: '', Comment: '', InsUser: '', InsDate: '', UpdUser: '', UpdDate: '', StartingIn: '' },
    triggerLoad: false,
    triggerLoadTripState: false
};

export const reducer: Reducer<EditTripState> = (state: EditTripState | undefined, incomingAction: Action): EditTripState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EDIT_TRIP_DATA':
            return {
                isLoading: true,
                tripData: unloadedState.tripData,
                triggerLoad: unloadedState.triggerLoad,
                triggerLoadTripState: unloadedState.triggerLoadTripState
            };        
        case 'RECEIVE_EDIT_TRIP_DATA':
            return {
                tripData: action.tripData,
                isLoading: false,
                triggerLoad: state.triggerLoad,
                triggerLoadTripState: action.triggerLoadTripState
            };
        case 'REQUEST_SAVE_TRIP_DATA':
            return state;
        case 'RECEIVE_SAVE_TRIP_DATA':
            return {
                tripData: state.tripData,
                isLoading: state.isLoading,
                triggerLoad: action.triggerLoad,
                triggerLoadTripState: state.triggerLoadTripState
            };
        case 'RESET_TRIGGER_LOAD_TRIP_ID':
            return {
                tripData: state.tripData,
                isLoading: state.isLoading,
                triggerLoad: false,
                triggerLoadTripState: state.triggerLoadTripState
            };
        case 'RESET_TRIGGER_LOAD_TRIP_STATE':
            return {
                tripData: state.tripData,
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                triggerLoadTripState: false
            };
        default:
            return state;
    }
};
