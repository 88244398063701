import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as EditTripStore from '../store/EditTrip';
import * as Validator from "../validator/Validator";
import { TripData } from '../store/FetchTrips'
import { Box, Grid, TextField, Button, TextareaAutosize } from '@mui/material';
import * as utils from '../store/Utils';
import { withParams, withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...dd

type EditTripProps =
    utils.IClasses
    & EditTripStore.EditTripState // ... state we've requested from the Redux store
    & typeof EditTripStore.actionCreators; // ... plus action creators we've requested
    

export class EditTrip extends React.PureComponent<EditTripProps, TripData> {

    id = 0;

    constructor(props: Readonly<EditTripProps>) {
        super(props);
        
        this.state = {
            TripId: props.tripData.TripId,
            Destination: props.tripData.Destination,
            StartDate: props.tripData.StartDate,
            EndDate: props.tripData.EndDate,
            Comment: props.tripData.Comment,
            InsUser: props.tripData.InsUser,
            InsDate: props.tripData.InsDate,
            UpdUser: props.tripData.UpdUser,
            UpdDate: props.tripData.UpdDate,
            StartingIn: props.tripData.StartingIn
        };
        

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleChangeDestination = this.handleChangeDestination.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleChangeComment = this.handleChangeComment.bind(this);
    }

    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeDestination(event: any) {
        this.setState({ Destination: event.target.value });
    }
    handleChangeStartDate(event: any) {
        this.setState({ StartDate: event.target.value });
    }
    handleChangeEndDate(event: any) {
        this.setState({ EndDate: event.target.value });
    }
    handleChangeComment(event: any) {
        this.setState({ Comment: event.target.value });
    }
    // This will handle the submit form event.  
    private async handleSave(event: any) {
        event.preventDefault();
        if (this.handleValidation()) {            
            this.props.requestSaveData(this.id, this.state);
        }
    }

    // This will handle Cancel button click event.  
    private handleClose(e: any) {
        e.preventDefault();
        this.props.navigate("/fetchtrips");
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var Destination = this.state.Destination;
        var StartDate = this.state.StartDate;
        var EndDate = this.state.EndDate;
        var Comment = this.state.Comment;

        if (!Validator.isAlphaNumericAndSpace(Destination)) {
            err += "Destination should be alpha numeric!\r\n";
            result = false;
        }

        if (Destination.length <= 0 || Destination.length > 50) {
            err += "Destination should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isText(Comment)) {
            err += "Illigal character in Comment field!\n";
            result = false;
        }
        if (Comment.length <= 0 || Comment.length > 200) {
            err += "Comment should be between 1 and 200 characters!\n";
            result = false;
        }

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
            console.log(err);
        }

        return result;
    }

    // This method is called when the component is first added to the document
    componentDidMount() {        
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate(prevProps: any, prevState: any) {

        //cand se schimba parametrul "id" din url trebui incarcate datele (este cazul cand se face redirect cu useNavigate)
        if (this.props.params != prevProps.params) {
            this.ensureDataFetched();
        }

        if (this.props.triggerLoad) {            

            this.props.resetTriggerLoadTripId();            
            this.ensureDataFetched();
        }
        if (this.props.triggerLoadTripState) {

            this.props.resetTriggerLoadTripState();

            this.setState({
                TripId: this.props.tripData.TripId,
                Destination: this.props.tripData.Destination,
                StartDate: this.props.tripData.StartDate,
                EndDate: this.props.tripData.EndDate,
                Comment: this.props.tripData.Comment,
                InsUser: this.props.tripData.InsUser,
                InsDate: this.props.tripData.InsDate,
                UpdUser: this.props.tripData.UpdUser,
                UpdDate: this.props.tripData.UpdDate,
                StartingIn: this.props.tripData.StartingIn
            });
        }
    }

    private ensureDataFetched() {
        var idS = "0";
        if (this.props.params.id)
            idS = this.props.params.id;

        this.id = parseInt(idS) || 0;

        this.props.requestEditData(this.id);
    }

    public render() {        
        return <div>
            <h3>Trip</h3>
            <hr />
            {this.renderCreateForm()}
        </div>;
    }
   
    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <form name="formEditTrip" id="formEditTrip" onSubmit={this.handleSave} >
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <fieldset>
                            <legend>Details</legend>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    Destination:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        id="Destination"
                                        name="Destination"
                                        value={this.state.Destination}
                                        onChange={this.handleChangeDestination}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Start Date:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        id="StartDate"
                                        name="StartDate"
                                        type="date"
                                        value={this.state.StartDate}
                                        onChange={this.handleChangeStartDate}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    End Date:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        id="EndDate"
                                        name="EndDate"
                                        type="date"
                                        value={this.state.EndDate}
                                        onChange={this.handleChangeEndDate}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    Comment:
                                </Grid>
                                <Grid item xs={9}>
                                    <TextareaAutosize                                        
                                        id="Comment"
                                        name="Comment"
                                        minRows={3}
                                        maxRows={7}
                                        className="textarea"
                                        value={this.state.Comment}
                                        onChange={this.handleChangeComment}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>                        
                    </Grid>
                    <Grid item xs={6}>
                        <fieldset>
                            <legend>Update history</legend>
                            <Grid container spacing={5}>

                                <Grid item xs={3}>
                                    Create User:
                                </Grid>
                                <Grid item xs={9}>
                                    {this.state.InsUser}
                                </Grid>

                                <Grid item xs={3}>
                                    Create Date:
                                </Grid>
                                <Grid item xs={9}>
                                    {this.state.InsDate}
                                </Grid>

                                <Grid item xs={3}>
                                    Update User:
                                </Grid>
                                <Grid item xs={9}>
                                    {this.state.UpdUser}
                                </Grid>

                                <Grid item xs={3}>
                                    Update Date:
                                </Grid>
                                <Grid item xs={9}>
                                    {this.state.UpdDate}
                                </Grid>

                            </Grid>
                        </fieldset>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styled.buttonsBox}>
                            <Button variant="contained" color="primary" type="submit">
                                Save
                            </Button>
                            <Button variant="contained" color="primary" onClick={this.handleClose}>
                                Close
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form >
        )
    }
}

export default connect(
    (state: ApplicationState) => state.editTrip, // Selects which state properties are merged into the component's props
    EditTripStore.actionCreators // Selects which action creators are merged into the component's props
)(withParams(withNavigation(EditTrip)));