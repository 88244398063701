import * as React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as DocumentsStore from '../store/Documents';
import { Grid, Button, Input, TextField, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import authService from './api-authorization/AuthorizeService';
import axios from 'axios'
import * as Navigation from "../navigation/Navigation";
import * as utils from '../store/Utils';

// At runtime, Redux will merge together...
type LocalProps =
    utils.IClasses
    & DocumentsStore.DocumentsState // ... state we've requested from the Redux store
    & typeof DocumentsStore.actionCreators; // ... plus action creators we've requested
    



export class Documents extends React.PureComponent<LocalProps, DocumentsStore.SearchData> {    
    
    columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'reference', headerName: 'Reference', width: 200 },
        { field: 'type', headerName: 'Type', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
        {
            field: 'ins_date',
            headerName: 'Date',
            width: 150,
            valueFormatter: (value) =>
                (new Date(value as string).toLocaleString()),
        },
        {
            field: 'document_id',
            headerName: 'View',
            renderCell: (params: GridCellParams) => (                
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={(id) => this.handleView(params.value as number)}
                >
                View
                </Button>
            ),
        },

    ];

    constructor(props: Readonly<LocalProps>) {
        super(props);

        this.state = {
            PageSize: props.searchData.PageSize,
            PageNumber: props.searchData.PageNumber,
            AfterId: props.searchData.AfterId,
            start_date: props.searchData.start_date,
            end_date: props.searchData.end_date,
            time_zone_offset: props.searchData.time_zone_offset,
            description: props.searchData.description,
        };

        // This binding is necessary to make "this" work in the callback

        this.handleSearch = this.handleSearch.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.handleView = this.handleView.bind(this);        

        this.handleChangeDescription = this.handleChangeDescription.bind(this);        
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);        
        this.handleChangePageSize = this.handleChangePageSize.bind(this);

        this.http_axios_download = this.http_axios_download.bind(this);
    }


    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeDescription(event: any) {
        this.setState({ description: event.target.value });
    }
    

    handleChangeStartDate(event: any) {
        this.setState({ start_date: event.target.value });
    }
    handleChangeEndDate(event: any) {
        this.setState({ end_date: event.target.value });
    }
    
    async handleChangePageSize(event: any) {
        await this.setState({ PageSize: event.target.value, PageNumber: 0 });
        this.search();
    }

    private async handleSearch(event: any) {
        event.preventDefault();
        await this.setState({ PageNumber: 0 });
        if (this.handleValidation()) {
            await this.search();
        }
    }    

    private async handleNavigation(pageNumber: number) {

        await this.setState({ PageNumber: pageNumber });

        if (this.handleValidation()) {
            this.search();
        }
    }

    private handleView(document_id: number) {
        this.http_axios_download(document_id);
    }

    private handleValidation() {
        let result = true;
        let err = '';
        /*
        var Destination = this.state.Destination;
        var StartDate = this.state.StartDate;
        var EndDate = this.state.EndDate;
        var Comment = this.state.Comment;

        if (Destination != "" && Destination.length > 0 && !Validator.isAlphaNumericAndSpace(Destination)) {
            err += "Destination should be alpha numeric!\n";
            result = false;
        }
        if (Destination != null && Destination.length > 50) {
            err += "Destination should not exceed 50 characters!\n";
            result = false;
        }

        if (Comment != null && Comment.length > 0 && !Validator.isText(Comment)) {
            err += "Illegal character in Comment field!\n";
            result = false;
        }
        if (Comment != null && Comment.length > 200) {
            err += "Comment should not exceed 200 characters!\n";
            result = false;
        }

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
        }
        */
        return result;
    }

    private async search() {
        this.props.requestData(this.state);
    }

    async http_axios_download(document_id: number) {
        var token = null;

        token = await authService.getAccessToken();

        axios.get('/api/Document/' + document_id,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob', // Important
            }).then((response) => {
                //FileDownload(response.data, "app.aab");
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', document_id + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        //FileDownload(response.data, "app.aab");
    }

    // This method is called when the component is first added to the document
    public async componentDidMount() {
        if (!this.props.isLoadedSearchBox)
            this.props.requestFilters();

    }
    // This method is called when the route parameters change
    public async componentDidUpdate() {
        if (this.props.triggerSearch) {
            {
                this.props.resetTriggerTripsSearch();
                await this.search();
            }
        }

        if (this.props.triggerRefresh) {
            {
                this.props.resetTriggerRefresh();
                await this.props.refreshData();
            }
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderSearchBox()}
                {this.renderSearchResultTable()}
            </React.Fragment>
        );
    }

    private renderSearchBox() {        
        return (
            this.props.isLoadingSearchBox ?
                <p><em>Loading search parameters...</em></p> :
                <div>
                    <fieldset>
                        <legend>Search criteria</legend>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <TextField
                                    id="start_date"
                                    name="start_date"
                                    label="Start Date"
                                    type="date"
                                    variant="standard"
                                    value={this.state.start_date}
                                    onChange={this.handleChangeStartDate}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="end_date"
                                    name="end_date"
                                    label="End Date"
                                    type="date"
                                    variant="standard"
                                    value={this.state.end_date}
                                    onChange={this.handleChangeEndDate}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="Description"
                                    label="Description"
                                    variant="standard"
                                    value={this.state.description}
                                    onChange={this.handleChangeDescription} />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel id="label-page-size">Page size</InputLabel>
                                <Select
                                    labelId="label-page-size"
                                    id="PageSize"
                                    name="PageSize"
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    value={this.state.PageSize} onChange={this.handleChangePageSize}
                                >
                                    {this.props.pageSizeList.map(pageSize =>
                                        <MenuItem key={pageSize.Value} value={pageSize.Value}>{pageSize.Description}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        </Grid>                        
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" onClick={this.handleSearch}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </div>
        );
    }

    private renderSearchResultTable() {
        return (
            <div>
                <fieldset>
                    <legend>Search results</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <DataGrid
                                autoHeight
                                rows={this.props.searchResult.Data}
                                columns={this.columns}
                                rowCount={this.props.searchResult.RowCount}
                                pagination
                                pageSizeOptions={[this.state.PageSize]}
                                paginationMode="server"
                                paginationModel= {{ pageSize: this.state.PageSize, page: this.state.PageNumber }}
                                onPaginationModelChange={(e) => this.handleNavigation(e.page)}
                                
                            />
                            <div className="form-group text-center">
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.First)} onClick={(id) => this.handleNavigation(this.props.searchResult.First)}>{Navigation.displayNavigationText('First', this.props.searchResult.First)}</Button>
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Prev)} onClick={(id) => this.handleNavigation(this.props.searchResult.Prev)}>{Navigation.displayNavigationText('Previous', this.props.searchResult.Prev)}</Button>
                                <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', this.props.searchResult.Current)}</Button>
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Next)} onClick={(id) => this.handleNavigation(this.props.searchResult.Next)}>{Navigation.displayNavigationText('Next', this.props.searchResult.Next)}</Button>
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Last)} onClick={(id) => this.handleNavigation(this.props.searchResult.Last)}>{Navigation.displayNavigationText('Last', this.props.searchResult.Last)}</Button>
                            </div>
                        </Grid>
                    </Grid>

                </fieldset>
            </div>
        );
    }
}


export default connect(
    (state: ApplicationState) => state.documents, // Selects which state properties are merged into the component's props
    DocumentsStore.actionCreators // Selects which action creators are merged into the component's props
)(Documents as any);
