import * as React from 'react';
import { connect } from 'react-redux'
import { Snackbar, Alert } from '@mui/material';
import { ApplicationState } from '../store';
import { store } from '../store/configureStore'
import * as ApiInterfaceStore from '../store/ApiInterface';
import * as utils from '../store/Utils';
import { pageVisibilityApi } from '../store/Utils';


interface ApiInterfaceState {
    silentRenew: boolean;
}


const { hidden, visibilityChange } = pageVisibilityApi();


type ApiInterfaceProps =
    utils.IClasses
    & ApiInterfaceStore.ApiInterfaceState // ... state we've requested from the Redux store
    & typeof ApiInterfaceStore.actionCreators; // ... plus action creators we've requested

export class ApiInterface extends React.PureComponent<ApiInterfaceProps, ApiInterfaceState> {

    constructor(props: Readonly<ApiInterfaceProps>) {
        super(props);
        this.state = { silentRenew: false };
        // This binding is necessary to make "this" work in the callback  
        this.handleCloseError = this.handleCloseError.bind(this);
        this.handleCloseInfo = this.handleCloseInfo.bind(this);
        
    }

    async silentRenew() {
        

        if (this.state.silentRenew) {
            console.log('silent renew already started, exit');
            return;
        }

        this.setState({ silentRenew: true });
        await ApiInterfaceStore.checkTokenValidTryRefreshIfNot();
        this.setState({ silentRenew: false });

        
    }

    async handleVisibilityChange(evt: Event) {

        //https://github.com/IdentityModel/oidc-client-js/issues/143
        //solve silent refreh token when computer is off
        //when page is visible again, try to get silent new token
        
        if (!document[hidden]) {
            this.silentRenew();
        }
    };

    async handleFocusChange(evt: Event) {

        //https://github.com/IdentityModel/oidc-client-js/issues/143
        //solve silent refreh token when computer is off
        //when page is visible again, try to get silent new token
        this.silentRenew();
    };


    // This method is called when the component is first added to the document
    componentDidMount() {
        //https://able.bio/drenther/track-page-visibility-in-react-using-render-props--78o9yw5
        document.addEventListener(visibilityChange, e => this.handleVisibilityChange(e));
        document.addEventListener("focus", e => this.handleFocusChange(e));
        window.addEventListener("focus", e => this.handleFocusChange(e));
    }

    // This method is called when the route parameters change
    componentDidUpdate() {

    }

    componentWillUnmount() {
        document.removeEventListener(visibilityChange, this.handleVisibilityChange);
        document.removeEventListener("focus", this.handleFocusChange);
        window.removeEventListener("focus", this.handleFocusChange);
    }
    private handleCloseError() {
        //this.props.hideError();
        store.dispatch({ type: 'HIDE_ERROR' });
    }

    private handleCloseInfo() {
        //this.props.hideInfo();
        store.dispatch({ type: 'HIDE_INFO' });
    }

    public render() {
        return (
            <>
                {
                    this.props.isErrorOpen && this.props.error && (
                        <div>
                            <Snackbar
                                open={this.props.isErrorOpen}
                                autoHideDuration={6000}
                                onClose={this.handleCloseError}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            >
                                <Alert elevation={6} variant="filled" onClose={this.handleCloseError} severity="error" >{this.props.error}</Alert>
                            </Snackbar>
                        </div>
                    )
                }
                {
                    this.props.isInfoOpen && this.props.info && (
                        <div>
                            <Snackbar
                                open={this.props.isInfoOpen}
                                autoHideDuration={6000}
                                onClose={this.handleCloseInfo}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            >
                                <Alert elevation={6} variant="filled" onClose={this.handleCloseInfo} severity="success">{this.props.info}</Alert>
                            </Snackbar>
                        </div>
                    )
                }
            </>
        )
    }
}




export default connect(
    (state: ApplicationState) => state.ApiInterface, // Selects which state properties are merged into the component's props
    ApiInterfaceStore.actionCreators // Selects which action creators are merged into the component's props
)(ApiInterface as any);



