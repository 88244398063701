import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_get, http_axios_post, http_axios_delete } from './ApiInterface';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export class ApplicationParametersExpanded {
    /* mobile app parameters */
    ShowPickup: boolean = false;

    /* allocation parameters */
    AllocationMethod: string = "";
    OptimizationType: string = "";
    CurrentRouteLockType: string = "";
    AllowUserToChangeNavigationOrder: boolean = false;
    TimeWindowConstraint: boolean = false;
    VehicleWorkingTimeConstraint: boolean = false;
    VehicleStopsContraint: boolean = false;
    PickupDropoffContraint: boolean = false;
    VehicleMinimizeStartTime: boolean = false;
    VehicleMinimizeEndTime: boolean = false;
    PrecedenceContraint: boolean = false;
    AllowDrop: boolean = false;
    DropPenality: number = 0;
    MinimizeEmptyRoutes: boolean = false;
    EmptyRoutePenality: number = 0;
    DailyEndTime: string = "";
    MaximumAllowWaitingTime: number = 0;
    WaitingTimeAtLocation: number = 0;
    GlobalCostCoefficient: number = 0;
    InitialLoadTime: boolean = false;
    InitialLoadTimeValue: number = 0;
    FinalUnloadTime: boolean = false;
    FinalUnloadTimeValue: number = 0;
    DepotLatitude: number = 0;
    DepotLongitude: number = 0;
    ManattanDistanceFactor: number = 1;
    WorkingDay: string = "";
}

export class Vehicle {
    vehicle_id: number = 0;
    name: string = "";
    description: string = "";
    speed: number = 0;
    pickup_dropoff_capacity: number = 0;
}

export class Depot {
    depot_id: number = 0;
    name: string = "";
    latitude: number = 0;
    longitude: number = 0;
}

export interface EditParametersState {
    isLoading: boolean;
    parameters: ApplicationParametersExpanded;
    vehicles: Array<Vehicle>;
    depots: Array<Depot>;
    triggerLoadState: boolean;
    triggerLoad: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface EditParametersRequestDataAction {
    type: 'EDIT_PARAMETERS_REQUEST_DATA';
}
export interface EditParametersReceiveDataAction {
    type: 'EDIT_PARAMETERS_RECEIVE_DATA';
    parameters: ApplicationParametersExpanded;
    vehicles: Array<Vehicle>;
    depots: Array<Depot>;
    triggerLoadState: boolean;    
}
export interface EditParametersSetTriggerLoadAction {
    type: 'EDIT_PARAMETERS_SET_TRIGGER_LOAD';
}
export interface EditParametersResetTriggerLoadAction {
    type: 'EDIT_PARAMETERS_RESET_TRIGGER_LOAD';
}
export interface EditParametersResetTriggerLoadStateAction {
    type: 'EDIT_PARAMETERS_RESET_TRIGGER_LOAD_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |    
    EditParametersRequestDataAction |
    EditParametersReceiveDataAction |
    EditParametersSetTriggerLoadAction |
    EditParametersResetTriggerLoadAction |
    EditParametersResetTriggerLoadStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,    
    requestApplicationParameters: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {

            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all
            Promise.all([
                http_axios_get('api/ApplicationParametersCompany')
                    .then(response => {
                        return response;
                    }),
                http_axios_get('api/Vehicles')
                    .then(response => {
                        return response;
                    }),
                http_axios_get('api/Depots')
                    .then(response => {
                        return response;
                    }),

            ]).then(([parameters, vehicles, depots]) => {
                const parametersData = parameters as ApplicationParametersExpanded;
                const vehiclesData = vehicles as Array<Vehicle>;
                const depotsData = depots as Array<Depot>;

                dispatch({
                    type: 'EDIT_PARAMETERS_RECEIVE_DATA',
                    parameters: parametersData,
                    vehicles: vehiclesData,
                    depots: depotsData,
                    triggerLoadState: true
                });
            })
            .catch(function (e) {
                    console.log(e);
                });

            
            dispatch({ type: 'EDIT_PARAMETERS_REQUEST_DATA' });
        }
    },
    requestSaveParameters: (data: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/ApplicationParametersCompany';

            http_axios_post(url, data)
                .then(response => {
                    dispatch({ type: 'EDIT_PARAMETERS_SET_TRIGGER_LOAD' }); /* the form is updated because of the location change event */
                })
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
    requestSaveVehicle: (data: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/Vehicles';

            http_axios_post(url, data)
                .then(response => {
                    dispatch({ type: 'EDIT_PARAMETERS_SET_TRIGGER_LOAD' });
                })
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
    requestDeleteVehicle: (vehicle_id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        http_axios_delete('api/Vehicles/' + vehicle_id)
            .then(response => {
                dispatch({ type: 'EDIT_PARAMETERS_SET_TRIGGER_LOAD' });
            })
            .catch(function (e) {
                console.log(e);
            })
    },

    requestSaveDepot: (data: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/Depots';

            http_axios_post(url, data)
                .then(response => {
                    dispatch({ type: 'EDIT_PARAMETERS_SET_TRIGGER_LOAD' });
                })
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
    requestDeleteDepot: (depot_id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        http_axios_delete('api/Depots/' + depot_id)
            .then(response => {
                dispatch({ type: 'EDIT_PARAMETERS_SET_TRIGGER_LOAD' });
            })
            .catch(function (e) {
                console.log(e);
            })
    },
    testToolsClearAllAllocations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/TestTools/ClearAllAllocations';

            http_axios_post(url)
                .then(response => {
                    
                })
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
    resetTriggerLoad: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'EDIT_PARAMETERS_RESET_TRIGGER_LOAD' });
        }
    },
    resetTriggerLoadState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'EDIT_PARAMETERS_RESET_TRIGGER_LOAD_STATE' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: EditParametersState = {
    isLoading: false,
    parameters: {
        ShowPickup: false,
        
        AllocationMethod: "",
        OptimizationType: "",
        CurrentRouteLockType: "",
        AllowUserToChangeNavigationOrder: false,
        TimeWindowConstraint: false,
        VehicleWorkingTimeConstraint: false,
        VehicleStopsContraint: false,
        PickupDropoffContraint: false,
        VehicleMinimizeStartTime: false,
        VehicleMinimizeEndTime: false,
        PrecedenceContraint: false,
        AllowDrop: false,
        DropPenality: 0,
        MinimizeEmptyRoutes: false,
        EmptyRoutePenality: 0,
        DailyEndTime: "",
        MaximumAllowWaitingTime: 0,
        WaitingTimeAtLocation: 0,
        GlobalCostCoefficient: 0,
        InitialLoadTime: false,
        InitialLoadTimeValue: 0,
        FinalUnloadTime: false,
        FinalUnloadTimeValue: 0,
        ManattanDistanceFactor: 1,
        WorkingDay: "",
        DepotLatitude: 0,
        DepotLongitude: 0,
    },
    vehicles: [],
    depots: [],
    triggerLoad: false,
    triggerLoadState: false
};

export const reducer: Reducer<EditParametersState> = (state: EditParametersState | undefined, incomingAction: Action): EditParametersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'EDIT_PARAMETERS_REQUEST_DATA':
            return {
                isLoading: true,
                parameters: state.parameters,
                vehicles: state.vehicles,
                depots: state.depots,
                triggerLoad: state.triggerLoad,
                triggerLoadState: state.triggerLoadState
            };        
        case 'EDIT_PARAMETERS_RECEIVE_DATA':
            return {
                isLoading: false,
                parameters: action.parameters,
                vehicles: action.vehicles,
                depots: action.depots,
                triggerLoad: state.triggerLoad,
                triggerLoadState: action.triggerLoadState
            };
        case 'EDIT_PARAMETERS_RESET_TRIGGER_LOAD':
            return {
                isLoading: state.isLoading,
                parameters: state.parameters,
                vehicles: state.vehicles,
                depots: state.depots,
                triggerLoad: false,
                triggerLoadState: state.triggerLoadState
            };
        case 'EDIT_PARAMETERS_SET_TRIGGER_LOAD':
            return {
                isLoading: state.isLoading,
                parameters: state.parameters,
                vehicles: state.vehicles,
                depots: state.depots,
                triggerLoad: true,
                triggerLoadState: state.triggerLoadState
            };
        case 'EDIT_PARAMETERS_RESET_TRIGGER_LOAD_STATE':
            return {
                isLoading: state.isLoading,
                parameters: state.parameters,
                vehicles: state.vehicles,
                depots: state.depots,
                triggerLoad: state.triggerLoad,
                triggerLoadState: false
            };
        default:
            return state;
    }
};
