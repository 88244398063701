export class ListValue {
    Value: number = 0;
    Description: string = "";
}

export class SearchPaginationResult {
    RowCount: number = 0;
    First: number = 0;
    Prev: number = 0;
    Current: number = 0;
    Next: number = 0;
    Last: number = 0;
    AfterIdNext: number = 0;
}


export class SearchPaginationInput {
    PageNumber: number = 0;
    PageSize: number = 0;
    AfterId: number = 0;
}

export class Company {
    company_id: number = 0;
    name: string = "";
}


export class Shop {
    shop_id: number = 0;
    name: string = "";
}

export class User {
    user_id: number = 0;
    name: string = "";
    user_name: string = "";
}