import * as App from './App';
import * as ApiInterface from './ApiInterface';
import * as SiganlR from './SignalR';
import * as FetchUsers from './FetchUsers';
import * as EditUser from './EditUser';
import * as FetchTrips from './FetchTrips';
import * as EditTrip from './EditTrip';
import * as Map from './Map';
import * as TransportRequest from './TransportRequest';
import * as SearchTransportRequests from './SearchTransportRequests';
import * as MobileApp from './MobileApp';
import * as EditParameters from './EditParameters';
import * as Upload from './Upload';
import * as Settlements from './Settlements';
import * as Documents from './Documents';
import * as FetchCompanies from './FetchCompanies';
import * as EditCompany from './EditCompany';
import * as FetchShops from './FetchShops';
import * as EditShop from './EditShop';

// The top-level state object
export interface ApplicationState {
    App: App.AppState | undefined;
    ApiInterface: ApiInterface.ApiInterfaceState | undefined;
    SiganlR: SiganlR.SignalRState | undefined;
    fetchUsers: FetchUsers.FetchUsersState | undefined;
    editUser: EditUser.EditUserState | undefined;
    fetchTrips: FetchTrips.FetchTripsState | undefined;
    editTrip: EditTrip.EditTripState | undefined;
    map: Map.MapState | undefined;
    transportRequest: TransportRequest.TransportRequestState;
    searchTransportRequests: SearchTransportRequests.SearchTransportRequestsState;
    mobileApp: MobileApp.MobileAppState;
    editParameters: EditParameters.EditParametersState;
    upload: Upload.UploadState;
    settlements: Settlements.SettlementsState;
    documents: Documents.DocumentsState;
    fetchCompanies: FetchCompanies.FetchCompaniesState;
    editCompany: EditCompany.EditCompanyState;
    fetchShops: FetchShops.FetchShopsState;
    editShop: EditShop.EditShopState;

}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    //counter: Counter.reducer,
    App: App.reducer,
    ApiInterface: ApiInterface.reducer,
    SiganlR: SiganlR.reducer,
    fetchUsers: FetchUsers.reducer,
    editUser: EditUser.reducer,
    fetchTrips: FetchTrips.reducer,
    editTrip: EditTrip.reducer,
    map: Map.reducer,
    transportRequest: TransportRequest.reducer,
    searchTransportRequests: SearchTransportRequests.reducer,
    mobileApp: MobileApp.reducer,
    editParameters: EditParameters.reducer,
    upload: Upload.reducer,
    settlements: Settlements.reducer,
    documents: Documents.reducer,
    fetchCompanies: FetchCompanies.reducer,
    editCompany: EditCompany.reducer,
    fetchShops: FetchShops.reducer,
    editShop: EditShop.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
