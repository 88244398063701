import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as EditCompanyStore from '../store/EditCompany';
import * as Validator from "../validator/Validator";
import { CompanyData } from '../store/FetchCompanies';
import { Box, Select, Grid, TextField, Button, MenuItem, Input, Switch, } from '@mui/material';
import * as utils from '../store/Utils';
import { withParams, withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...

type EditCompanyProps =
    utils.IClasses
    & EditCompanyStore.EditCompanyState // ... state we've requested from the Redux store
    & typeof EditCompanyStore.actionCreators; // ... plus action creators we've requested
    


export class EditCompany extends React.PureComponent<EditCompanyProps, CompanyData> {

    id = 0;

    constructor(props: Readonly<EditCompanyProps>) {
        super(props);

        this.state = {
            id: props.companyData.id,
            company_id: props.companyData.company_id,
            name: props.companyData.name,
            delivery_price: props.companyData.delivery_price,
            delivery_price_ccy: props.companyData.delivery_price_ccy,
            enabled: props.companyData.enabled,
            
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleChangeDeliveryPrice = this.handleChangeDeliveryPrice.bind(this);
        this.handleChangeDeliveryPriceCcy = this.handleChangeDeliveryPriceCcy.bind(this);
        
    }

    // This will handle the submit form event.  
    private async handleSave(event: any) {
        event.preventDefault();
        if (this.handleValidation()) {
            this.props.requestSaveData(this.state, this.id > 0);
        }
    }
    // This will handle Cancel button click event.  
    private handleClose(e: any) {
        e.preventDefault();
        this.props.navigate("/companies");
    }
    
    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/    
    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
    }
    handleChangeEnabled(event: any) {
        this.setState({ enabled: event.target.checked });
    }    
    handleChangeDeliveryPrice(event: any) {
        var value = Number(event.target.value);
        this.setState({ delivery_price: value });
    }
    handleChangeDeliveryPriceCcy(event: any) {
        var value = event.target.value as string;
        this.setState({ delivery_price_ccy: value });
    }

    // This method is called when the component is first added to the document
    componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate(prevProps: any, prevState: any) {

        //cand se schimba parametrul "id" din url trebui incarcate datele (este cazul cand se face redirect cu useNavigate)
        if (this.props.params != prevProps.params) {
            this.ensureDataFetched();
        }

        if (this.props.triggerLoad) {
            this.props.resetTriggerLoad();
            this.ensureDataFetched();
        }
        if (this.props.triggerLoadState) {

            this.props.resetTriggerLoadState();

            this.setState({
                company_id: this.props.companyData.company_id,
                name: this.props.companyData.name,
                delivery_price: this.props.companyData.delivery_price,
                delivery_price_ccy: this.props.companyData.delivery_price_ccy,
                enabled: this.props.companyData.enabled,
            });
        }
    }

    private ensureDataFetched() {
        var idS = "0";
        if (this.props.params.id)
            idS = this.props.params.id;

        this.id = parseInt(idS) || 0;

        this.props.requestEditData(this.id);
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var name = this.state.name;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 50) {
            err += "Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            this.props.setError(err)
        }

        return result;
    }

    public render() {
        return (
            <div>
                <h3>Company management</h3>
                <hr />
                {this.renderCreateForm()}
            </div>
        );
    }
    

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <div>
                <fieldset>
                    <legend>Details</legend>
                    <form name="formEditCompany" id="formEditCompany" onSubmit={this.handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="Name"
                                            name="Name"
                                            variant="standard"
                                            value={this.state.name}
                                            onChange={this.handleChangeName}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            checked={this.state.enabled}
                                            onChange={(e) => this.handleChangeEnabled(e)}
                                            name="EmailConfirmed"
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Delivery Price:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="DeliveryPrice"
                                            name="DeliveryPrice"
                                            type="number"
                                            variant="standard"
                                            value={this.state.delivery_price}
                                            onChange={this.handleChangeDeliveryPrice}
                                            required
                                        />
                                        <Select
                                            id="DeliveryPrice"
                                            value={this.state.delivery_price_ccy}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={this.handleChangeDeliveryPriceCcy}
                                        >
                                            {this.props.ccyList.map(o =>
                                                <MenuItem value={o.Value}>{o.Description || o.Value}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" type="submit">
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={this.handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.editCompany, // Selects which state properties are merged into the component's props
    EditCompanyStore.actionCreators // Selects which action creators are merged into the component's props
)(withParams(withNavigation(EditCompany)));



