import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_delete, http_axios_get, http_axios_post } from './ApiInterface';
import * as Models from "../models/Models";
import { TransportRequestStatus } from './Map';
import * as utils from './Utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SearchTransportRequestsState {
    navigateMore?: any;
    searchData: SearchData;
    searchResult: TransportRequestsSearchResult;
    userList: Models.User[];
    pageSizeList: Models.ListValue[];
    paymentTypeList: Models.ListValue[];
    companyList: Models.Company[];
    transportRequestStatus: TransportRequestStatus[];
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingSearchBox: boolean;
    isLoadedSearchBox: boolean;
    triggerSearch: boolean;
    triggerRefresh: boolean;
}


export class SearchData extends Models.SearchPaginationInput {
    transport_id: string = "";    
    status: Array<number> = [];
    start_date: string = "";
    end_date: string = "";
    time_zone_offset: string = "";
    sender_name: string = "";
    payment_type: string = "";
    user_id: number = 0;
    company_id: number = 0;
}



export class TransportRequestSearchResult {
    transport_id: string = "0";
    status: string = "";
    ins_date: string = "";
    company: string = "";
    sender_name: string = "";
    payment_type: string = "";
}



export class TransportRequestsSearchResult extends Models.SearchPaginationResult {
    Data: TransportRequestSearchResult[] = [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestDataAction {
    type: 'TRANSPORT_REQUESTS_SEARCH_REQUEST_DATA';
    searchData: SearchData;
}
export interface ReceiveDataAction {
    type: 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_DATA';
    searchResult: TransportRequestsSearchResult;
}

export interface ReceiveDataAppendAction {
    type: 'TRANSPORT_REQUESTS_SEARCH_APPEND';
    searchResult: TransportRequestsSearchResult;
}

export interface RequestSearchFiltersAction {
    type: 'TRANSPORT_REQUESTS_SEARCH_FILTERS';
}

export interface ReceiveSearchFiltersAction {
    type: 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_FILTERS';
    userList: Models.User[];
    pageSizeList: Models.ListValue[];
    paymentTypeList: Models.ListValue[];
    companyList: Models.Company[];
    transportRequestStatus: TransportRequestStatus[];
}
export interface ResetTriggerSearch {
    type: 'TRANSPORT_REQUESTS_SEARCH_RESET_TRIGGER_SEARCH';
}

export interface SetTriggerRefresh {
    type: 'TRANSPORT_REQUESTS_SEARCH_SET_TRIGGER_REFRESH';
}

export interface ResetTriggerRefresh {
    type: 'TRANSPORT_REQUESTS_SEARCH_RESET_TRIGGER_REFRESH';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestDataAction |
    ReceiveDataAction |
    ReceiveDataAppendAction |
    RequestSearchFiltersAction |
    ReceiveSearchFiltersAction |
    ResetTriggerSearch |
    SetTriggerRefresh |
    ResetTriggerRefresh;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestData: (data: any, appendToState: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var payload = JSON.stringify(data);


            http_axios_post('api/TransportRequestsSearch', payload)
                .then(response => {
                    return response as Promise<TransportRequestsSearchResult>;
                })
                .then(data => {
                    if (appendToState) {
                        dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_APPEND', searchResult: data });
                    }
                    else {
                        dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_DATA', searchResult: data });
                    }
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            var searchData = Object.assign(new SearchData, data);
            dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_REQUEST_DATA', searchData });
        }
    },
    refreshTripData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var stateCopy: SearchData;
            stateCopy = Object.assign({}, appState.searchTransportRequests?.searchData);

            stateCopy.AfterId = 0;
            if (appState.searchTransportRequests?.searchResult.Data.length)
                stateCopy.PageSize = appState.searchTransportRequests?.searchResult.Data.length;
            var payload = JSON.stringify(stateCopy);

            http_axios_post('api/TransportRequestsSearch', payload)
                .then(response => {
                    return response as Promise<TransportRequestsSearchResult>;
                })
                .then(data => {
                    dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_DATA', searchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            var searchData = Object.assign(new SearchData, appState.searchTransportRequests?.searchData);
            dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_REQUEST_DATA', searchData });
        }
    },
    
    requestFilters: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all

            Promise.all([
                http_axios_get('api/TransportRequestsSearch/GetSearchUsers')
                    .then(users => {
                        return users;
                    }),
                http_axios_get('api/Lists/3')
                    .then(pageSize => {
                        return pageSize;
                    }),
                http_axios_get('api/Lists/4/ALL/ALL')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/Companies/GetAll/0/ALL')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/TransportRequestsStatus')
                    .then(requestStatus => {
                        return requestStatus;
                    })
            ]).then(([users, pageSize, paymentType, company, requestStatus]) => {
                const usrList = users as Models.User[];
                const pageSizeList = pageSize as Models.ListValue[];
                const paymentTypeList = paymentType as Models.ListValue[];
                const companyList = company as Models.Company[];
                const requestStatusList = requestStatus as TransportRequestStatus[];
                
                dispatch({
                    type: 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_FILTERS',
                    userList: usrList,
                    pageSizeList: pageSizeList,
                    paymentTypeList: paymentTypeList,
                    companyList: companyList,
                    transportRequestStatus: requestStatusList
                });
            })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_FILTERS' });
        }

    },
    resetTriggerTripsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_RESET_TRIGGER_SEARCH' });
        }
    },
    resetTriggerRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_SET_TRIGGER_REFRESH' });
        }
    },
    setTriggerRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'TRANSPORT_REQUESTS_SEARCH_RESET_TRIGGER_REFRESH' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SearchTransportRequestsState = {
    searchResult: {
        ...utils.getSearchPaginationResultDefaultValues(),
        Data: []
    },
    userList: [],
    pageSizeList: [],
    paymentTypeList: [],
    companyList: [],
    transportRequestStatus: [],
    isLoading: false,
    isLoaded: false,
    isLoadingSearchBox: false,
    isLoadedSearchBox: false,
    triggerSearch: false,
    triggerRefresh: false,
    searchData: {
        ...utils.getSearchPaginationInputDefaultValues(),
        transport_id: "0",
        status: [],
        start_date: utils.getDateTimeISO(-1),
        end_date: utils.getDateTimeISO(),
        time_zone_offset: utils.getTimezoneOffset().toString(),
        sender_name: "",
        payment_type: "ALL",
        user_id: 0,
        company_id: 0
    }
};

export const reducer: Reducer<SearchTransportRequestsState> = (state: SearchTransportRequestsState | undefined, incomingAction: Action): SearchTransportRequestsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TRANSPORT_REQUESTS_SEARCH_REQUEST_DATA':
            return {
                searchData: action.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoading: true,
                isLoaded: false,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh
            };
        case 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_DATA':
            return {
                searchData: state.searchData,
                searchResult: action.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoading: false,
                isLoaded: true,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'TRANSPORT_REQUESTS_SEARCH_APPEND':
            return {
                searchData: state.searchData,
                searchResult: {
                    RowCount: action.searchResult.RowCount,
                    First: action.searchResult.First,
                    Prev: action.searchResult.Prev,
                    Current: action.searchResult.Current,
                    Next: action.searchResult.Next,
                    Last: action.searchResult.Last,
                    AfterIdNext: action.searchResult.AfterIdNext,
                    Data: state.searchResult.Data.concat(action.searchResult.Data)
                },
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoading: false,
                isLoaded: true,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'TRANSPORT_REQUESTS_SEARCH_FILTERS':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoadingSearchBox: true,
                isLoadedSearchBox: false,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'TRANSPORT_REQUESTS_SEARCH_RECEIVE_FILTERS':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: action.userList,
                pageSizeList: action.pageSizeList,
                paymentTypeList: action.paymentTypeList,
                companyList: action.companyList,
                transportRequestStatus: action.transportRequestStatus,
                isLoadingSearchBox: false,
                isLoadedSearchBox: true,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: true,
                triggerRefresh: state.triggerRefresh,
            };
        case 'TRANSPORT_REQUESTS_SEARCH_RESET_TRIGGER_SEARCH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: false,
                triggerRefresh: state.triggerRefresh,
            };
        case 'TRANSPORT_REQUESTS_SEARCH_SET_TRIGGER_REFRESH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: false,
            };
        case 'TRANSPORT_REQUESTS_SEARCH_RESET_TRIGGER_REFRESH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                paymentTypeList: state.paymentTypeList,
                companyList: state.companyList,
                transportRequestStatus: state.transportRequestStatus,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: true,
            };


        default:
            return state;
    }
};
