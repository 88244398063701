import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_delete, http_axios_get } from './ApiInterfaceAlternative';
import * as Models from "../models/Models";
import * as utils from './Utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

var startDate = new Date();
var endDate = new Date();

startDate.setDate(startDate.getDate() - 14);
endDate.setDate(endDate.getDate() + 14);

export interface FetchTripsState {
    navigateMore?: any;
    searchData: SearchData;
    tripSearchResult: TripSearchResult;
    userList: TripUser[];
    pageSizeList: Models.ListValue[];
    isLoadingTrips: boolean;
    isLoadedTrips: boolean;
    isLoadingSearchBox: boolean;
    isLoadedSearchBox: boolean;
    triggerSearch: boolean;
    triggerRefresh: boolean;
}


export class SearchData extends Models.SearchPaginationInput {
    Destination: string = "";
    StartDate: string = "";
    EndDate: string = "";
    Comment: string = "";
    UserName: string = "";
}



export class TripData {
    TripId: number = 0;
    Destination: string = "";
    StartDate: string = "";
    EndDate: string = "";
    Comment: string = "";
    InsUser: string = "";
    InsDate: string = "";
    UpdUser: string = "";
    UpdDate: string = "";
    StartingIn: string = "";
}

export class TripUser {
    UserName: string = "";
    DisplayName: string = "";
}

export class TripSearchResult extends Models.SearchPaginationResult {
    Data: TripData[] = [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestTripDataAction {
    type: 'REQUEST_TRIP_DATA';
    searchData: SearchData;
}
export interface ReceiveTripDataAction {
    type: 'RECEIVE_TRIP_DATA';
    tripSearchResult: TripSearchResult;
}

export interface ReceiveTripDataAppendAction {
    type: 'RECEIVE_TRIP_DATA_APPEND';
    tripSearchResult: TripSearchResult;
}

export interface RequestTripSearchBoxAction {
    type: 'REQUEST_TRIP_SEARCH_BOX_DATA';
}
export interface ReceiveTripSearchBoxAction {
    type: 'RECEIVE_TRIP_SEARCH_BOX_DATA';
    userList: TripUser[];
    pageSizeList: Models.ListValue[];
}
export interface ResetTriggerTripsSearch {
    type: 'RESET_TRIGGER_TRIPS_SEARCH';
}

export interface SetTriggerTripsRefresh {
    type: 'SET_TRIGGER_TRIPS_REFRESH';
}
export interface ResetTriggerTripsRefresh {
    type: 'RESET_TRIGGER_TRIPS_REFRESH';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestTripDataAction |
    ReceiveTripDataAction |
    ReceiveTripDataAppendAction |
    RequestTripSearchBoxAction |
    ReceiveTripSearchBoxAction |
    ResetTriggerTripsSearch |
    SetTriggerTripsRefresh |
    ResetTriggerTripsRefresh;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestTripData: (data: any, appendToState: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {            
                http_axios_get('api/TripsSearch', data)
                    .then(response => {
                        return response as Promise<TripSearchResult>;
                    })
                    .then(data => {
                        if (appendToState) {
                            dispatch({ type: 'RECEIVE_TRIP_DATA_APPEND', tripSearchResult: data });
                        }
                        else {
                            dispatch({ type: 'RECEIVE_TRIP_DATA', tripSearchResult: data });
                        }
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
                
                var searchData = Object.assign(new SearchData, data);
                dispatch({ type: 'REQUEST_TRIP_DATA', searchData });
        }
    },
    refreshData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var stateCopy: SearchData;
            stateCopy = Object.assign({}, appState.fetchTrips?.searchData);

            stateCopy.AfterId = 0;
            if (appState.fetchTrips?.tripSearchResult.Data.length)
                stateCopy.PageSize = appState.fetchTrips?.tripSearchResult.Data.length;


            http_axios_get('api/TripsSearch', stateCopy)
                .then(response => {
                    return response as Promise<TripSearchResult>;
                })
                .then(data => {                    
                    dispatch({ type: 'RECEIVE_TRIP_DATA', tripSearchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            var searchData = Object.assign(new SearchData, appState.fetchTrips?.searchData);
            dispatch({ type: 'REQUEST_TRIP_DATA', searchData });
        }
    },
    requestDeleteTrip: (tripId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)            


        http_axios_delete('api/Trips/' + tripId)
            .then(response => {
                //https://github.com/aspnet/JavaScriptServices/issues/1321
                const untypedDispath = <any>dispatch;
                untypedDispath(actionCreators.setTriggerTripsRefresh());
            })
            .catch(function (e) {
                console.log(e);
            })
    },
    requestTripSearchBoxData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all

            Promise.all([
                http_axios_get('api/Trips/GetTripUsers')
                    .then(users => {
                        return users;
                    }),
                http_axios_get('api/Lists/3')
                    .then(list => {
                        return list;
                    })
            ]).then(([users, pageSize]) => {
                const usrList = users as TripUser[];
                const pageSizeList = pageSize as Models.ListValue[];

                dispatch({ type: 'RECEIVE_TRIP_SEARCH_BOX_DATA', userList: usrList, pageSizeList: pageSizeList });
            })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'REQUEST_TRIP_SEARCH_BOX_DATA' });
        }

    },
    resetTriggerTripsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_TRIPS_SEARCH' });
        }
    },
    resetTriggerTripsRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_TRIPS_REFRESH' });
        }
    },
    setTriggerTripsRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();        
        if (appState) {
            dispatch({ type: 'SET_TRIGGER_TRIPS_REFRESH' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: FetchTripsState = {
    tripSearchResult: {
        ...utils.getSearchPaginationResultDefaultValues(),
        Data: []
    },
    userList: [],
    pageSizeList: [],
    isLoadingTrips: false,
    isLoadedTrips: false,
    isLoadingSearchBox: false,
    isLoadedSearchBox: false,
    triggerSearch: false,
    triggerRefresh: false,
    searchData: {
        ...utils.getSearchPaginationInputDefaultValues(),
        Destination: "",
        StartDate: startDate.toISOString().substr(0, 10),
        EndDate: endDate.toISOString().substr(0, 10),
        Comment: "",
        UserName: ""
    }
};

export const reducer: Reducer<FetchTripsState> = (state: FetchTripsState | undefined, incomingAction: Action): FetchTripsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_TRIP_DATA':
            return {
                searchData: action.searchData,
                tripSearchResult: state.tripSearchResult,                
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                isLoadingTrips: true,
                isLoadedTrips: false,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh
            };
        case 'RECEIVE_TRIP_DATA':
            return {
                searchData: state.searchData,
                tripSearchResult: action.tripSearchResult,                
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                isLoadingTrips: false,
                isLoadedTrips: true,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'RECEIVE_TRIP_DATA_APPEND':
            return {
                searchData: state.searchData,
                //tripSearchResult: action.tripSearchResult,                
                tripSearchResult: {
                    RowCount: action.tripSearchResult.RowCount,
                    First: action.tripSearchResult.First,
                    Prev: action.tripSearchResult.Prev,
                    Current: action.tripSearchResult.Current,
                    Next: action.tripSearchResult.Next,
                    Last: action.tripSearchResult.Last,
                    AfterIdNext: action.tripSearchResult.AfterIdNext,
                    Data: state.tripSearchResult.Data.concat(action.tripSearchResult.Data)
                },
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                isLoadingTrips: false,
                isLoadedTrips: true,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'REQUEST_TRIP_SEARCH_BOX_DATA':
            return {
                searchData: state.searchData,
                tripSearchResult: state.tripSearchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: true,
                isLoadedSearchBox: false,
                isLoadingTrips: state.isLoadingTrips,
                isLoadedTrips: state.isLoadedTrips,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'RECEIVE_TRIP_SEARCH_BOX_DATA':
            return {
                searchData: state.searchData,
                tripSearchResult: state.tripSearchResult,                
                userList: action.userList,
                pageSizeList: action.pageSizeList,
                isLoadingSearchBox: false,
                isLoadedSearchBox: true,
                isLoadingTrips: state.isLoadingTrips,
                isLoadedTrips: state.isLoadedTrips,
                triggerSearch: true,
                triggerRefresh: state.triggerRefresh,
            };
        case 'RESET_TRIGGER_TRIPS_SEARCH':
            return {
                searchData: state.searchData,
                tripSearchResult: state.tripSearchResult,                
                userList: state.userList,
                pageSizeList: state.pageSizeList,                
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoadingTrips: state.isLoadingTrips,
                isLoadedTrips: state.isLoadedTrips,
                triggerSearch: false,
                triggerRefresh: state.triggerRefresh,
            };
        case 'RESET_TRIGGER_TRIPS_REFRESH':
            return {
                searchData: state.searchData,
                tripSearchResult: state.tripSearchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoadingTrips: state.isLoadingTrips,
                isLoadedTrips: state.isLoadedTrips,
                triggerSearch: state.triggerSearch,
                triggerRefresh: false,
            };
        case 'SET_TRIGGER_TRIPS_REFRESH':
            return {
                searchData: state.searchData,
                tripSearchResult: state.tripSearchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoadingTrips: state.isLoadingTrips,
                isLoadedTrips: state.isLoadedTrips,
                triggerSearch: state.triggerSearch,
                triggerRefresh: true,
            };


        default:
            return state;
    }
};
